import {
  Redirect,
  Route,
  useHistory,
  useLocation,
  Switch,
  BrowserRouter,
} from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonSplitPane,
  IonContent,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonSearchbar,
  isPlatform,
  IonMenu,
  IonRippleEffect,
  IonHeader,
  IonAlert,
  createAnimation,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { ellipse, home, square, triangle } from "ionicons/icons";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

import "@mobiscroll/react/dist/css/mobiscroll.min.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/dashlite.scss";
import "./theme/fonts.css";
// import "./theme/font-awesome.min.css";
import "./font-awesome/css/all.min.css";

import "./theme/jobster.scss";

import "./theme/animate.scss";

import "./Global.css";
import "./Global_desktop.css";
import "./Global_mobile.css";

import Menu from "./components/JobsterMenu";

// import carIcon from "./svg/innner/car.svg";
// import cashIcon from "./svg/innner/cash.svg";
// import chatIcon from "./svg/innner/chat.svg";
// import homeIcon from "./svg/innner/home.svg";
// import lectureIcon from "./svg/innner/lecture.svg";
import { BaseSyntheticEvent, createElement, useEffect, useState } from "react";

import { HubConnectionBuilder, HubConnection } from "@microsoft/signalr";

import AddStudent from "./pages/AddStudent";

import Home from "./pages/Home";
import List from "./pages/List";
import JobExpanded from "./pages/JobExpanded";
import AddJob from "./pages/AddJob";
import Job from "./pages/Job";

import Tab1 from "./pages/Tab1";
import Tab2 from "./pages/Tab2";
import Tab3 from "./pages/Tab3";
import Login from "./pages/Login";
import Template from "./pages/Template";

import Brokul from "./pages/Brokul";

import {
  GlobalStateProvider,
  useGlobalState,
  GlobalStateInterface,
} from "./GlobalStateProvider";

import { Storage } from "@ionic/storage";
import { Roles } from "./components/Roles";

//import { SignalR } from "./components/Common";

import { setupIonicReact } from "@ionic/react";
import Header from "./components/JobsterHeader";
import MobiPopup from "./components/Mobi/MobiPopup";

import logo from "./zlecenia_svg/logo.svg";

import { mobileAnimation, opacityAnimation } from "./components/Ebert/Animations";

setupIonicReact({
  mode: "md",
});

const App: React.FC = () => {
  const storage = new Storage();
  const { setState } = useGlobalState();


  //const [isPopupOpen, setOpen] = useState(false);

  //const [connection, setConnection] = useState<HubConnection>();

  useEffect(() => {
    document.querySelector("html")?.classList.add("dashlite");
    document.querySelector("html")?.classList.add("jobster");
    // try {
    //   const newConnection = new HubConnectionBuilder()
    //     .withUrl("https://localhost:44334/socket", { withCredentials: false })
    //     .withAutomaticReconnect()
    //     .build();

    //   setConnection(newConnection);
    // } catch (error) {
    //   console.log("Cannot connect to socket!");
    // }

    async function CreateStorage() {
      await storage.create();
    }
    async function GetToken() {
      await CreateStorage();

      const token = await storage.get("token");

      if (Boolean(token)) {
        if (token == "admin") {
          setState((prev) => ({
            ...prev,
            ...{
              token: token,
              logged: true,
              firstname: "siema",
              role: Roles.PlatformAdmin,
            },
          }));
        } else {
          setState((prev) => ({
            ...prev,
            ...{ token: undefined, logged: false },
          }));
        }
      } else {
        setState((prev) => ({
          ...prev,
          ...{ token: undefined, logged: false },
        }));
      }

      //const loader = document.querySelector(".loader-container");

      // if (loader != null) {
      //   loader.classList.add("hide");
      // }

      //setLoader(false);
    }

    GetToken();

    setTimeout(() => {
      setLoader(false);
    }, 500);
  }, []);

  // useEffect(() => {
  //   setTimeout(async () => {
  //     if (connection) {
  //       if (SignalR.isConnected(connection)) {
  //         await connection.send("Login", "siema");
  //       }
  //     }
  //   }, 10000);

  //   if (connection) {
  //     connection
  //       .start()
  //       .then((result) => {
  //         console.log("Connected!");

  //         connection.on("newMessage", (message) => {
  //           console.log(message);
  //         });
  //       })
  //       .catch((e) => console.log("Connection failed: ", e));
  //   }
  // }, [connection]);

  // useEffect(() => {

  //   if(isPlatform("mobile"))
  //   {
  //     setOpen(true);
  //   }

  // }, [])

  const { state } = useGlobalState();

  //const [logged, setLogged] = useState(true);

  const [loader, setLoader] = useState(true);


  

  

  const Routes = () => (
    <>
      <IonRouterOutlet
        id="main"
        animated={true}
        animation={isPlatform("mobile") ? mobileAnimation : opacityAnimation}
        mode={"md"}
      >
        <Route path="/" component={Home} exact={true} />
        <Route path="/Brokul" component={Brokul} exact={true} />
        <Route path="/AddJob" component={AddJob} />

        <Route path="/a/tabs" component={Tabs} exact={true} />

        <Route path="/a/tab2" component={Tab2} exact={true} />
        <Route path="/a/tab3" component={Tab3} />

        <Route path="/login" component={Login} />
        <Route path="/template" component={Template} />

        <Route path="/zlecenie" component={Job} />

        {/* <Route path="/" render={() => <Redirect to="/a/tab1" />} exact={true} /> */}
        {/* <Route
          path="/login"
          render={() =>
            state.logged ? <Redirect to="/a/tab1" /> : <Redirect to="/login" />
          }
          exact={true}
        /> */}

        <Route exact path={"/zlecenia/:id?"} component={List} />

      </IonRouterOutlet>
    </>
  );

  const JustHeader = () => (
    <>
      {/* <IonHeader mode={"ios"} collapse="condense">
        <Header />
      </IonHeader> */}
      <Menu />
      <Routes />
    </>
  );

  const SplitPane = () => (
    <IonSplitPane contentId="main">
      <Menu />
      <Routes />
      {/* <IonRouterOutlet id="main" animated={false}>

        <Route path="/a/AddStudent" component={AddStudent} exact={true} />

        <Route path="/a/tabs" component={Tabs} exact={true} />
        <Route path="/a/tab1" component={Tab1} exact={true} />
        <Route path="/a/tab2" component={Tab2} exact={true} />
        <Route path="/a/tab3" component={Tab3} />

        <Route path="/login" component={Login} />
        <Route path="/template" component={Template} />

        <Route path="/" render={() => <Redirect to="/a/tab1" />} exact={true} />
        <Route
          path="/login"
          render={() =>
            state.logged ? <Redirect to="/a/tab1" /> : <Redirect to="/login" />
          }
          exact={true}
        />
      </IonRouterOutlet> */}
    </IonSplitPane>
  );

  const Tabs = () => (
    // <IonTabs>
    //   <IonRouterOutlet id="main" animated={false}>
    //     <Route path="/a/Home" component={Home} exact={true} />

    //     <Route path="/a/tabs" component={Tabs} exact={true} />
    //     <Route path="/a/tab1" component={Tab1} exact={true} />
    //     <Route path="/a/tab2" component={Tab2} exact={true} />
    //     <Route path="/a/tab3" component={Tab3} />

    //     <Route path="/login" component={Login} />
    //     <Route path="/template" component={Template} />

    //     <Route path="/" render={() => <Redirect to="/a/tab1" />} exact={true} />
    //     <Route
    //       path="/login"
    //       render={() =>
    //         state.logged ? <Redirect to="/a/tab1" /> : <Redirect to="/login" />
    //       }
    //       exact={true}
    //     />
    //   </IonRouterOutlet>

    //   <IonTabBar slot="bottom">
    //     <IonTabButton tab="tab1" href="/a/tab1">
    //       <IonRippleEffect></IonRippleEffect>
    //       <IonIcon icon={homeIcon} />
    //       <IonLabel>Start</IonLabel>
    //     </IonTabButton>
    //     <IonTabButton tab="tab2" href="/a/tab2">
    //       <IonRippleEffect></IonRippleEffect>
    //       <IonIcon icon={carIcon} />
    //       <IonLabel>Jazdy</IonLabel>
    //     </IonTabButton>
    //     <IonTabButton tab="tab3" href="/a/tab3">
    //       <IonRippleEffect></IonRippleEffect>
    //       <IonIcon icon={lectureIcon} />
    //       <IonLabel>Wykłady</IonLabel>
    //     </IonTabButton>
    //     <IonTabButton tab="tab4" href="/a/tab4">
    //       <IonRippleEffect></IonRippleEffect>
    //       <IonIcon icon={cashIcon} />
    //       <IonLabel>Płatnośc</IonLabel>
    //     </IonTabButton>
    //     <IonTabButton tab="tab5" href="/a/tab5">
    //       <IonRippleEffect></IonRippleEffect>
    //       <IonIcon icon={chatIcon} />
    //       <IonLabel>Czat</IonLabel>
    //     </IonTabButton>
    //   </IonTabBar>
    // </IonTabs>
    <>
      <IonHeader>
        <Header />
      </IonHeader>
      <Menu />
      <Routes />
    </>
  );

  const _Login = () => {
    return (
      <IonRouterOutlet animated={false} id="main">
        <Route path="/login" render={() => <Login />} exact={false} />
        <Route
          path="/a"
          render={() =>
            Boolean(state.logged) ? (
              <Redirect to="/a/tab1" />
            ) : (
              <Redirect to="/login" />
            )
          }
          exact={false}
        />
        <Route
          path="/"
          render={() =>
            Boolean(state.logged) ? (
              <Redirect to="/a/tab1" />
            ) : (
              <Redirect to="/login" />
            )
          }
          exact={true}
        />
      </IonRouterOutlet>
    );
  };

  // const ionAppOnClick = (e: BaseSyntheticEvent) => {

  //   const target = e.target as HTMLElement;

  //   if(target.classList.contains("mbsc-error-message"))
  //   {

  //   }

  // }

  return (
    <IonApp>

      {loader ? (
        <div className="loader-container">
          <div className="loader">
            <IonIcon src={logo} />
          </div>
        </div>
      ) : (
        <></>
      )}

      <IonReactRouter>
        {/* {state.logged == undefined ? (
          ""
        ) : Boolean(state.logged) ? (
          [
            (isPlatform("mobileweb") || isPlatform("mobile")) && <Tabs />,
            isPlatform("desktop") && <JustHeader />,
          ]
        ) : (
          <_Login />
        )} */}
        <JustHeader />
      </IonReactRouter>
      {/* <IonReactRouter>
        <IonRouterOutlet animated={false} mode="ios" basePath="/a/tab1" >
          <Route path="/a/tab1/:id?" component={List} exact={true} />
        </IonRouterOutlet>
      </IonReactRouter> */}

      {/* <IonAlert
          isOpen={true}
          header="Warunki korzystania"
          onDidDismiss={(e) => console.log(e)}
          cssClass='custom-alert-class'
          message={'My i nasi partnerzy przechowujemy i/lub uzyskujemy dostęp do informacji na urządzeniu, takich jak pliki cookie, oraz przetwarzamy dane osobowe, takie jak unikalne identyfikatory i standardowe informacje wysyłane przez urządzenie w celu tworzenia profilu spersonalizowanych treści i reklam, pomiaru wydajności treści i reklam, a także rozwijania i ulepszania produktów. Za Twoją zgodą my i nasi partnerzy możemy korzystać z precyzyjnych danych geolokalizacyjnych oraz identyfikacji poprzez skanowanie urządzeń. Kliknięcie przycisku "Zaakceptuj wszystko" pozwala na wyrażenie zgody na przetwarzanie danych przez nas i naszych partnerów, zgodnie z opisem powyżej. Możesz również uzyskać dostęp do bardziej szczegółowych informacji i zmienić swoje preferencje zanim wyrazisz zgodę lub odmówisz jej wyrażenia. Niektóre rodzaje przetwarzania danych nie wymagają zgody użytkownika, ale masz prawo sprzeciwić się takiemu przetwarzaniu. Twoje preferencje nie będą miały zastosowania do innych witryn w sieci.'}
          buttons={[
            {
              text: 'Akceptuję',
              role: 'accept',
              handler: e => {
                console.log(e);
              }
            }
          ]}
          mode={ isPlatform("mobile") ? "ios" : "md" }
          translucent={true}
        /> */}
    </IonApp>
  );
};

export default App;

import { IonButton, IonCol, IonGrid, IonRippleEffect, IonRouterLink, IonRow, isPlatform } from "@ionic/react";
import { Input, localePl, Popup, setOptions } from "@mobiscroll/react";
import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import IonicButtonCustom from "../Ebert/Button";

import "./JobPost.css";

import { translateAnimation } from "../Ebert/Animations";

setOptions({
  theme: "ios",
  themeVariant: "light",
  locale: localePl,
});

type Props = {
  transitionDelay: number;
  animated?: boolean;
  title?: string;
  description?: string;
  margin?: boolean
}

const JobPost: React.FC<Props> = ({ transitionDelay, title, description, animated = false, margin = false }) => {

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {

    ref.current?.classList.remove("pxp-in");

    setTimeout(() => {
      ref.current?.classList.add("pxp-in");
    }, 200);

  }, [])

  return (
    
    <div
      ref={ref}
      className={"col-12 col-md-6 col-xxl-6 " + (margin ? "mb-5" : "") + " mb-md-0 pxp-jobs-card-1-container pxp-animate-in pxp-animate-in-top " + (animated ? "pxp-in" : "")}
      style={{
        transition: "0.5s cubic-bezier(0.215, 0.61, 0.355, 1)",
        transitionDelay: transitionDelay + "s",
      }}
    >
      <IonRouterLink routerLink="/zlecenie">
      <div className="pxp-jobs-card-1 pxp-has-border scaled" style={{
        position: "relative",
        overflow: "hidden"
      }}>
        
        <IonRippleEffect className="ripple-small" />
        <div
          className="pxp-jobs-card-1-top"
          style={{ height: "100%", margin: "auto 0", display: "grid" }}
        >
          <a
            href="jobs-list-1.html"
            className="pxp-jobs-card-1-category"
            style={{ marginBottom: "15px" }}
          >
            <div className="pxp-jobs-card-1-category-icon">
              <span className="fa fa-bullhorn" />
            </div>
            <div className="pxp-jobs-card-1-category-label">
              Project Management
            </div>
          </a>
          <a
            href="single-job-1.html"
            className="pxp-jobs-card-1-title"
            style={{ marginTop: "0" }}
          >
            {title}
          </a>
          <div className="pxp-jobs-card-1-details">
            <div className="pxp-jobs-card-1-type">
              {description}
            </div>
          </div>
        </div>

        {/* <div
          className="row justify-content-center post-categories post-categories-mobile"
        >
<div className="col-auto">
            <a
              style={{ marginTop: "20px" }}
              href="jobs-list-1.html"
              className="pxp-jobs-card-1-category"
            >
              <div className="pxp-jobs-card-1-category-label">
                <strong>Marketing Communication</strong> &amp; <strong>Marketing Communication</strong> &amp; <strong>Marketing Communication</strong>
              </div>
            </a>
          </div>
        </div>                    */}

        {/* <div
          className="row justify-content-center post-categories post-categories-desktop"
        >
          <div className="col-auto">
            <a
              style={{ marginTop: "20px" }}
              href="jobs-list-1.html"
              className="pxp-jobs-card-1-category"
            >
              <div className="pxp-jobs-card-1-category-icon">
                <span className="fa fa-bullhorn" />
              </div>
              <div className="pxp-jobs-card-1-category-label">
                Marketing &amp; Communication
              </div>
            </a>
          </div>
          <div className="col-auto">
            <a
              style={{ marginTop: "20px" }}
              href="jobs-list-1.html"
              className="pxp-jobs-card-1-category"
            >
              <div className="pxp-jobs-card-1-category-icon">
                <span className="fa fa-bullhorn" />
              </div>
              <div className="pxp-jobs-card-1-category-label">
                Marketing &amp; Communication
              </div>
            </a>
          </div>
          <div className="col-auto">
            <a
              style={{ marginTop: "20px" }}
              href="jobs-list-1.html"
              className="pxp-jobs-card-1-category"
            >
              <div className="pxp-jobs-card-1-category-icon">
                <span className="fa fa-bullhorn" />
              </div>
              <div className="pxp-jobs-card-1-category-label">
                Marketing &amp; Communication
              </div>
            </a>
          </div>
        </div> */}
        <div className="pxp-jobs-card-1-bottom">
          <div className="pxp-jobs-card-1-bottom-left">
            <div className="pxp-jobs-card-1-date pxp-text-light">
              3 dni temu przez
            </div>
            <a href="single-company-1.html" className="pxp-jobs-card-1-company">
              <strong>Artistre Studio</strong>
            </a>
          </div>
          <div
            className="pxp-jobs-card-1-bottom-left"
            style={{ paddingRight: "0" }}
          >

            {/* <IonicButtonCustom routerLink={"/zlecenie"} >
                Zobacz więcej
              </IonicButtonCustom> */}
          </div>
        </div>
        
      </div>
      </IonRouterLink>
    </div>
    
  );
};

export default JobPost;

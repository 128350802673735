import { localePl, Popup, setOptions } from "@mobiscroll/react";
import React, { createRef, MouseEvent, useEffect, useMemo, useRef, useState } from "react";
import "./Header.css";

import { IonIcon, isPlatform } from "@ionic/react";
import qrcodeIcon from "../svg/innner/qrcode.svg";
import logo from "../svg/logo/beside-blue.svg";

// import {
//   BarcodeScanner,
//   BarcodeScannerOptions,
//   BarcodeScanResult,
// } from "@ionic-native/barcode-scanner";
import { useHistory, useLocation } from "react-router";

import { useGlobalState, GlobalStateInterface } from "./../GlobalStateProvider";

import { Storage } from "@ionic/storage";

import MobiPopup from './Mobi/MobiPopup';

setOptions({
  theme: "ios",
  themeVariant: "light",
  locale: localePl,
});

interface Props {
  title: string;
}

const Header: React.FC<Props> = ({ title }) => {
  const [isPopupOpen, setOpen] = useState(false);
  const [isNotificationOpen, setOpenNotification] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const notificationRef = useRef<HTMLAnchorElement>(null);

  const location = useLocation();
  const history = useHistory();

  const { state } = useGlobalState();
  const { setState } = useGlobalState();

  const storage = new Storage();

  // const popupRef = useRef<HTMLDivElement>(null);
  const popupIonicRef = useRef<Popup>(null);

  // useEffect(() => {
  //   if (window) {
  //     window.addEventListener("mousemove", (e) => {
  //       setTimeout(() => {
  //         const target = e.target as Node;
  //       if (
  //         ref.current &&
  //         popupRef.current &&
  //         target &&
  //         popupIonicRef.current
  //       ) {
  //         if (
  //           !ref.current.contains(target) &&
  //           !popupRef.current.contains(target) &&
  //           ref.current != target &&
  //           popupRef.current != target &&
  //           popupIonicRef.current._isOpen
  //         ) {
  //           setOpen(false);
  //         }
  //       }
  //       }, 10);
  //     });
  //   }
  // }, []);

  useEffect(() => {
    if (!location.search.includes("userModal=true")) {
      setOpen(false);
    }
    if (!location.search.includes("notificationModal=true")) {
      setOpenNotification(false);
    }
  }, [location]);

  const openUserModal = () => {
    if (isPlatform("mobile")) {
      history.push(history.location.pathname + "?userModal=true");
    }
    setOpen(true);
  };

  const closeUserModal = () => {
    if (isPlatform("mobile")) {
      history.goBack();
    }
    setOpen(false);
  };

  const openNotificationModal = () => {
    if (isPlatform("mobile")) {
      history.push(history.location.pathname + "?notificationModal=true");
    }
    setOpenNotification(true);
  };

  const closeNotificationModal = () => {
    if (isPlatform("mobile")) {
      history.goBack();
    }
    setOpenNotification(false);
  };

  return (
    <div className="nk-header nk-header-fixed is-light">
      <div className="container-fluid">
        <div className="nk-header-wrap">
          {/* <div className="nk-menu-trigger d-xl-none ml-n1">
                        <a href="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu"><em className="icon ni ni-menu" /></a>
                    </div> */}
          {/* {
                        isPlatform("mobile")
                            ?
                            <div className="nk-header-brand" style={{ padding: "0" }}>
                                <div className="header-title" style={{ height: "49px" }} >
                                    <IonIcon src={logo} />
                                </div>
                            </div>
                            :
                            ""
                    } */}
          <div className="nk-header-brand" style={{ padding: "0" }}>
            <div className="header-title" style={{ height: "49px" }}>
              {/* {title} */}
              <IonIcon src={logo} />
            </div>
          </div>
          {/* <div className="nk-header-news d-none d-xl-block">
                        <div className="nk-news-list">
                            <a className="nk-news-item" href="#">
                                <div className="nk-news-icon">
                                    <em className="icon ni ni-card-view" />
                                </div>
                                <div className="nk-news-text">
                                    <p>Do you know the latest update of 2019? <span> A overview of our is now available on YouTube</span></p>
                                    <em className="icon ni ni-external" />
                                </div>
                            </a>
                        </div>
                    </div> */}
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              <li
                className="dropdown user-dropdown"
                onClick={() => {
                  if (ref.current != null) {
                    openUserModal();
                  }
                }}
                onMouseEnter={() => {
                  console.log("s");
                  if (popupIonicRef.current) {
                    if (!popupIonicRef.current._isOpen) {
                      openUserModal();
                    }
                  }
                }}
                onMouseLeave={(event: MouseEvent<HTMLLIElement>) => {
                  
                  if(!isPlatform("mobile"))
                  {
                    setTimeout(() => {

                      const cursorTarget = document.querySelectorAll( ":hover" );
  
                      if (popupIonicRef.current) {
                        if (popupIonicRef.current._isOpen) {
                          if(!popupIonicRef.current._popup.contains(cursorTarget[cursorTarget.length - 1]))
                          {
                            closeUserModal();
                          }
                        }
                      }
  
                    }, 150);
                  }
                  
                }}
              >
                <a className="dropdown-toggle" data-toggle="dropdown">
                  <div className="user-toggle">
                    <div className="user-avatar sm" ref={ref}>
                      <em className="icon ni ni-user-alt" />
                    </div>
                    <div className="user-info d-none d-md-block">
                      <div className="user-status">Administrator</div>
                      <div className="user-name dropdown-indicator">
                        Abu Bin Ishityak
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              {/* .dropdown */}
              {isPlatform("mobile") && !isPlatform("mobileweb") ? (
                <li className="dropdown notification-dropdown mr-n1">
                  <a
                    ref={notificationRef}
                    className="dropdown-toggle nk-quick-nav-icon"
                    data-toggle="dropdown"
                    onClick={async () => {
                      //var data = await BarcodeScanner.scan();
                    }}
                  >
                    <IonIcon src={qrcodeIcon} />
                  </a>
                </li>
              ) : (
                ""
              )}
              <li className="dropdown notification-dropdown mr-n1">
                <a
                  ref={notificationRef}
                  className="dropdown-toggle nk-quick-nav-icon"
                  data-toggle="dropdown"
                  onClick={() => {
                    openNotificationModal();
                  }}
                >
                  <div className="icon-status icon-status-info">
                    <em className="icon ni ni-bell" />
                  </div>
                </a>
              </li>
              {/* .dropdown */}
            </ul>
            {/* .nk-quick-nav */}
          </div>
          {/* .nk-header-tools */}
        </div>
        {/* .nk-header-wrap */}
      </div>
      {/* .container-fliud */}

      <Popup
        ref={popupIonicRef}
        anchor={ref.current as HTMLDivElement}
        isOpen={isPopupOpen}
        onOpen={() => {
          if(popupIonicRef.current)
          {
            popupIonicRef.current._popup.onmouseleave = () => {
              setTimeout(() => {

                const cursorTarget = document.querySelectorAll( ":hover" );

                if (popupIonicRef.current) {
                  if (popupIonicRef.current._isOpen) {
                    if(!popupIonicRef.current._popup.contains(cursorTarget[cursorTarget.length - 1]))
                    {
                      closeUserModal();
                    }
                  }
                }

              }, 150);
            }
          }
        }}
        onClose={() => closeUserModal()}
        cssClass="mbsc-no-padding modal-top-border"
        contentPadding={false}
        showOverlay={false}
        responsive={{
          xsmall: {
            display: "center",
            showOverlay: true,
          },
          medium: {
            width: "270px",
            display: "anchored",
          },
        }}
        on={() => {
          console.log('siema')
        }}
      >
        <div
          // ref={popupRef}
          className="dropdown-menu show"
        >
          <div className="dropdown-inner user-card-wrap bg-lighter d-block">
            <div className="user-card">
              <div className="user-avatar">
                <span>AB</span>
              </div>
              <div className="user-info">
                <span className="lead-text">{state.firstname}</span>
                <span className="sub-text">info@softnio.com</span>
              </div>
            </div>
          </div>
          <div className="dropdown-inner">
            <ul className="link-list">
              <li>
                <a href="html/user-profile-regular.html">
                  <em className="icon ni ni-user-alt" />
                  <span>View Profile</span>
                </a>
              </li>
              <li>
                <a href="html/user-profile-setting.html">
                  <em className="icon ni ni-setting-alt" />
                  <span>Account Setting</span>
                </a>
              </li>
              <li>
                <a href="html/user-profile-activity.html">
                  <em className="icon ni ni-activity-alt" />
                  <span>Login Activity</span>
                </a>
              </li>
              <li>
                <a className="dark-switch" href="#">
                  <em className="icon ni ni-moon" />
                  <span>Dark Mode</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="dropdown-inner">
            <ul className="link-list">
              <li>
                <a
                  onClick={async () => {
                    setState((prev) => ({
                      ...prev,
                      ...{ logged: false, token: undefined },
                    }));
                    await storage.create();
                    await storage.remove("token");
                    history.push("/login");
                  }}
                >
                  <em className="icon ni ni-signout" />
                  <span>Sign out</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Popup>

      <Popup
        anchor={notificationRef.current as HTMLAnchorElement}
        isOpen={isNotificationOpen}
        onClose={() => closeNotificationModal()}
        cssClass="mbsc-no-padding"
        contentPadding={false}
        showOverlay={false}
        scrollLock={false}
        responsive={{
          xsmall: {
            cssClass: "mobile",
            display: "center",
            maxHeight: "calc( 95% - 55px )",
            showOverlay: true,
          },
          medium: {
            cssClass: "desktop",
            width: "270px",
            display: "anchored",
            maxHeight: "800px",
          },
        }}
      >
        <div className="dropdown-menu show">
          <div
            className="dropdown-head"
            style={{
              position: "fixed",
              width: "100%",
              marginBottom: "24px",
            }}
          >
            <span className="sub-title nk-dropdown-title">Powiadomienia</span>
            <a href="#">Pokaż wszystkie</a>
          </div>
          <div className="dropdown-body" style={{ marginTop: "46px" }}>
            <div className="nk-notification">
              <div className="nk-notification-item dropdown-inner">
                <div className="nk-notification-icon">
                  <em className="icon icon-circle bg-warning-dim ni ni-curve-down-right" />
                </div>
                <div className="nk-notification-content">
                  <div className="nk-notification-text">
                    You have requested to <span>Widthdrawl</span>
                  </div>
                  <div className="nk-notification-time">2 hrs ago</div>
                </div>
              </div>
              <div className="nk-notification-item dropdown-inner">
                <div className="nk-notification-icon">
                  <em className="icon icon-circle bg-success-dim ni ni-curve-down-left" />
                </div>
                <div className="nk-notification-content">
                  <div className="nk-notification-text">
                    Your <span>Deposit Order</span> is placed
                  </div>
                  <div className="nk-notification-time">2 hrs ago</div>
                </div>
              </div>
              <div className="nk-notification-item dropdown-inner">
                <div className="nk-notification-icon">
                  <em className="icon icon-circle bg-warning-dim ni ni-curve-down-right" />
                </div>
                <div className="nk-notification-content">
                  <div className="nk-notification-text">
                    You have requested to <span>Widthdrawl</span>
                  </div>
                  <div className="nk-notification-time">2 hrs ago</div>
                </div>
              </div>
              <div className="nk-notification-item dropdown-inner">
                <div className="nk-notification-icon">
                  <em className="icon icon-circle bg-success-dim ni ni-curve-down-left" />
                </div>
                <div className="nk-notification-content">
                  <div className="nk-notification-text">
                    Your <span>Deposit Order</span> is placed
                  </div>
                  <div className="nk-notification-time">2 hrs ago</div>
                </div>
              </div>
              <div className="nk-notification-item dropdown-inner">
                <div className="nk-notification-icon">
                  <em className="icon icon-circle bg-warning-dim ni ni-curve-down-right" />
                </div>
                <div className="nk-notification-content">
                  <div className="nk-notification-text">
                    You have requested to <span>Widthdrawl</span>
                  </div>
                  <div className="nk-notification-time">2 hrs ago</div>
                </div>
              </div>
              <div className="nk-notification-item dropdown-inner">
                <div className="nk-notification-icon">
                  <em className="icon icon-circle bg-success-dim ni ni-curve-down-left" />
                </div>
                <div className="nk-notification-content">
                  <div className="nk-notification-text">
                    Your <span>Deposit Order</span> is placed
                  </div>
                  <div className="nk-notification-time">2 hrs ago</div>
                </div>
              </div>
            </div>
            {/* .nk-notification */}
          </div>
          {/* .nk-dropdown-body */}
          {/* <div className="dropdown-foot center">
                        <a href="#">View All</a>
                    </div> */}
        </div>
      </Popup>
    </div>
  );
};

export default Header;

import { IonCol, IonGrid, IonRow, isPlatform } from "@ionic/react";
import { Input, localePl, Popup, setOptions } from "@mobiscroll/react";
import React, { ChangeEvent, forwardRef, ReactNode, SetStateAction, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { GenerateGUID } from "./Common";
import './PIN.css'

setOptions({
  theme: 'ios',
  themeVariant: 'light',
  locale: localePl
});

export type PINModel = {
  focus: () => void;
  children?: ReactNode;
  setButtonDisabled: () => SetStateAction<Boolean>;
  inputsActive: Boolean;
  validEmptyPins: () => void;
  onFocus: () => void;
};


const PIN: React.FC<any> = forwardRef((props, ref) => {

  const inputRef = [
    useRef<Input>(null),
    useRef<Input>(null),
    useRef<Input>(null),
    useRef<Input>(null),
    useRef<Input>(null),
    useRef<Input>(null)
  ]

  const [inputs, setInputs] = useState(["", "", "", "", "", ""]);


  useImperativeHandle(ref, () => ({

    focus() {
      setTimeout(() => {
        console.log(inputs[0])
        if (inputRef[0].current != null && inputs[0] == "") {
          console.log(inputRef[0].current.nativeElement);
          inputRef[0].current.nativeElement.focus();
        }
      }, 50);
    },

    validEmptyPins() {

      inputRef.map(function (e) {

        if (e.current != null) {
          console.log(e.current.nativeElement.nodeValue)
          if (!Boolean(e.current.nativeElement.nodeValue)) {
            e.current.nativeElement.classList.add("error-border");
          }
        }

      })

    }

  }));

  useEffect(() => {

    props.setButtonDisabled(false);

  }, [])

  function removeErrorBorder() {
    inputRef.map(function (e) {

      if (e.current != null) {
        e.current.nativeElement.classList.remove("error-border");
      }

    })
  }

  function inputKeyDown(event: KeyboardEvent) {
    if (event.target != null) {
      const element = event.target as HTMLInputElement;
      if (event.key == "Backspace") {
        if (element.value == "") {
          var id = element.id.substring(element.id.length - 1, element.id.length)
          if (Boolean(inputRef[parseInt(id) - 1])) {
            const node = inputRef[parseInt(id) - 1].current;
            if (node != null) {
              node.nativeElement.focus();
            }
          }
          else {
            element.blur();
          }
        }
        else {
          element.value = element.value.slice(0, element.value.length - 1)

          var id = element.id.substring(element.id.length - 1, element.id.length)
          if (Boolean(inputRef[parseInt(id) - 1])) {
            const node = inputRef[parseInt(id) - 1].current;
            if (node != null) {
              node.nativeElement.focus();
            }
          }
          else {
            element.blur();
          }

        }

      }
      else if (element.value.length == 0 && ((parseInt(event.key) >= 0 && parseInt(event.key) <= 9))) {
        if (parseInt(event.key) >= 0 && parseInt(event.key) <= 9) {
          element.value = event.key
        }

        var id = element.id.substring(element.id.length - 1, element.id.length)
        if (Boolean(inputRef[parseInt(id) + 1])) {
          const node = inputRef[parseInt(id) + 1].current;
          if (node != null) {
            node.nativeElement.focus();
          }

        }
        else {
          element.blur();
        }

      }
      else if (element.value.length == 1 && ((parseInt(event.key) >= 0 && parseInt(event.key) <= 9))) {

        if (parseInt(event.key) >= 0 && parseInt(event.key) <= 9) {
          element.value = event.key
        }

        var id = element.id.substring(element.id.length - 1, element.id.length)
        if (Boolean(inputRef[parseInt(id) + 1])) {
          const node = inputRef[parseInt(id) + 1].current;
          if (node != null) {
            node.nativeElement.focus();
          }
        }
      }
      else if (event.key == "Tab") {
        var id = element.id.substring(element.id.length - 1, element.id.length)
        if (Boolean(inputRef[parseInt(id) + 1])) {
          const node = inputRef[parseInt(id) + 1].current;
          if (node != null) {
            node.nativeElement.focus();
          }
        }
      }

      inputs[parseInt(element.id.substring(element.id.length - 1, element.id.length))] = element.value
      setInputs(inputs)

      console.log('siema')
      var _buttonDisabled = true;
      inputs.map(function (e) {

        if (!Boolean(e)) {
          _buttonDisabled = false;
        }

      })
      props.setButtonDisabled(_buttonDisabled);

    }

    // if((event.target as HTMLInputElement).value.length > 0)
    // {
    //   (event.target as HTMLInputElement).classList.add("animate-test");
    // }

    event.preventDefault();
    event.stopPropagation();
  }

  function inputMobile(event?: ChangeEvent, eventKeyboard?: KeyboardEvent) {


    if(eventKeyboard != null)
    {
      if (eventKeyboard.key == "Backspace" && eventKeyboard.target != null) {
        const element = eventKeyboard.target as HTMLInputElement;
        if (element.value == "") {
          var id = element.id.substring(element.id.length - 1, element.id.length)
          if (Boolean(inputRef[parseInt(id) - 1])) {
            const node = inputRef[parseInt(id) - 1].current;
            if (node != null) {
              node.nativeElement.focus();
            }
          }
          else {
            element.blur();
          }
        }
        else {
          element.value = element.value.slice(0, element.value.length - 1)
  
          var id = element.id.substring(element.id.length - 1, element.id.length)
          if (Boolean(inputRef[parseInt(id) - 1])) {
            const node = inputRef[parseInt(id) - 1].current;
            if (node != null) {
              node.nativeElement.focus();
            }
          }
          else {
            element.blur();
          }
  
        }
  
        eventKeyboard.preventDefault();
        eventKeyboard.stopPropagation();

      }
    }

    if (event != null) {
      const _event = event.nativeEvent as InputEvent
      const element = event.target as HTMLInputElement;
      // if (_event.inputType == "deleteContentBackward") {
      //   if (element.value == "") {
      //     var id = element.id.substring(element.id.length - 1, element.id.length)
      //     if (Boolean(inputRef[parseInt(id) - 1])) {
      //       const node = inputRef[parseInt(id) - 1].current;
      //       if (node != null) {
      //         node.nativeElement.focus();
      //       }
      //     }
      //     else {
      //       element.blur();
      //     }
      //   }
      //   else {
      //     element.value = element.value.slice(0, element.value.length - 1)

      //     var id = element.id.substring(element.id.length - 1, element.id.length)
      //     if (Boolean(inputRef[parseInt(id) - 1])) {
      //       const node = inputRef[parseInt(id) - 1].current;
      //       if (node != null) {
      //         node.nativeElement.focus();
      //       }
      //     }
      //     else {
      //       element.blur();
      //     }

      //   }

      // }
      if (element.value.length == 0 && ((parseInt(_event.data as string) >= 0 && parseInt(_event.data as string) <= 9))) {
        if (parseInt(_event.data as string) >= 0 && parseInt(_event.data as string) <= 9) {
          element.value = _event.data as string
        }

        var id = element.id.substring(element.id.length - 1, element.id.length)
        if (Boolean(inputRef[parseInt(id) + 1])) {
          const node = inputRef[parseInt(id) + 1].current;
          if (node != null) {
            node.nativeElement.focus();
          }

        }
        else {
          element.blur();
        }

      }
      else if (element.value.length == 1 && ((parseInt(_event.data as string) >= 0 && parseInt(_event.data as string) <= 9))) {

        if (parseInt(_event.data as string) >= 0 && parseInt(_event.data as string) <= 9) {
          element.value = _event.data as string
        }
        var id = element.id.substring(element.id.length - 1, element.id.length)
        if (Boolean(inputRef[parseInt(id) + 1])) {
          const node = inputRef[parseInt(id) + 1].current;
          if (node != null) {
            node.nativeElement.focus();
          }
        }
        else {
          element.blur();
        }
      }
      else if (element.value.length > 1 && ((parseInt(_event.data as string) >= 0 && parseInt(_event.data as string) <= 9))) {
        element.value = element.value.substring(0, 1)

        var id = element.id.substring(element.id.length - 1, element.id.length)
        if (Boolean(inputRef[parseInt(id) + 1])) {
          const node = inputRef[parseInt(id) + 1].current;
          if (node != null) {
            node.nativeElement.focus();
          }
        }
      }
      else if (_event.data as string == "Tab") {
        var id = element.id.substring(element.id.length - 1, element.id.length)
        if (Boolean(inputRef[parseInt(id) + 1])) {
          const node = inputRef[parseInt(id) + 1].current;
          if (node != null) {
            node.nativeElement.focus();
          }
        }
      }

      inputs[parseInt(element.id.substring(element.id.length - 1, element.id.length))] = element.value
      setInputs(inputs)

      console.log('siema')
      var _buttonDisabled = true;
      inputs.map(function (e) {

        if (!Boolean(e)) {
          _buttonDisabled = false;
        }

      })
      props.setButtonDisabled(_buttonDisabled);

    }


    // if((event?.target as HTMLInputElement).value.length > 0)
    // {
    //   (event?.target as HTMLInputElement).classList.add("animate-test");
    // }


    if(event != null)
    {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  function RenderFields() {
    var rows = []
    if (true) {
      for (var i = 0; i < 6; i++) {
        rows.push(
          <Input
            inputClass={"test"}
            onFocus={() => {
              console.log('focus_child')
              props.onFocus()
            }}
            disabled={props.inputsActive}
            type="number"
            id={GenerateGUID() + "-" + i}
            autoComplete={GenerateGUID() + "-" + i}
            name={GenerateGUID() + "-" + i}
            ref={inputRef[i]}
            onMouseEnter={() => removeErrorBorder()}
            onClick={() => removeErrorBorder()}
            onChange={(event: ChangeEvent) => inputMobile(event, undefined)}
            onKeyDown={(event: KeyboardEvent) => inputMobile(undefined, event)}
            placeholder="" theme="ios" inputStyle="box" label="" />
        )
      }
    }
    else {
      for (var i = 0; i < 6; i++) {
        rows.push(
          <Input
            disabled={props.inputsActive}
            autoComplete="off"
            type="number"
            id={GenerateGUID() + "-" + i}
            ref={inputRef[i]}
            onMouseEnter={() => removeErrorBorder()}
            onClick={() => removeErrorBorder()}
            onKeyPress={(event: KeyboardEvent) => event.preventDefault()}
            onKeyUp={(event: KeyboardEvent) => event.preventDefault()}
            onKeyDown={(event: KeyboardEvent) => inputKeyDown(event)}
            placeholder="" theme="ios" inputStyle="box" label="" />
        )
      }
    }

    // for (var i = 0; i < 6; i++) {
    //   if(inputRef[i].current != null)
    //   {
    //     (inputRef[i].current as Input).nativeElement.nodeValue = inputs[i];
    //   }
    // }


    return (<>
      {rows.map(function (e, i) {
        if (i == 2) {
          return (<>{e}<div className="pin-sep"><div className="pin-sep-line" /></div></>)
        }
        else {
          return (e)
        }
      })}
    </>)

  }

  const memoRenderFields = useMemo(() => {

    return (
      <RenderFields />
    )

  }, [])

  return (

    <form autoComplete="off">
      <div className="pin-container">

        {memoRenderFields}

      </div>
    </form>

  )

});

export default PIN;
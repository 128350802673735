import './Login.css';

import React, { useState, useCallback, ChangeEventHandler, SyntheticEvent, useRef, useEffect, SetStateAction, Dispatch } from 'react';
import { Button, Input, platform, setOptions } from '@mobiscroll/react';
import { IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonPage, IonRouterOutlet, IonRow, isPlatform } from '@ionic/react';
import Header from '../components/Header';

import { ButtonLoader, GenerateGUID, isValidPesel } from '../components/Common'
import logo from '../svg/logo/symbol-blue.svg';
import PIN, { PINModel } from '../components/PIN';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';

import { useGlobalState, GlobalStateInterface } from "./../GlobalStateProvider";

import { Storage } from '@ionic/storage';

setOptions({
    theme: 'ios',
    themeVariant: 'light'
});


const _Page: React.FC = () => {

    const storage = new Storage();

    const { state } = useGlobalState();

    let history = useHistory();


    const { setState } = useGlobalState();

    const [login, setLogin] = useState(false);

    const [pesel, setPesel] = useState("");
    const [peselError, setPeselError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [inputsActive, setInputsActive] = useState(false);

    const refPin = useRef<PINModel>(null)
    const refPassword = useRef<Input>(null)
    const loginButtonRef = useRef<Button>(null)


    function Terms() {
        return (
            <div className="nk-block nk-auth-footer mt-md-5">
                <div className="nk-block-between">
                    <ul className="nav nav-sm" style={{ justifyContent: "center" }}>
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                                Regulamin
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                                Przetwarzanie danych
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                                Polityka prywatności
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                                Pomoc
                            </a>
                        </li>

                    </ul>
                    {/* .nav */}
                </div>

            </div>
        )
    }

    useEffect(() => {

        async function CreateStorage() {
            await storage.create();
        }
        CreateStorage();

        setInputsActive(buttonDisabled);

        if (buttonDisabled) {
            setTimeout(async () => {
                await storage.set('token', 'admin');
                setState((prev) => ({ ...prev, ...{ firstname: "siema", logged: true, token: "admin" } }));
                history.replace("/a/tab1")
            }, 2000);
        }

    }, [buttonDisabled])

    return (
        <IonPage>
            <IonContent className="background-lightgrey" >

                <div className="nk-split nk-split-page nk-split-md">
                    <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container">
                        {/* <div className="absolute-top-right d-lg-none p-3 p-sm-5">
                            <a
                                href="#"
                                className="toggle btn-white btn btn-icon btn-light"
                                data-target="athPromo"
                            >
                                <em className="icon ni ni-info" />
                            </a>
                        </div> */}

                        <div className="brand-logo">
                            <IonIcon src={logo} />
                        </div>

                        <div className="nk-block nk-block-middle nk-auth-body">





                            <IonPage>
                                <IonRouterOutlet id="login-router" animated={true} >
                                    <Route render={() =>
                                        [
                                            <div className="nk-block-head" id="login-head" style={{ scrollMargin: "20px" }}>
                                                <div className="nk-block-head-content">
                                                    <h5 className="nk-block-title">Logowanie</h5>
                                                    <div className="nk-block-des">
                                                        <p>
                                                            Uzyskaj dostęp do panelu jazdy.app. Jeżeli jeszcze nie masz konta, to poproś o nie w jednym z <strong><a href="">naszych OSK</a></strong>.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>,
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size="12" offset="0" className="no-padding no-margin-l-r-label">
                                                        <Input
                                                            placeholder=""
                                                            labelStyle="floating"
                                                            theme="ios"
                                                            inputStyle="box"
                                                            label="PESEL"
                                                            className="input-PESEL"
                                                            type="number"
                                                            maxLength="11"
                                                            step="1"
                                                            autoComplete="off"
                                                            id={GenerateGUID()}
                                                            error={Boolean(peselError)}
                                                            errorMessage={peselError}

                                                            onFocus={(event: InputEvent) => {

                                                                if (isPlatform("mobile")) {
                                                                    const element = document.getElementById("login-head")
                                                                    if (element != null) {
                                                                        element.scrollIntoView();
                                                                    }
                                                                }

                                                            }}

                                                            onKeyPress={(event: KeyboardEvent) => event.preventDefault()}
                                                            onKeyUp={(event: KeyboardEvent) => event.preventDefault()}

                                                            value={pesel}

                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {

                                                                setPesel(e.target.value);
                                                                setPeselError("")

                                                            }}

                                                            onKeyDown={(event: KeyboardEvent) => {
                                                                if (event.target != null) {
                                                                    const element = event.target as HTMLInputElement;
                                                                    console.log(element.value)
                                                                   
                                                                    if (event.key == "Backspace") {
                                                                        element.value = element.value.slice(0, element.value.length - 1)
                                                                    }

                                                                    else if (element.value.length < 11) {
                                                                        if (parseInt(event.key) >= 0 && parseInt(event.key) <= 9) {
                                                                            element.value = element.value + event.key
                                                                        }
                                                                    }
                                                                    setPesel(element.value);
                                                                    if (element.value.length >= 11) {
                                                                        if (isValidPesel(element.value.toString())) {
                                                                            if(refPassword.current != null)
                                                                            {
                                                                                refPassword.current.nativeElement.focus();
                                                                            }
                                                                            setPeselError("")
                                                                        }
                                                                        else {
                                                                            setPeselError("PESEL jest nieprawidłowy")
                                                                        }
                                                                    }
                                                                    else {
                                                                        setPeselError("")
                                                                    }
                                                                }
                                                                event.preventDefault();
                                                                event.stopPropagation();

                                                            }}

                                                        ></Input>
                                                    </IonCol>
                                                    <IonCol size="12" offset="0" className="no-padding no-margin-l-r-label">
                                                        <Input placeholder="Wpisz hasło" labelStyle="floating" theme="ios" inputStyle="box" label="Hasło" type="password" passwordToggle={true}
                                                            value={password}
                                                            error={Boolean(passwordError)}
                                                            errorMessage={passwordError}
                                                            ref={refPassword}

                                                            onKeyUp={(event: KeyboardEvent) => {

                                                                if(event.key == 'Enter' || event.keyCode == 13)
                                                                {
                                                                    if(loginButtonRef.current != null)
                                                                    {
                                                                        loginButtonRef.current.nativeElement.click();
                                                                    }
                                                                }

                                                            }}

                                                            onFocus={(event: InputEvent) => {

                                                                if (isPlatform("mobile")) {
                                                                    const element = document.getElementById("login-head")
                                                                    if (element != null) {
                                                                        element.scrollIntoView();
                                                                    }
                                                                }

                                                            }}

                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {

                                                                setPassword(e.target.value);
                                                                setPasswordError("")

                                                            }}

                                                        ></Input>
                                                    </IonCol>
                                                </IonRow>

                                            </IonGrid>,

                                            <div className="form-group" style={{ marginTop: "12px" }}>
                                                <Button
                                                    ref={loginButtonRef}
                                                    theme="none"
                                                    className={"btn btn-lg btn-primary btn-block btn-login " + (login ? "btn-loading" : "")}
                                                    disabled={login}
                                                    onClick={() => {

                                                        setLogin(true);

                                                        let submit = true;

                                                        if (Boolean(pesel)) {
                                                            if (!isValidPesel(pesel)) {
                                                                submit = false;
                                                                setPeselError("PESEL jest nieprawidłowy")
                                                            }
                                                        }
                                                        else {
                                                            submit = false;
                                                            setPeselError("PESEL nie może być pusty")
                                                        }
                                                        if (Boolean(password)) {
                                                            if (password.length <= 0) {
                                                                submit = false;
                                                                setPasswordError("Hasło nie może być puste")
                                                            }
                                                        }
                                                        else {
                                                            submit = false;
                                                            setPasswordError("Hasło nie może być puste")
                                                        }


                                                        if (submit) {
                                                            setTimeout(() => {

                                                                setLogin(false);

                                                                history.replace("/login/sms");

                                                            }, 500);
                                                        }
                                                        else {
                                                            setLogin(false);
                                                        }

                                                    }}

                                                >
                                                    <ButtonLoader />
                                                    <span>Zaloguj się</span>
                                                </Button>
                                            </div>,

                                            <div className="form-note-s2 pt-4 pt-md-2">
                                                <Button theme="none" className="btn btn-lg btn-outline-primary btn-dim btn-block btn-login">
                                                    <ButtonLoader />
                                                    <span>Pierwsze logowanie</span>
                                                </Button>
                                            </div>,
                                            <div className="text-center pt-2 pb-2">
                                                <h6 className="overline-title overline-title-sap">
                                                    <span>OR</span>
                                                </h6>
                                            </div>,
                                            <div className="form-note-s2 pb-3">
                                                <Button theme="none" className="btn btn-lg btn-outline-primary btn-dim btn-block btn-login">
                                                    <ButtonLoader />
                                                    <span>Logowanie do panelu OSK</span>
                                                </Button>
                                            </div>,
                                            <div className="form-note-s2 text-center">
                                                Zapomniałeś/aś hasła?{"  "}
                                                <strong><a href="html/pages/auths/auth-register.html">Odzyskaj hasło</a></strong>
                                            </div>
                                        ]
                                    } path="/login" exact={true} />

                                    <Route render={() => {

                                        if(!isValidPesel(pesel) || !Boolean(password))
                                        {
                                            history.replace("/login")
                                        }

                                        if (refPin.current != null) {
                                            if(refPin.current.focus != null)
                                            {
                                                console.log('focused')
                                                refPin.current.focus();
                                            }
                                        }

                                        return (
                                            [
                                                <div className="nk-block-head" id="verification-head" style={{ scrollMargin: "20px" }}>
                                                    <div className="nk-block-head-content">
                                                        <h5 className="nk-block-title">Weryfikacja użytkownika</h5>
                                                        <div className="nk-block-des">
                                                            <p>
                                                                Na twój telefon został wysłany kod, który należy wpisać poniżej.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>,
                                                <IonGrid id="pin-grid">
                                                    <IonRow>
                                                        <IonCol size="12" offset="0" className="no-padding no-margin-l-r-label">
                                                            <PIN onFocus={() => {

                                                                // if (isPlatform("mobile")) {
                                                                //     const element = document.getElementById("pin-grid")
                                                                //     if (element != null) {
                                                                //         element.scrollIntoView({block: "center"});
                                                                //     }
                                                                // }

                                                            }} ref={refPin} setButtonDisabled={setButtonDisabled} inputsActive={inputsActive} />
                                                        </IonCol>
                                                    </IonRow>
                                                </IonGrid>,
                                                <div className="form-group" style={{ marginTop: "12px" }}>
                                                    <Button
                                                        theme="none"
                                                        className={"btn btn-lg btn-primary btn-block btn-login " + (buttonDisabled ? "btn-loading" : "")}
                                                        disabled={buttonDisabled}
                                                        onClick={() => {

                                                            // if(refPin.current != null)
                                                            // {
                                                            //     refPin.current.validEmptyPins();
                                                            // }

                                                            setTimeout( async () => {
                                                                await storage.set('token', 'admin');
                                                                setState((prev) => ({...prev, ...{firstname: "siema", logged: true, token: "admin"} }));
                                                                history.replace("/a/tab1")
                                                            }, 2000);

                                                        }}

                                                    >
                                                        <ButtonLoader />
                                                        <span>Potwierdź</span>
                                                    </Button>
                                                </div>
                                            ])
                                    }
                                    } path="/login/sms" exact={true} />

                                    

                                </IonRouterOutlet>
                            </IonPage>

                            {
                                        !isPlatform("mobile")
                                        ?
                                        <Terms/>
                                        :
                                        ""
                                    }
                            


                            {/* <div className="text-center pt-4 pb-3">
                                <h6 className="overline-title overline-title-sap">
                                    <span>OR</span>
                                </h6>
                            </div>
                            <ul className="nav justify-center gx-4">
                                <li className="nav-item">
                                    <a className="nav-link" href="#">
                                        Facebook
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">
                                        Google
                                    </a>
                                </li>
                            </ul>
                            <div className="text-center mt-5">
                                <span className="fw-500">
                                    I don't have an account? <a href="#">Try 15 days free</a>
                                </span>
                            </div> */}

                            
                                {/* <div className="mt-3">
                                    <p>© 2019 DashLite. All Rights Reserved.</p>
                                </div> */}
                            </div>

                            {
                                isPlatform("mobile")
                                ?
                                <Terms/>
                                :
                                ""
                            }
                        
                        {/* .nk-block */}

                        {/* .nk-block */}
                    </div>
                    {/* .nk-split-content */}
                    <div
                        style={{ background: "white" }}
                        className="nk-split-content nk-split-stretch d-flex toggle-break-lg toggle-slide toggle-slide-right"
                        data-content="athPromo"
                        data-toggle-screen="lg"
                        data-toggle-overlay="true"
                    >
                        <div className="slider-wrap w-100 w-max-550px p-3 p-sm-5 m-auto">



                            {/* .slider-init */}
                            <div className="slider-dots" />
                            <div className="slider-arrows" />
                        </div>
                        {/* .slider-wrap */}
                    </div>
                    {/* .nk-split-content */}
                </div>
                {/* .nk-split */}


            </IonContent>



        </IonPage>
    );
};

export default _Page
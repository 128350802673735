import {
  IonCol,
  IonGrid,
  IonRow,
  isPlatform,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { Input, localePl, Popup, setOptions } from "@mobiscroll/react";
import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  RefObject,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";


import MobiInput from "../Mobi/MobiInput";
import MobiButton from "../Mobi/MobiButton";
import MobiPopup from "../Mobi/MobiPopup";
import { useHistory } from "react-router";

import Categories from "../../data/categories.json";

setOptions({
  theme: "ios",
  themeVariant: "light",
  locale: localePl,
});

export type CategoryProps = {
  id: string;
  name: string;
  nameShow?: string;
  icon: string;
  iconShow?: string;
};

export type CollectionProps = {
  id: string;
  name: string;
  nameShow?: string;
  icon: string;
  iconShow?: string;
  next?: CollectionProps[];
};

export type ForwardProps = {
  closeModal: () => void;
  setCategory: (category: CategoryProps) => void;
};

const CategorySearchInput: React.FC<any> = forwardRef((props, _ref) => {
  const popupRef = useRef<Popup>(null);

  useIonViewWillEnter(() => {
    if (popupRef.current) {
      if (popupRef.current._isOpen) {
        popupRef.current.close();
      }
    }
  });

  useIonViewWillLeave(() => {
    if (popupRef.current) {
      if (popupRef.current._isOpen) {
        popupRef.current.close();
      }
    }
  });

  useImperativeHandle(_ref, () => ({
    closeModal() {
      if (popupRef.current) {
        if (popupRef.current._isOpen) {
          popupRef.current.close();
        }
      }
    },
    setCategory(category: CategoryProps) {
      setCategory(category);
    },
    getCategory() {
      return category;
    },
  }));

  const history = useHistory();

  const ref = useRef<HTMLAnchorElement>(null);

  const [collection, setCollection] = useState<CollectionProps[]>(
    Categories as CollectionProps[]
  );

  const [previous, setPrevious] = useState<CollectionProps[][]>([]);

  const [isPopupOpen, setOpen] = useState(false);
  const [popupWidth, setPopupWidth] = useState(400);

  const formFloatingRef = useRef<HTMLDivElement>(null);

  const [category, setCategory] = useState<CategoryProps>({
    id: "",
    name: "",
    icon: "",
  });

  const openModal = () => {
    if (formFloatingRef.current) {
      setPopupWidth(formFloatingRef.current.clientWidth);
    }
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <a
        style={{ display: "block" }}
        onClick={() => {
          openModal();
        }}
        ref={ref}
      >
        <div style={{ pointerEvents: "none" }}>
        <MobiInput
          label="Kategoria"
          required
          placeholder="Wybierz kategorię"
          type="search"
          autoComplete="off"
          id={"post-code"}
          value={category.nameShow ? category.nameShow : category.name}
        />
        </div>
      </a>

      <MobiPopup
        updateTop={props.updateTop}
        popupRef={popupRef}
        anchor={ref?.current}
        isOpen={isPopupOpen}
        onClose={() => closeModal()}
        cssClass="mbsc-no-padding"
        contentPadding={false}
        showOverlay={false}
        scrollLock={false}
        width={popupWidth}
        responsive={{
          xsmall: {
            cssClass: "mobile popup-dropdown-custom",
            display: "bottom",
            maxHeight: "calc( 95% - 55px )",
            showOverlay: true,
            buttons: [
              {
                icon: "material-close",
                // text: "Wyjdź",
                handler: function () {
                  closeModal();
                },
              },
            ],
          },
          medium: {
            cssClass: "desktop popup-dropdown-custom",
            display: "anchored",
            maxHeight: "800px",
          },
        }}
      >
        <div className="dropdown-menu show">
          {collection.map((e, i) => {
            return (
              <div className="dropdown-inner">
                <ul className="link-list">
                  <li>
                    <a
                      onClick={() => {
                        if (e.next) {
                          setPrevious([...previous, collection]);
                          setCollection([
                            {
                              name: "Kategoria wyżej",
                              icon: "fas fa-redo",
                              id: "up",
                            },
                            ...e.next,
                          ]);
                        } else if (!e.next && e.id != "up") {
                          closeModal();
                        }
                        if (e.id == "up" && previous) {
                          setCollection(previous[previous.length - 1]);
                          let _previous = previous;
                          _previous.pop();
                          setPrevious(_previous);
                        } else {
                          setCategory({
                            id: e.id,
                            name: e.name,
                            nameShow: e.nameShow,
                            icon: e.icon,
                            iconShow: e.iconShow,
                          });
                        }
                      }}
                    >
                      <em
                        style={{
                          fontWeight: i == 0 ? 700 : 500,
                          color: "var(--ion-color-primary)",
                        }}
                        className={e.icon}
                      />
                      <span
                        style={{
                          fontWeight: i == 0 ? 700 : 500,
                          paddingRight: "25px",
                        }}
                      >
                        {e.name}
                      </span>
                      {e.next ? (
                        <em
                          style={{
                            marginLeft: "auto",
                            fontWeight: 900,
                            fontSize: "17px",
                          }}
                          className={"fas fa-ellipsis-h"}
                        />
                      ) : e.id == "up" || e.id == "any" ? (
                        <></>
                      ) : (
                        <span style={{ marginLeft: "auto" }}>
                          {Math.floor(
                            Math.random() * (Math.floor(120) - Math.ceil(0))
                          ) + Math.ceil(0)}
                        </span>
                      )}
                    </a>
                  </li>
                </ul>
              </div>
            );
          })}
        </div>
      </MobiPopup>
    </>
  );
});

export default CategorySearchInput;

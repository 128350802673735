import { IonCol, IonGrid, IonRow, isPlatform } from "@ionic/react";
import { Input, localePl, Popup, Select, setOptions } from "@mobiscroll/react";
import {
  MbscSelectOnFilterEvent,
  MbscSelectOptions,
} from "@mobiscroll/react/dist/src/core/components/select/select";
import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Spinner, titleCase } from "../Common";

import './MobiSelect.css';


export const SelectDataPrimary = (data: any[]) => {
  let tempArray: any[] = [];
  data.forEach((e) => {
    const splitted = (e.text as string).split(" | ");
    tempArray.push(
      {
        text: <div className="select-data-primary"><div>{splitted[0]}<span>{splitted[1]}</span></div></div>,
        value: e.value,
        text2: e.text2
      }
    )
  })
  return tempArray;
}

setOptions({
  theme: "ios",
  themeVariant: "light",
  locale: localePl,
});

type Props = {
  data?: any[];
  label?: string;
  labelBottom?: string;
  placeholder?: string;
  filter?: boolean;
  responsive?: { [key: string]: any };
  responsive_xsmall?: object;
  onChange?: (args: any, inst: any) => void;
  onTempChange?: (args: any, inst: any) => void;
  loading?: boolean;
  disabled?: boolean;
  autoComplete?: boolean;
  itemHeight?: number;
  selectMultiple?: boolean;
  required?: boolean;
  borderless?: boolean;
  defaultValue?: string;
};

const MobiSelect: React.FC<Props> = ({
  data,
  label,
  labelBottom,
  filter,
  responsive,
  responsive_xsmall,
  onChange,
  onTempChange,
  loading,
  disabled,
  autoComplete,
  itemHeight,
  selectMultiple,
  placeholder,
  defaultValue,
  required = false,
  borderless = false
}) => {

  useEffect(() => {

    if(defaultValue)
    {
      ref.current?.setVal(defaultValue);
    }

  }, [])

  const ref = useRef<Select>(null);
  let input: any;
  let spanInput: any;

  const [dataState, setDataState] = useState(data);
  const defaultData = data;

  const _responsive = {
    xsmall: responsive_xsmall ? responsive_xsmall : {
      display: "center",
      touchUi: true,
      scroll3d: false
    },
    medium: {
      touchUi: false,
      showOverlay: false,
      onOpen: (e: any) => {

        if(ref.current)
        {
          if(!ref.current.getVal())
          {
            const tempElement = document.querySelector(".mbsc-selected");

            if(tempElement)
            {
              tempElement.classList.remove(".mbsc-selected");
            }

          }
        }

        if(isPlatform("mobile"))
        {
          return;
        }

        if (ref.current != null) {

          const inp = e.inst._input as HTMLInputElement;
          const inpBounding = inp.getBoundingClientRect();

          input = inp;

          const target = e.target as HTMLDivElement;
          
          const targetBody = target.querySelector(
            ".mbsc-popup-body"
          ) as HTMLDivElement;
          const targetPopups = target.querySelector(
            ".mbsc-popup"
          ) as HTMLDivElement;
          const targetInputContainer = target.querySelector(
            ".mbsc-select-filter-cont"
          ) as HTMLDivElement;
          const targetInputLabel = target.querySelector(
            ".mbsc-select-filter"
          ) as HTMLLabelElement;
          const targetInput = target.querySelector(
            ".mbsc-select-filter-input"
          ) as HTMLInputElement;
          const _spanInput = target.querySelector(
            ".mbsc-textfield-tags-inner"
          ) as HTMLInputElement;

          console.log(_spanInput)

          if (target) {
            if (filter && !selectMultiple) {
              targetInputContainer.style.padding = "0";
              targetInputLabel.style.margin = "0";
              targetInput.style.height = "58.8px";
              targetInput.style.borderRadius = "0";
              // targetInput.style.fontSize = "1.05rem";
              // targetInput.style.letterSpacing = "0.3px";

              if(inpBounding.top + targetPopups.offsetHeight + 58.8 + 0 > window.innerHeight)
              {
                targetPopups.style.transform = "translate(0, 62px)";
              }


            } else {

              const targetBody = target.querySelector(
                ".mbsc-popup-body"
              ) as HTMLDivElement;

              if(inpBounding.top + targetPopups.offsetHeight + 58.8 + 70 > window.innerHeight)
              {
                target.style.top = Number(target.style.top.replaceAll("px", "")) + 67 + "px";
                if(!borderless)
                {
                  // targetBody.style.boxShadow = "rgba(0, 0, 0, 0.24) 0px -6px 8px"
                  targetBody.style.boxShadow = "0 2px 8px 0 rgb(99 99 99 / 16%)";
                }
                targetBody.style.borderBottomRightRadius = "0";
                targetBody.style.borderBottomLeftRadius = "0";
                targetBody.style.border = "1px solid #ebebeb";
                inp.style.borderTopRightRadius = "0"
                inp.style.borderTopLeftRadius = "0"
                if(_spanInput)
                {
                  spanInput = _spanInput;
                  _spanInput.style.borderTopRightRadius = "0"
                  _spanInput.style.borderTopLeftRadius = "0"
                }
              }
              else
              {
                target.style.top = Number(target.style.top.replaceAll("px", "")) + 58 + "px";
                if(!borderless)
                {
                  targetBody.style.boxShadow = "0 2px 8px 0 rgb(99 99 99 / 16%)";
                }
                targetBody.style.borderTopRightRadius = "0";
                targetBody.style.borderTopLeftRadius = "0";
                targetBody.style.border = "1px solid #ebebeb";
                inp.style.borderBottomRightRadius = "0"
                inp.style.borderBottomLeftRadius = "0"
                if(_spanInput)
                {
                  spanInput = _spanInput;
                  _spanInput.style.borderTopRightRadius = "0"
                  _spanInput.style.borderTopLeftRadius = "0"
                }
              }

              

              

              
            }
          }

          const selectTarget = e.inst.nativeElement as HTMLInputElement;

          const targetPopup = target.querySelector(".mbsc-popup");

          if (targetPopup != null) {
            (targetPopup as HTMLDivElement).style.width =
              (Number(selectTarget.offsetWidth)) + "px";
          }
        }
      },
      onClose: (e: any) => {
        if (input) {
          input.style.borderBottomRightRadius = "";
          input.style.borderBottomLeftRadius = "";
          input.style.borderTopRightRadius = "";
          input.style.borderTopLeftRadius = "";
          if (spanInput) {
            spanInput.style.borderTopRightRadius = "0";
            spanInput.style.borderTopLeftRadius = "0";
          }
        }
      }
    },
  };


  return (
    <form style={{ position: (!required ? "relative" : "unset") }}>
    <Select
      endIconSvg={Boolean(loading) ? Spinner : undefined}
      ref={ref}
      className="input-bold"
      labelStyle="floating"
      inputStyle="box"
      label={label}
      data={dataState}

      

      selectMultiple={selectMultiple}

      focusOnClose={false}
  
      filterPlaceholderText={placeholder ? placeholder : ""}

      filter={filter}

      rows={((dataState != undefined) ? dataState.length > 20 ? undefined: dataState.length : undefined)}

      itemHeight={itemHeight ? itemHeight : 40}

      responsive={Boolean(responsive) ? responsive : _responsive}
      onChange={(args: any, inst: any) => {

        if(onChange)
        {
          onChange(args, inst);
        }

        if(dataState)
        {
          var _value = dataState.filter(obj => {
            return obj.value == inst.value;
          })[0]


          if (_value) {
            if (_value.text2 || inst.value == "") {
              if(!_value.text2)
              {
                setTimeout(() => {
                  inst.nativeElement.value = "";
                }, 50);
              }
              else
              {
                setTimeout(() => {
                  inst.nativeElement.value = _value.text2;
                }, 50);
              }
            }
          }
        }

      }}
      disabled={disabled}
      maxWidth={2000}
      onFilter={(ev: MbscSelectOnFilterEvent, inst: any) => {
        if (autoComplete) {
          if (dataState && defaultData && ev.filterText) {
            setDataState([
              {
                text: titleCase(ev.filterText),
                value: ev.filterText,
                group: "9999",
              },
              ...defaultData,
            ]);
          } else if (!ev.filterText && defaultData) {
            setDataState(defaultData);
          }
        }

        return true;
      }}
    />
    {
      labelBottom
      ?
      <div className="select-label-bottom">
        {labelBottom}
      </div>
      :
      <></>
    }
    {
      !required
      ?
      <span className=" mbsc-ios mbsc-ltr mbsc-error-message mbsc-optional-message-box mbsc-error-message-floating" style={{ opacity: (disabled ? "0.5" : "0.9") }} >Opcjonalne</span>
      :
      <></>
    }
  </form>
  );
};

export default MobiSelect;

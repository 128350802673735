export const Main = () => {
    

    handlePreloader();


    var animateHTML = function() {
        var elems:any;
        var windowHeight:any;

        function init() {
            elems = document.querySelectorAll('.pxp-animate-in');
            windowHeight = window.innerHeight;
            //addEventHandlers();
            checkPosition();
        }

        // function addEventHandlers() {
        //     window.addEventListener('scroll', checkPosition);
        //     window.addEventListener('resize', init);
        // }

        function checkPosition() {
            for (var i = 0; i < elems.length; i++) {
                var positionFromTop = elems[i].getBoundingClientRect().top;

                if (positionFromTop - windowHeight <= 0) {
                    elems[i].classList.add('pxp-in');
                    if(elems[i])
                    {
                        if (elems[i].classList.contains('pxp-info-stats-item')) {
                            animateBubbles(elems[i]);
                        }
                    }
                    if(elems[i])
                    {
                        if (elems[i].classList.contains('pxp-testimonials-1-circles-item')) {
                            animateTestimonialsCircles(elems[i]);
                        }
                    }
                    
                }
            }
        }

        return {
            init: init
        };
    };

    function handlePreloader() {
      setTimeout(() => {
        //animateHTML().init();
        animateHeroElement(".pxp-hero-right-bg-card");
        animateHeroElement(".pxp-hero-card-info");
        animateHeroElement(".pxp-hero-cards-container");
        animateHeroElement(".pxp-hero-center-carousel");
        setTimeout(function () {
          animateHeroElement(".pxp-hero-stats-item");
        }, 200);
        setTimeout(function () {
          animateHeroElement(".pxp-header-side-image");
        }, 200);

        setTimeout(function () {
          animateHeroElement(".pxp-contact-us-form");
        }, 200);

        animateHeroElement(".pxp-hero-boxed-circulars");
        animateHeroElement(".pxp-hero-boxed-icon-circle-1");
        animateHeroElement(".pxp-hero-boxed-icon-circle-2");

        animateHeroElement(".pxp-hero-boxed-info-card-big");
        animateHeroElement(".pxp-hero-boxed-info-card-small");
        animateHeroElement(".pxp-hero-boxed-info-list-container");

        animateOnMouseMove(".pxp-mouse-move");
      }, 200);
    }

    function windowResizeHandler() {
        resizeHeroBoxedCirculars();
    }

    function onContentScroll() {
        if (document.querySelector('.pxp-header')?.classList.contains('pxp-bigger') || document.querySelector('.pxp-header')?.classList.contains('pxp-no-bg')) {
            if (window.pageYOffset > 20) {
                document.querySelector('.pxp-header')?.classList.add('pxp-is-sticky');
            } else {
                document.querySelector('.pxp-header')?.classList.remove('pxp-is-sticky');
            }
        } else if (document.querySelector('.pxp-header')?.classList.contains('pxp-no-bg')) {
            if (window.pageYOffset > 0) {
                document.querySelector('.pxp-header')?.classList.add('pxp-is-sticky');
            } else {
                document.querySelector('.pxp-header')?.classList.remove('pxp-is-sticky');
            }
        } else {
            if (window.pageYOffset > 93) {
                document.querySelector('.pxp-header')?.classList.add('pxp-is-sticky');
            } else {
                document.querySelector('.pxp-header')?.classList.remove('pxp-is-sticky');
            }
        }
    }

    window.onscroll = function() {
        onContentScroll();
        console.log('siema')
    };

    windowResizeHandler();

    function animateHeroElement(element:any) {
        if(document.querySelector(element))
        {
            if (document.querySelector(element).classList.contains('pxp-has-animation')) {
                document.querySelector(element).classList.add('pxp-animate');
            }
        }
        if(document.querySelector(element))
        {
            if (document.querySelector(element).classList.contains('pxp-animate-cards')) {
                setTimeout(function() {
                    document.querySelector(element).querySelector('.pxp-hero-card').classList.add('pxp-animate');
                }, 600);
                setTimeout(function() {
                    document.querySelector(element).querySelector('.pxp-hero-card-dark').classList.add('pxp-animate');
                    document.querySelector(element).querySelector('.pxp-hero-card-light').classList.add('pxp-animate');
                }, 1200);
            }
        }
        if(document.querySelector(element))
        {
            if (document.querySelector(element).classList.contains('pxp-animate-bounce')) {
                setTimeout(function() {
                    document.querySelector(element).classList.add('animate__animated');
                    document.querySelector(element).classList.add('animate__bounceIn');
                }, 1800);
            }
        }
        if(document.querySelector(element))
        {
            if (document.querySelector(element).classList.contains('pxp-animate-circles-bounce')) {
                document.querySelector(element).classList.add('animate__animated');
                document.querySelector(element).classList.add('animate__bounceIn');
            }
        }
        if(document.querySelector(element))
        {
            if (document.querySelector(element).classList.contains('pxp-animate-info-card')) {
                setTimeout(function() {
                    document.querySelector(element).classList.add('animate__animated');
                    document.querySelector(element).classList.add('animate__flipInX');
                }, 300);
            }
        }
        if(document.querySelector(element))
        {
            if (document.querySelector(element).classList.contains('pxp-animate-icon-circle-bounce')) {
                setTimeout(function() {
                    document.querySelector(element).classList.add('animate__animated');
                    document.querySelector(element).classList.add('animate__bounceIn');
                }, 800);
            }
        }
    }

    function animateBubbles(element:any) {
        if (document.querySelector(element).classList.contains('pxp-animate-bounce')) {
            setTimeout(function() {
                document.querySelector(element).classList.add('animate__animated');
                document.querySelector(element).classList.add('animate__bounceIn');
            }, 500);
        }
    }

    function animateTestimonialsCircles(element:any) {
        if (document.querySelector(element).classList.contains('pxp-animate-bounce')) {
            setTimeout(function() {
                document.querySelector(element).classList.add('animate__animated');
                document.querySelector(element).classList.add('animate__bounceIn');
            }, 200);
        }
    }

    function animateOnMouseMove(element:any) {
        const mouseMoveElems = document.querySelectorAll(element);

        mouseMoveElems.forEach(function(mouseMoveElem) {
            var speed = mouseMoveElem.getAttribute('data-speed');

            window.addEventListener('mousemove', (evt) => {
                const x = -(window.innerWidth / 2 - evt.pageX) / parseInt(speed);
                const y = -(window.innerHeight / 2 - evt.pageY) / parseInt(speed);
                mouseMoveElem.style.transform = `translateY(${y}px) translateX(${x}px)`;
            });
        });
    }

    function resizeHeroBoxedCirculars() {
        // var circularsWidth = document.querySelector('.pxp-hero-boxed-circulars')?.clientWidth;
        // (document.querySelector('.pxp-hero-boxed-circulars') as HTMLDivElement).style.height = circularsWidth + "px";
    }

   

    if(document.querySelector('.pxp-animate-icon'))
    {
        document.querySelector('.pxp-animate-icon')?.addEventListener("hover", function() {
            document.querySelector('.pxp-animate-icon')?.querySelector('img')?.classList.add('animate__animated animate__jackInTheBox');
            document.querySelector('.pxp-animate-icon')?.querySelector('img')?.classList.remove('animate__animated animate__jackInTheBox');
        })
    }
    

    // Price plans switcher
    // if(document.querySelector('[name=pxp-price-plans-switcher]'))
    // {
    //     document.querySelector('[name=pxp-price-plans-switcher]').addEventListener('change', function() {
    //         var checkedValue = document.querySelector('[name=pxp-price-plans-switcher]:checked').attr('data-period');
    
    //         if (checkedValue == 'month') {
    //             document.querySelector('.pxp-plans-price-annual').hide();
    //             document.querySelector('.pxp-plans-price-monthly').show();
    //         } else {
    //             document.querySelector('.pxp-plans-price-monthly').hide();
    //             document.querySelector('.pxp-plans-price-annual').show();
    //         }
    //     });
    // }

    // if(document.querySelector('.pxp-categories-carousel'))
    // {
    //     if (document.querySelector('.pxp-categories-carousel').length > 0) {
    //         document.querySelector('.pxp-categories-carousel').owlCarousel({
    //             'nav': false,
    //             'dots': true,
    //             'margin': 30,
    //             'loop': false,
    //             'responsive': {
    //                 0: {
    //                     'items': 1
    //                 },
    //                 600: {
    //                     'items': 2
    //                 },
    //                 900: {
    //                     'items': 4
    //                 },
    //                 1600: {
    //                     'items': 6
    //                 }
    //             },
    //             'checkVisible': false,
    //             'smartSpeed': 600
    //         });
    //     }
    // }

    // Set checked badge color for jobs list filter
    // if(document.querySelector('.pxp-jobs-list-side-filter .list-group-item input[type="checkbox"'))
    // {
    //     document.querySelector('.pxp-jobs-list-side-filter .list-group-item input[type="checkbox"')?.addEventListener('change', function() {
    //         if (document.querySelector('.pxp-jobs-list-side-filter .list-group-item input[type="checkbox"')?.is(":checked")) {
    //             document.querySelector(this).parent().parent().classList.add('pxp-checked');
    //         } else {
    //             document.querySelector(this).parent().parent().removeClass('pxp-checked');
    //         }
    //     });
    // }

    // Company dashboard charts
    // if(document.querySelector('#pxp-company-dashboard-visits-chart'))
    // {
    //     if (document.querySelector('#pxp-company-dashboard-visits-chart').length > 0) {
    //         var companyVisitsChartElem = document.getElementById('pxp-company-dashboard-visits-chart').getContext('2d');
    
    //         var gradient = companyVisitsChartElem.createLinearGradient(0, 250, 0, 0);
    //         gradient.addColorStop(0, 'rgba(255, 255, 255, 0)');
    //         gradient.addColorStop(.5, 'rgba(0, 112, 201, 0.09)');
    //         gradient.addColorStop(1, 'rgba(0, 112, 201, 0.12)');
    
    //         var companyVisitsChart = new Chart(companyVisitsChartElem, {
    //             type: 'line',
    //             data: {
    //                 labels: ['Oct 06', 'Oct 07', 'Oct 08', 'Oct 09', 'Oct 10', 'Oct 11', 'Oct 12'],
    //                 datasets: [{
    //                     label: 'Visits',
    //                     data: [12, 56, 34, 78, 38, 28, 54],
    //                     borderWidth: 3,
    //                     borderColor: 'rgba(0, 112, 201, 1)',
    //                     pointBackgroundColor: 'rgba(255, 255, 255, 0)',
    //                     pointHoverBackgroundColor: 'rgba(255, 255, 255, 1)',
    //                     pointBorderColor: 'rgba(66, 133, 244, 0)',
    //                     pointHoverBorderColor: 'rgba(0, 112, 201, 1)',
    //                     pointBorderWidth: 10,
    //                     pointHoverBorderWidth: 3,
    //                     pointHitRadius: 20,
    //                     cubicInterpolationMode: 'monotone',
    //                     fill: true,
    //                     backgroundColor: gradient
    //                 }]
    //             },
    //             options: {
    //                 scales: {
    //                     xAxes: [{
    //                         ticks: {
    //                             fontColor: 'rgba(153, 153, 153, 1)',
    //                             maxTicksLimit: 7,
    //                             maxRotation: 0
    //                         },
    //                         gridLines: {
    //                             zeroLineColor: 'rgba(232, 232, 232, 1)',
    //                             drawOnChartArea: false,
    //                         },
    //                     }],
    //                     yAxes: [{
    //                         ticks: {
    //                             beginAtZero: true,
    //                             fontColor: 'rgba(153, 153, 153, 1)',
    //                             callback: function(value, index, values) {
    //                                 if (Math.floor(value) === value) {
    //                                     return value;
    //                                 }
    //                             }
    //                         },
    //                         gridLines: {
    //                             zeroLineColor: 'rgba(232, 232, 232, 0)',
    //                         },
    //                     }],
    //                 },
    //                 responsive: true,
    //                 tooltips: {
    //                     backgroundColor: 'rgba(0, 39, 69, 1)',
    //                     cornerRadius: 7,
    //                     mode: 'index',
    //                     intersect: false,
    //                     displayColors: false,
    //                     xPadding: 10,
    //                     yPadding: 10,
    //                     titleFontColor: 'rgba(255, 255, 255, .7)',
    //                     bodyFontColor: 'rgba(255, 255, 255, 1)',
    //                     titleFontStyle: 'normal',
    //                     bodyFontStyle: 'bold',
    //                 },
    //                 legend: {
    //                     display: false,
    //                 }
    //             }
    //         });
    //     }
    // }
    // if(document.querySelector('#pxp-company-dashboard-app-chart'))
    // {
    //     if (document.querySelector('#pxp-company-dashboard-app-chart').length > 0) {
    //         var companyAppChartElem = document.getElementById('pxp-company-dashboard-app-chart').getContext('2d');
    
    //         var gradient = companyVisitsChartElem.createLinearGradient(0, 250, 0, 0);
    //         gradient.addColorStop(0, 'rgba(255, 255, 255, 0)');
    //         gradient.addColorStop(.5, 'rgba(255, 168, 35, 0.09)');
    //         gradient.addColorStop(1, 'rgba(255, 168, 35, 0.12)');
    
    //         var companyAppChart = new Chart(companyAppChartElem, {
    //             type: 'line',
    //             data: {
    //                 labels: ['Oct 06', 'Oct 07', 'Oct 08', 'Oct 09', 'Oct 10', 'Oct 11', 'Oct 12'],
    //                 datasets: [{
    //                     label: 'Applications',
    //                     data: [43, 81, 72, 85, 42, 65, 80],
    //                     borderWidth: 3,
    //                     borderColor: 'rgba(255, 168, 35, 1)',
    //                     pointBackgroundColor: 'rgba(255, 255, 255, 0)',
    //                     pointHoverBackgroundColor: 'rgba(255, 255, 255, 1)',
    //                     pointBorderColor: 'rgba(66, 133, 244, 0)',
    //                     pointHoverBorderColor: 'rgba(255, 168, 35, 1)',
    //                     pointBorderWidth: 10,
    //                     pointHoverBorderWidth: 3,
    //                     pointHitRadius: 20,
    //                     cubicInterpolationMode: 'monotone',
    //                     fill: true,
    //                     backgroundColor: gradient
    //                 }]
    //             },
    //             options: {
    //                 scales: {
    //                     xAxes: [{
    //                         ticks: {
    //                             fontColor: 'rgba(153, 153, 153, 1)',
    //                             maxTicksLimit: 7,
    //                             maxRotation: 0
    //                         },
    //                         gridLines: {
    //                             zeroLineColor: 'rgba(232, 232, 232, 1)',
    //                             drawOnChartArea: false,
    //                         },
    //                     }],
    //                     yAxes: [{
    //                         ticks: {
    //                             beginAtZero: true,
    //                             fontColor: 'rgba(153, 153, 153, 1)',
    //                             callback: function(value, index, values) {
    //                                 if (Math.floor(value) === value) {
    //                                     return value;
    //                                 }
    //                             }
    //                         },
    //                         gridLines: {
    //                             zeroLineColor: 'rgba(232, 232, 232, 0)',
    //                         },
    //                     }],
    //                 },
    //                 responsive: true,
    //                 tooltips: {
    //                     backgroundColor: 'rgba(0, 39, 69, 1)',
    //                     cornerRadius: 7,
    //                     mode: 'index',
    //                     intersect: false,
    //                     displayColors: false,
    //                     xPadding: 10,
    //                     yPadding: 10,
    //                     titleFontColor: 'rgba(255, 255, 255, .7)',
    //                     bodyFontColor: 'rgba(255, 255, 255, 1)',
    //                     titleFontStyle: 'normal',
    //                     bodyFontStyle: 'bold',
    //                 },
    //                 legend: {
    //                     display: false,
    //                 }
    //             }
    //         });
    //     }
    // }

    // Candidate dashboard charts
    // if(document.querySelector('#pxp-candidate-dashboard-visits-chart'))
    // {
    //     var candidateVisitsChartElem = document.getElementById('pxp-candidate-dashboard-visits-chart').getContext('2d');
    
    //         var gradient = candidateVisitsChartElem.createLinearGradient(0, 250, 0, 0);
    //         gradient.addColorStop(0, 'rgba(255, 255, 255, 0)');
    //         gradient.addColorStop(.5, 'rgba(0, 112, 201, 0.09)');
    //         gradient.addColorStop(1, 'rgba(0, 112, 201, 0.12)');
    
    //         var candidateVisitsChart = new Chart(candidateVisitsChartElem, {
    //             type: 'line',
    //             data: {
    //                 labels: ['Oct 06', 'Oct 07', 'Oct 08', 'Oct 09', 'Oct 10', 'Oct 11', 'Oct 12'],
    //                 datasets: [{
    //                     label: 'Visits',
    //                     data: [12, 56, 34, 78, 38, 28, 54],
    //                     borderWidth: 3,
    //                     borderColor: 'rgba(0, 112, 201, 1)',
    //                     pointBackgroundColor: 'rgba(255, 255, 255, 0)',
    //                     pointHoverBackgroundColor: 'rgba(255, 255, 255, 1)',
    //                     pointBorderColor: 'rgba(66, 133, 244, 0)',
    //                     pointHoverBorderColor: 'rgba(0, 112, 201, 1)',
    //                     pointBorderWidth: 10,
    //                     pointHoverBorderWidth: 3,
    //                     pointHitRadius: 20,
    //                     cubicInterpolationMode: 'monotone',
    //                     fill: true,
    //                     backgroundColor: gradient
    //                 }]
    //             },
    //             options: {
    //                 scales: {
    //                     xAxes: [{
    //                         ticks: {
    //                             fontColor: 'rgba(153, 153, 153, 1)',
    //                             maxTicksLimit: 7,
    //                             maxRotation: 0
    //                         },
    //                         gridLines: {
    //                             zeroLineColor: 'rgba(232, 232, 232, 1)',
    //                             drawOnChartArea: false,
    //                         },
    //                     }],
    //                     yAxes: [{
    //                         ticks: {
    //                             beginAtZero: true,
    //                             fontColor: 'rgba(153, 153, 153, 1)',
    //                             callback: function(value, index, values) {
    //                                 if (Math.floor(value) === value) {
    //                                     return value;
    //                                 }
    //                             }
    //                         },
    //                         gridLines: {
    //                             zeroLineColor: 'rgba(232, 232, 232, 0)',
    //                         },
    //                     }],
    //                 },
    //                 responsive: true,
    //                 tooltips: {
    //                     backgroundColor: 'rgba(0, 39, 69, 1)',
    //                     cornerRadius: 7,
    //                     mode: 'index',
    //                     intersect: false,
    //                     displayColors: false,
    //                     xPadding: 10,
    //                     yPadding: 10,
    //                     titleFontColor: 'rgba(255, 255, 255, .7)',
    //                     bodyFontColor: 'rgba(255, 255, 255, 1)',
    //                     titleFontStyle: 'normal',
    //                     bodyFontStyle: 'bold',
    //                 },
    //                 legend: {
    //                     display: false,
    //                 }
    //             }
    //         });
    // }


    // if(document.querySelector('#pxp-candidate-dashboard-app-chart'))
    // {
    //     if (document.querySelector('#pxp-candidate-dashboard-app-chart').length > 0) {
    //         var candidateAppChartElem = document.getElementById('pxp-candidate-dashboard-app-chart').getContext('2d');
    
    //         var gradient = candidateVisitsChartElem.createLinearGradient(0, 250, 0, 0);
    //         gradient.addColorStop(0, 'rgba(255, 255, 255, 0)');
    //         gradient.addColorStop(.5, 'rgba(255, 168, 35, 0.09)');
    //         gradient.addColorStop(1, 'rgba(255, 168, 35, 0.12)');
    
    //         var candidateAppChart = new Chart(candidateAppChartElem, {
    //             type: 'line',
    //             data: {
    //                 labels: ['Oct 06', 'Oct 07', 'Oct 08', 'Oct 09', 'Oct 10', 'Oct 11', 'Oct 12'],
    //                 datasets: [{
    //                     label: 'Applications',
    //                     data: [43, 81, 72, 85, 42, 65, 80],
    //                     borderWidth: 3,
    //                     borderColor: 'rgba(255, 168, 35, 1)',
    //                     pointBackgroundColor: 'rgba(255, 255, 255, 0)',
    //                     pointHoverBackgroundColor: 'rgba(255, 255, 255, 1)',
    //                     pointBorderColor: 'rgba(66, 133, 244, 0)',
    //                     pointHoverBorderColor: 'rgba(255, 168, 35, 1)',
    //                     pointBorderWidth: 10,
    //                     pointHoverBorderWidth: 3,
    //                     pointHitRadius: 20,
    //                     cubicInterpolationMode: 'monotone',
    //                     fill: true,
    //                     backgroundColor: gradient
    //                 }]
    //             },
    //             options: {
    //                 scales: {
    //                     xAxes: [{
    //                         ticks: {
    //                             fontColor: 'rgba(153, 153, 153, 1)',
    //                             maxTicksLimit: 7,
    //                             maxRotation: 0
    //                         },
    //                         gridLines: {
    //                             zeroLineColor: 'rgba(232, 232, 232, 1)',
    //                             drawOnChartArea: false,
    //                         },
    //                     }],
    //                     yAxes: [{
    //                         ticks: {
    //                             beginAtZero: true,
    //                             fontColor: 'rgba(153, 153, 153, 1)',
    //                             callback: function(value, index, values) {
    //                                 if (Math.floor(value) === value) {
    //                                     return value;
    //                                 }
    //                             }
    //                         },
    //                         gridLines: {
    //                             zeroLineColor: 'rgba(232, 232, 232, 0)',
    //                         },
    //                     }],
    //                 },
    //                 responsive: true,
    //                 tooltips: {
    //                     backgroundColor: 'rgba(0, 39, 69, 1)',
    //                     cornerRadius: 7,
    //                     mode: 'index',
    //                     intersect: false,
    //                     displayColors: false,
    //                     xPadding: 10,
    //                     yPadding: 10,
    //                     titleFontColor: 'rgba(255, 255, 255, .7)',
    //                     bodyFontColor: 'rgba(255, 255, 255, 1)',
    //                     titleFontStyle: 'normal',
    //                     bodyFontStyle: 'bold',
    //                 },
    //                 legend: {
    //                     display: false,
    //                 }
    //             }
    //         });
    //     }
    // }

    // Upload company cover photo and logo
    if(document.querySelector('#pxp-company-cover-choose-file'))
    {
        const chooseCoverFile = document.getElementById('pxp-company-cover-choose-file') as any;
    
            chooseCoverFile.addEventListener('change', function() {
                const files = chooseCoverFile.files[0];
    
                if (files) {
                    const fileReader = new FileReader();
    
                    fileReader.readAsDataURL(files);
                    fileReader.addEventListener("load", function() {
                        (document.querySelector('#pxp-company-cover-choose-file') as any).next('label').css({
                            'background-image': 'url(' + this.result + ')',
                            'border': '0 none'
                        }).querySelector('span').hide();
                    });
                }
            });
    }


    if(document.querySelector('#pxp-company-logo-choose-file'))
    {
        const chooseLogoFile = document.getElementById('pxp-company-logo-choose-file') as any;
    
            chooseLogoFile.addEventListener('change', function() {
                const files = chooseLogoFile.files[0];
    
                if (files) {
                    const fileReader = new FileReader();
    
                    fileReader.readAsDataURL(files);
                    fileReader.addEventListener("load", function() {
                        (document.querySelector('#pxp-company-logo-choose-file') as any).next('label').css({
                            'background-image': 'url(' + this.result + ')',
                            'border': '0 none'
                        }).querySelector('span').hide();
                    });
                }
            });
    }

    // Upload candidate cover photo and logo
    if(document.querySelector('#pxp-candidate-cover-choose-file'))
    {
        const chooseCandidateCoverFile = document.getElementById('pxp-candidate-cover-choose-file') as any;
    
            chooseCandidateCoverFile.addEventListener('change', function() {
                const files = chooseCandidateCoverFile.files[0];
    
                if (files) {
                    const fileReader = new FileReader();
    
                    fileReader.readAsDataURL(files);
                    fileReader.addEventListener("load", function() {
                        (document.querySelector('#pxp-candidate-cover-choose-file') as any).next('label').css({
                            'background-image': 'url(' + this.result + ')',
                            'border': '0 none'
                        }).querySelector('span').hide();
                    });
                }
            });
    }
    if(document.querySelector('#pxp-candidate-photo-choose-file'))
    {
        const chooseCandidatePhotoFile = document.getElementById('pxp-candidate-photo-choose-file') as any;
    
            chooseCandidatePhotoFile.addEventListener('change', function() {
                const files = chooseCandidatePhotoFile.files[0];
    
                if (files) {
                    const fileReader = new FileReader();
    
                    fileReader.readAsDataURL(files);
                    fileReader.addEventListener("load", function() {
                        (document.querySelector('#pxp-candidate-photo-choose-file') as any).next('label').css({
                            'background-image': 'url(' + this.result + ')',
                            'border': '0 none'
                        }).querySelector('span').hide();
                    });
                }
            });
    }

    // Toogle side filter on mobile
    // if(document.querySelector('.pxp-list-side-filter-header a'))
    // {
    //     document.querySelector('.pxp-list-side-filter-header a')?.addEventListener('click', function() {
    //         document.querySelector('.pxp-list-side-filter-header a')?.parentElement?.parentElement?.querySelector('.pxp-list-side-filter-panel')?.slideToggle();
    //     });
    // }

    // Toggle job details panel on mobile
    // if(document.querySelector('.pxp-jobs-card-4'))
    // {
    //     document.querySelector('.pxp-jobs-card-4').addEventListener('click', function() {
    //         document.querySelector('.pxp-jobs-tab-content').classList.add('pxp-show');
    //     });
    // }


    // if(document.querySelector('.pxp-jobs-tab-pane-close-btn'))
    // {
    //     document.querySelector('.pxp-jobs-tab-pane-close-btn').addEventListener('click', function() {
    //         (document.querySelector('.pxp-jobs-tab-content') as any).removeClass('pxp-show');
    //     });
    // }

    // Toggle messages panel on mobile
    // if(document.querySelector('.pxp-dashboard-inbox-list-item'))
    // {
    //     document.querySelector('.pxp-dashboard-inbox-list-item').addEventListener('click', function() {
    //         document.querySelector('.pxp-dashboard-inbox-messages-container').classList.add('pxp-show');
    //     });
    // }
    // if(document.querySelector('.pxp-dashboard-inbox-messages-close-btn'))
    // {
    //     document.querySelector('.pxp-dashboard-inbox-messages-close-btn').addEventListener('click', function() {
    //         (document.querySelector('.pxp-dashboard-inbox-messages-container') as any).removeClass('pxp-show');
    //     });
    // }


}
import { IonCol, IonGrid, IonRow, isPlatform } from "@ionic/react";
import { Button, Input, localePl, Popup, Select, setOptions } from "@mobiscroll/react";
import { AnyKindOfDictionary } from "lodash";
import React, {
  BaseSyntheticEvent,
  ChangeEvent,
  forwardRef,
  ReactNode,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { ButtonLoader, OnKeyDownHours, Spinner } from "../Common";

setOptions({
  theme: "ios",
  themeVariant: "light",
  locale: localePl,
});

type Props = {
  children?: ReactNode;
  label?: string;
  placeholder?: string;
  type?: string;
  autoComplete?: string;
  id?: string;
  responsive?: {[key: string]: any};
  startIcon?: string;
  startIconStyle?: React.CSSProperties;
  startIconSvg?: string;
  loading?: boolean;
  onKeyDown?: (event: KeyboardEvent) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: KeyboardEvent) => void;
  onKeyUp?: (event: KeyboardEvent) => void;
  onClick?: () => void;
  error?: boolean;
  errorMessage?: string;
  className?: string;
  maxLength?: string;
  step?: string;
  value?: any;
  responsiveDefault?: boolean;
  disabled?: boolean;
  height?: number;
  fontSize?: number;
  focus?: boolean;
  unFocus?: boolean;
  theme?: "primary" | "secondary" | "light" | "info" | "success";
  dim?: boolean;
  fontWeight?: "light" | "bold";
  style?: React.CSSProperties;
}

// const _responsive = {
//   xsmall: {
//     className: "input-primary",
//   },
//   medium: {
//     className: "input-bold",
//   },
// }


const MobiButton: React.FC<Props> = ({
  children,
  placeholder,
  label,
  type,
  autoComplete,
  id,
  responsive,
  startIcon,
  startIconStyle,
  startIconSvg,
  loading,
  onKeyDown,
  onChange,
  onKeyPress,
  onKeyUp,
  onClick,
  error,
  errorMessage,
  className,
  maxLength,
  step,
  value,
  disabled,
  height,
  fontSize,
  focus,
  unFocus,
  style,
  theme = "primary",
  responsiveDefault = true,
  dim = false,
  fontWeight = "light"
}) => {
  return (
    <div className="form-group" style={{ margin: "0.3em" }}>
      <Button
        type="button"
        theme="none"
        className={
          "btn btn-lg " +
          (dim ? "btn-dim" : "") +
          " btn-" +
          theme +
          " btn-block " +
          (loading ? "btn-loading" : "") +
          (className ? " " + className : "")
        }
        style={{
          ...style,
          border: "none",
          height: height ? height + "px" : "",
          fontSize: fontSize ? fontSize + "px" : "",
          opacity: unFocus ? "0.5" : "",
          fontWeight: fontWeight == "light" ? "400" : "800",
        }}
        disabled={loading}
        onClick={onClick}
        ripple={true}
      >
        {startIcon ? (
          <i className={startIcon} style={startIconStyle} />
        ) : (
          <></>
        )}

        <ButtonLoader />
        {label ? <span>{label}</span> : <></>}
        {children}
      </Button>
    </div>
  );
};

export default MobiButton;

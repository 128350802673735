import { createAnimation } from "@ionic/react";

const easings = [
  "cubic-bezier(0.190, 1.000, 0.220, 1.000)", // szybkie przejscie jak karta produktów z boku
  "cubic-bezier(0.190, 1.000, 0.190, 1.000)" // szybkie przejscie jak karta produktów z boku - taki sam 
]

export const mobileAnimation = (baseEl: any, opts: any) => {

  const enteringEl = opts.enteringEl as HTMLDivElement | undefined;
  const leavingEl = opts.leavingEl as HTMLDivElement | undefined;

  console.log(enteringEl?.querySelector(".slide-transition"));
  console.log(leavingEl?.querySelector(".slide-transition"));

  if(enteringEl?.querySelector(".slide-transition") || leavingEl?.querySelector(".slide-transition"))
  {
    return translateAnimationANIMATEDSCALE(baseEl, opts);
  }

  return defaultMobileAnimation(baseEl, opts);

};

const defaultMobileAnimation = (baseEl: any, opts: any) => {

  if(opts.direction == "forward")
  {
    const enteringAnimation = createAnimation()
    .addElement(opts.enteringEl)
    .fromTo("transform", "translate3d(0, 40px, 0)", "translate3d(0, 0, 0)")
    .fromTo("opacity", 0, 1)
    .easing(easings[1])
    .duration(200);

    return createAnimation().addAnimation(enteringAnimation);
  }
  else
  {
    const leavingElement = createAnimation()
    .addElement(opts.leavingEl)
    .fromTo("transform", "translate3d(0, 0, 0)", "translate3d(0, 40px, 0)")
    .fromTo("opacity", 1, 0)
    .easing(easings[1])
    .duration(200);

    const enteringElement = createAnimation()
    .addElement(opts.enteringEl)
    .fromTo("opacity", 0, 1)
    .easing(easings[1])
    .duration(200);

    return createAnimation().addAnimation(leavingElement).addAnimation(enteringElement);
  }

};

export const translateAnimation = (baseEl: any, opts: any) => {

  if(opts.direction == "forward")
  {
    const enteringAnimation = createAnimation()
    .addElement(opts.enteringEl)
    .fromTo("transform", "translate3d(100%, 0, 0)", "translate3d(0, 0, 0)")
    .fromTo("opacity", 1, 1)
    .easing(easings[0])
    .duration(300);

    return createAnimation().addAnimation(enteringAnimation);
  }
  else
  {
    const leavingElement = createAnimation()
    .addElement(opts.leavingEl)
    .fromTo("transform", "translate3d(0, 0, 0)", "translate3d(100%, 0, 0)")
    .easing(easings[0])
    .duration(300);

    const enteringElement = createAnimation()
    .addElement(opts.enteringEl)
    .fromTo("opacity", 0, 1)
    .easing(easings[0])
    .duration(1);

    return createAnimation().addAnimation(leavingElement).addAnimation(enteringElement);
  }
};

export const translateAnimationANIMATEDSCALE = (baseEl: any, opts: any) => {

  if(opts.direction == "forward")
  {
    const enteringAnimation = createAnimation()
    .addElement(opts.enteringEl)
    .fromTo("transform", "translate3d(100%, 0, 0)", "translate3d(0, 0, 0)")
    .fromTo("opacity", 1, 1)
    .easing(easings[0])
    .delay(50)
    .duration(300);

    const leavingElement = createAnimation()
    .addElement(opts.leavingEl)
    .fromTo("transform", "translateY(0) scale(1)", "translateY(max(30px, var(--ion-safe-area-top))) scale(0.93)")
    .fromTo("borderRadius", "0px", "10px")
    .fromTo("transformOrigin", "center top", "center top")
    .easing(easings[1])
    .duration(300);

    return createAnimation().addAnimation(enteringAnimation).addAnimation(leavingElement);
  }
  else
  {
    const leavingElement = createAnimation()
    .addElement(opts.leavingEl)
    .fromTo("transform", "translate3d(0, 0, 0)", "translate3d(100%, 0, 0)")
    .easing(easings[0])
    .duration(300);

    const enteringElement = createAnimation()
    .addElement(opts.enteringEl)
    .fromTo("transform", "translateY(max(30px, var(--ion-safe-area-top))) scale(0.93)", "translateY(0) scale(1)")
    .from("borderRadius", "10px")
    .fromTo("borderRadius", "10px", "0px")
    .fromTo("opacity", "1", "1")
    .fromTo("transformOrigin", "center top", "center top")
    .easing(easings[1])
    .delay(150)
    .duration(300);

    return createAnimation().addAnimation(leavingElement).addAnimation(enteringElement);
  }
};

export const opacityAnimation = (baseEl: any, opts: any) => {
    const enteringAnimation = createAnimation()
      .addElement(opts.enteringEl)
      .fromTo('opacity', 0, 1)
      .easing("ease-in-out")
      .duration(100);
    
    const leavingAnimation = createAnimation()
      .addElement(opts.leavingEl)
      .fromTo('opacity', 1, 0)
      .easing("ease-in-out")
      .duration(100);
    
    const animation = createAnimation()
      .addAnimation(enteringAnimation)
      .addAnimation(leavingAnimation);
    
    return animation;
  };
import { IonCol, IonGrid, IonRow, isPlatform } from "@ionic/react";
import {
  Input,
  localePl,
  Popup,
  Select,
  setOptions,
  Textarea,
} from "@mobiscroll/react";
import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Spinner } from "../Common";

setOptions({
  theme: "ios",
  themeVariant: "light",
  locale: localePl,
});

type Props = {
  label?: string;
  placeholder?: string;
  type?: string;
  autoComplete?: string;
  id?: string;
  responsive?: { [key: string]: any };
  startIcon?: string;
  startIconSvg?: string;
  loading?: boolean;
  onKeyDown?: (event: KeyboardEvent) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: KeyboardEvent) => void;
  onKeyUp?: (event: KeyboardEvent) => void;
  error?: boolean;
  errorMessage?: string;
  className?: string;
  maxLength?: string;
  step?: string;
  value?: string;
  responsiveDefault?: boolean;
  disabled?: boolean;
  rows?: number;
};

// const _responsive = {
//   xsmall: {
//     className: "input-primary",
//     labelStyle: "stacked",
//   },
//   medium: {
//     className: "input-bold",
//   },
// };

const MobiTextarea: React.FC<Props> = ({
  placeholder,
  label,
  type,
  autoComplete,
  id,
  responsive,
  startIcon,
  startIconSvg,
  loading,
  onKeyDown,
  onChange,
  onKeyPress,
  onKeyUp,
  error,
  errorMessage,
  className,
  maxLength,
  step,
  value,
  disabled,
  rows,
  responsiveDefault = true,
}) => {
  return (
    <Textarea
      // startIcon={startIcon}
      // startIconSvg={startIconSvg}

      endIconSvg={Boolean(loading) ? Spinner : undefined}
      disabled={Boolean(loading) || disabled}
      placeholder={placeholder}
      labelStyle="floating"
      theme="ios"
      inputStyle="box"
      label={label}
      type={type}
      autoComplete={autoComplete}
      id={id}
      responsive={
        responsiveDefault
          ? Boolean(responsive)
            ? responsive
            : undefined
          : undefined
      }
      onKeyDown={onKeyDown}
      onChange={onChange}
      onKeyPress={onKeyPress}
      onKeyUp={onKeyUp}
      error={error}
      errorMessage={errorMessage}
      className={className}
      maxLength={maxLength}
      step={step}
      value={value}
      rows={rows}
            
    />
  );
};

export default MobiTextarea;

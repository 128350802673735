import { IonCol, IonGrid, IonRow, isPlatform } from "@ionic/react";
import {
  Input,
  localePl,
  MbscPopupButton,
  Popup,
  Select,
  setOptions,
  Textarea,
} from "@mobiscroll/react";
import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  RefObject,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Spinner } from "../Common";

import './MobiPopup.css';

setOptions({
  theme: "ios",
  themeVariant: "light",
  locale: localePl,
});

type Props = {
  children: ReactNode
  popupRef?: RefObject<Popup>,
  anchor?: HTMLElement | null,
  isOpen: boolean,
  onOpen?: () => void,
  onClose?: () => void,
  cssClass?: string,
  contentPadding?: boolean,
  showOverlay?: boolean,
  responsive?: { [key: string]: any; },
  scrollLock?: boolean,
  width?: number,
  buttons?: (string | MbscPopupButton)[],
  closeOnOverlayClick?: boolean,
  updateTop?: boolean,
  showArrow?: boolean
};

const MobiPopup: React.FC<Props> = ({
  children,
  popupRef,
  anchor,
  isOpen,
  onOpen,
  onClose,
  cssClass,
  contentPadding,
  showOverlay,
  responsive,
  scrollLock,
  width,
  buttons,
  closeOnOverlayClick,
  updateTop,
  showArrow
}) => {

  return (
    <Popup
      showArrow={showArrow}
      buttons={buttons}
      ref={popupRef}
      anchor={anchor == null ? undefined : anchor}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      cssClass={cssClass}
      scrollLock={scrollLock}
      contentPadding={contentPadding}
      showOverlay={showOverlay}
      width={width}
      responsive={responsive}
      closeOnOverlayClick={closeOnOverlayClick}

      
    >
      <div
        onClick={() => {
          if (updateTop) {
            if (popupRef?.current && anchor) {
              setTimeout(() => {
                popupRef?.current?._onResize()
              }, 50);
            }
          }
        }}
      >
        {children}
      </div>
    </Popup>
  );
};

export default MobiPopup;

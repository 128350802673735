import { IonButton, IonCol, IonGrid, IonRouterLink, IonRow, isPlatform, useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
import { Input, localePl, Popup, setOptions } from "@mobiscroll/react";
import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  RefObject,
  SetStateAction,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import "./CategorySearch.css";

import MobiInput from "../Mobi/MobiInput";
import IonicButtonCustom from "../Ebert/Button";
import MobiPopup from "../Mobi/MobiPopup";
import { useHistory } from "react-router";

import Categories from "../../data/categories.json";

import {NavContext} from '@ionic/react';

setOptions({
  theme: "ios",
  themeVariant: "light",
  locale: localePl,
});

export type CategoryProps = {
  id: string;
  name: string;
  nameShow?: string;
  icon: string;
  iconShow?: string;
};

export type CollectionProps = {
  id: string;
  name: string;
  nameShow?: string;
  icon: string;
  iconShow?: string;
  next?: CollectionProps[];
};

export type ForwardProps = {
  closeModal: () => void;
  setCategory: (category: CategoryProps) => void;
};

const CategorySearch: React.FC<any> = forwardRef((props, _ref) => {

  const {navigate} = useContext(NavContext);

  const popupRef = useRef<Popup>(null);

  useIonViewWillEnter(() => {
    if(popupRef.current)
    {
      if(popupRef.current._isOpen)
      {
        popupRef.current.close();
      }
    }
  });

  useIonViewWillLeave(() => {
    if(popupRef.current)
    {
      if(popupRef.current._isOpen)
      {
        popupRef.current.close();
      }
    }
  });

  useImperativeHandle(_ref, () => ({
    closeModal() {
      if (popupRef.current) {
        if (popupRef.current._isOpen) {
          popupRef.current.close();
        }
      }
    },
    setCategory(category: CategoryProps){
      setCategory(
        category
      )
    },
    getCategory(){
      return category;
    }
  }));

  const history = useHistory();

  const ref = useRef<HTMLAnchorElement>(null);


  const [collection, setCollection] = useState<CollectionProps[]>(
    Categories as CollectionProps[]
  );

  const [previous, setPrevious] = useState<CollectionProps[][]>([]);

  const [isPopupOpen, setOpen] = useState(false);
  const [popupWidth, setPopupWidth] = useState(400);

  const formFloatingRef = useRef<HTMLDivElement>(null);

  const [category, setCategory] = useState<CategoryProps>({
    id: "",
    name: isPlatform("mobile")
      ? "<strong>Kliknij</strong> aby wybrać kategorię"
      : "Wybierz kategorię",
    icon: "",
  });



  const openModal = () => {
    if (formFloatingRef.current) {
      setPopupWidth(formFloatingRef.current.clientWidth);
    }
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <form className="row gx-3 align-items-center" action="jobs-list-1.html">
        <a
          className="col-12 col-md"
          onClick={() => {
            openModal();
          }}
          ref={ref}
          style={{ display: "block" }}
        >
          <div className="">
            <div className="form-floating" ref={formFloatingRef}>
              <div style={{ pointerEvents: "none" }}>
                <MobiInput
                  startIcon={
                    category.iconShow ? category.iconShow : category.icon
                  }
                  labelStyle="floating"
                  className="main-dropdown-desktop"
                  required
                  placeholder="Wybierz kategorię"
                  inputClass="remove-shadow"
                  type="search"
                  autoComplete="off"
                  id={"post-code"}
                  value={category.nameShow ? category.nameShow : category.name}
                />
              </div>
            </div>
          </div>
          <div
            className={
              "main-dropdown-mobile " +
              (Boolean(category.id) ? "main-dropdown-mobile-animated" : "")
            }
          >
            <span
              dangerouslySetInnerHTML={
                category.nameShow
                  ? { __html: category.nameShow }
                  : { __html: category.name }
              }
            ></span>
            <i
              style={{
                display: "block",
                marginTop: "12px",
                fontSize: "30px",
                color: "#0969c3",
              }}
              className={category.iconShow ? category.iconShow : category.icon}
            ></i>
          </div>
        </a>
        <div className="col-12 col-md-auto">
          {/* <IonRouterLink
           routerLink={"/zlecenia/" + encodeURIComponent(category.name)}
            routerDirection={ props.replaceHistory ? "root" : "forward" }
            > */}
          <IonicButtonCustom
            height={67}
            borderRadius={20}
            className="find-jobs-button"
            onClick={() => {
              if (Boolean(category.id)) {
                if(category.id == "any")
                {
                  if(props.replaceHistory)
                  {
                    props.replaceHistory(category.name);
                    //navigate("/zlecenia/", "root", "pop");
                  }
                  else
                  {
                    // navigate("/zlecenia/", "forward");
                    history.push("/zlecenia", {
                      category: ""
                    });
                  }
                }
                else
                {
                  if(props.replaceHistory)
                  {
                    props.replaceHistory(category.name);
                    //navigate("/zlecenia/" + encodeURIComponent(category.name), "root", "pop");
                  }
                  else
                  {
                    // navigate("/zlecenia/" + encodeURIComponent(category.name), "forward");
                    history.push("/zlecenia", {
                      category: category.name
                    });
                  }
                }

                        if(props.scrollToView && isPlatform("mobile"))
                        {
                          const element = props.scrollToView as RefObject<HTMLDivElement>;

                          element.current?.scrollIntoView({ behavior: "smooth" });
                        }

              } else {
                openModal();
              }

            }}
            type="button"
            theme="primary"
            startIcon={Boolean(category.id) ? "fas fa-search" : "fas fa-sort"}
            startIconStyle={{
              marginRight: Boolean(category.id) ? "10px" : "12px",
              fontSize: Boolean(category.id) ? "" : "24px",
            }}

            // disabled={!Boolean(category.id)}
            // style={{
            //   background: Boolean(category.id)
            //     ? ""
            //     : "var(--pxpMainColorDark)",
            // }}
          >
            {Boolean(category.id)
              ? "Wyszukaj zleceń"
              : "Wybierz kategorię"}
          </IonicButtonCustom>
          {/* </IonRouterLink> */}
        </div>
      </form>

      <MobiPopup
        updateTop={props.updateTop}
        popupRef={popupRef}
        anchor={ref?.current}
        isOpen={isPopupOpen}
        onClose={() => closeModal()}
        cssClass="mbsc-no-padding"
        contentPadding={false}
        showOverlay={false}
        scrollLock={false}
        width={popupWidth}
        responsive={{
          xsmall: {
            cssClass: "mobile popup-dropdown-custom",
            display: "bottom",
            maxHeight: "calc( 95% - 55px )",
            showOverlay: true,
            buttons: [
              {
                icon: "material-close",
                // text: "Wyjdź",
                handler: function () {
                  closeModal();
                },
              },
            ],
          },
          medium: {
            cssClass: "desktop popup-dropdown-custom",
            display: "anchored",
            maxHeight: "800px",
          },
        }}
      >
        <div className="dropdown-menu show">
          {collection.map((e, i) => {
            return (
              <div className="dropdown-inner">
                <ul className="link-list">
                  <li>
                    <a
                      onClick={() => {
                        if (e.next) {
                          setPrevious([...previous, collection]);
                          setCollection([
                            {
                              name: "Kategoria wyżej",
                              icon: "fas fa-redo",
                              id: "up",
                            },
                            ...e.next,
                          ]);
                        } else if (!e.next && e.id != "up") {
                          closeModal();
                        }
                        if (e.id == "up" && previous) {
                          setCollection(previous[previous.length - 1]);
                          let _previous = previous;
                          _previous.pop();
                          setPrevious(_previous);
                        } else {
                          setCategory({
                            id: e.id,
                            name: e.name,
                            nameShow: e.nameShow,
                            icon: e.icon,
                            iconShow: e.iconShow,
                          });
                        }



                      }}
                    >
                      <em
                        style={{ fontWeight: i == 0 ? 700 : 500, color: "var(--ion-color-primary)" }}
                        className={e.icon}
                      />
                      <span
                        style={{
                          fontWeight: i == 0 ? 700 : 500,
                          paddingRight: "25px",
                        }}
                      >
                        {e.name}
                      </span>
                      {e.next ? (
                        <em
                          style={{
                            marginLeft: "auto",
                            fontWeight: 900,
                            fontSize: "17px",
                          }}
                          className={"fas fa-ellipsis-h"}
                        />
                      ) : e.id == "up" || e.id == "any" ? (
                        <></>
                      ) : (
                        <span style={{ marginLeft: "auto" }}>
                          {Math.floor(
                            Math.random() * (Math.floor(120) - Math.ceil(0))
                          ) + Math.ceil(0)}
                        </span>
                      )}
                    </a>
                  </li>
                </ul>
              </div>
            );
          })}
        </div>
      </MobiPopup>
    </>
  );
});

export default CategorySearch;

import {
  IonItem,
  IonLabel,
  IonInput,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonCol,
  IonRow,
  IonFooter,
  useIonViewWillEnter,
  useIonViewDidEnter,
  IonButtons,
  IonButton,
  IonIcon,
  IonBackButton,
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail
} from "@ionic/react";
import "./Tab1.css";

import {
  Datepicker,
  Button,
  Page,
  setOptions,
  localePl,
  Input,
  Popup,
  Select,
  Switch,
} from "@mobiscroll/react";
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
} from "react";

import Header from "../components/JobsterHeader";
import ContentBox from "../components/ContentBox";

import { useDebounce, useDebouncedCallback } from "use-debounce";

import { Main } from "../components/HomeScript";
import { isPlatform, ScrollDetail } from "@ionic/core";

import MobiInput from "../components/Mobi/MobiInput";
import { LocationModelAttributes } from "../components/Order/Location";
import {
  isValidPostCode,
  onScrollHeader,
  toggleHeader,
} from "../components/Common";
import MobiPopup from "../components/Mobi/MobiPopup";

import "./List.css";

import Categories from "../data/categories.json";

import JobPost from "../components/Home/JobPost";

import Footer from "../components/JobsterFooter";
import { useHistory, useLocation, useParams } from "react-router";

import CategorySearch from "../components/Home/CategorySearch";

import { ForwardProps } from "../components/Home/CategorySearch";
import MobiSelect from "../components/Mobi/MobiSelect";

import axios from "axios";
import { Player, Controls } from "@lottiefiles/react-lottie-player";

import loading from "../lottie/loading.json";
import { appsOutline, arrowBack, reorderFourOutline } from "ionicons/icons";
import { NavLink } from "react-router-dom";

import CustomHeaderFade from "../components/CustomHeaderFade"
import CustomHeaderCondense from "../components/CustomHeaderCondense"

setOptions({
  theme: "ios",
  themeVariant: "light",
});

const Home: React.FC<any> = (props) => {
  let _localePl = localePl;
  _localePl.setText = "Ustaw";

  const history = useHistory();

  type CategoryProps = {
    id: string;
    name: string;
    nameShow?: string;
    icon: string;
    iconShow?: string;
  };

  const [category, setCategory] = useState<CategoryProps>({
    id: "any",
    name: "Dowolna kategoria",
    icon: "fas fa-check",
  });

  type CollectionProps = {
    id: string;
    name: string;
    nameShow?: string;
    icon: string;
    iconShow?: string;
    next?: CollectionProps[];
  };

  const [collection, setCollection] = useState<CollectionProps[]>(
    Categories as CollectionProps[]
  );

  const categorySearchRef = useRef<ForwardProps>(null);

  const findInCollection = (name: string, _collection: CollectionProps[]) => {
    _collection.map((e) => {
      if (e.name == name) {
        setCategory({
          id: e.id,
          name: e.name,
          nameShow: e.nameShow,
          icon: e.icon,
          iconShow: e.iconShow,
        });
        setCollection(_collection);

        categorySearchRef.current?.setCategory({
          id: e.id,
          name: e.name,
          nameShow: e.nameShow,
          icon: e.icon,
          iconShow: e.iconShow,
        });
      }

      if (e.next) {
        findInCollection(name, e.next);
      }
    });
  };

  const ionContentRef = useRef<HTMLIonContentElement>(null);

  //useEffect(() => {
    // setTimeout(() => {
    //   ionContentRef.current?.scrollToPoint(0, 1);
    // }, 25);

    // Main();
    // if (!isPlatform("mobile")) {
    //   setTimeout(() => {
    //     onScrollHeader();
    //   }, 100);
    // }
  //});

  // useEffect(() => {
  //   const id = props.match.params.id;

  //   if (id) {
  //     findInCollection(decodeURIComponent(id), collection);
  //   } else {
  //     findInCollection("Dowolna kategoria", collection);
  //   }

  //   console.log(id);
  // }, []);

  type RandomDataProps = {
    short_sentence: string;
    very_long_sentence: string;
  };

  const [posts, setPosts] = useState<RandomDataProps[]>([]);

  const scrollViewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // if (ionContentRef.current) {
    //   toggleHeader(ionContentRef.current?.offsetTop);
    // }

    setPosts([]);

    axios
      .get<RandomDataProps[]>(
        "https://random-data-api.com/api/lorem_ipsum/random_lorem_ipsum?size=8"
      )
      .then((res) => {
        setPosts(res.data);
      });
  }, [category]);

  


  useIonViewWillEnter(() => {

    console.log(category)
    console.log(history)

    if (history.action == "PUSH") {
      ionContentRef.current?.scrollToPoint(0, 0);
      const state = history.location.state;

      if (state) {
        const stateParsed = state as any;
        if (stateParsed.category != null) {
          findInCollection(
            stateParsed.category,
            collection
          );
          return;
        }
      }

      findInCollection("Dowolna kategoria", collection);
    }
    // else
    // {
    //   const state = history.location.state;

    //   if (state) {
    //     const stateParsed = state as any;
    //     if (stateParsed.category != null) {
    //       if(stateParsed.category != category.name)
    //       {
    //         console.log("nazwa1: " + stateParsed.category);
    //         console.log("nazwa2: " + category.name);
    //         findInCollection(
    //           stateParsed.category,
    //           collection
    //         );
    //       }
    //     }
    //   }
    // }

  });


  return (
    <IonPage>
      
    <CustomHeaderFade title="Lista zleceń" />

      <IonContent
        fullscreen={true}
        ref={ionContentRef}
        className="background-lightgrey"
        scrollEvents={false}
        onIonScroll={(event: CustomEvent<ScrollDetail>) => {
          onScrollHeader(event);
          categorySearchRef.current?.closeModal();
        }}
      >
        {/* <div className="mg-65" /> */}

        {/* <IonRefresher slot="fixed" onIonRefresh={(event: CustomEvent<RefresherEventDetail>) => {
          console.log('Begin async operation');

          setTimeout(() => {
            console.log('Async operation has ended');
            event.detail.complete();
          }, 2000);
        }} >
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher> */}

        <CustomHeaderCondense title="Lista zleceń" />

        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <div>
                <section
                  className="pxp-page-header-simple"
                  style={{ backgroundColor: "var(--pxpMainColorLight)" }}
                >
                  <div className="pxp-container">
                    <h1 className="d-none d-md-block">Lista zleceń</h1>
                    <div className="d-none d-md-block pxp-hero-subtitle pxp-text-ligh">
                      Szukaj spośród <strong>12,800</strong> zleceń
                    </div>

                    <div className="pxp-hero-form pxp-hero-form-round pxp-bigger mt-3 mt-lg-4">
                      <CategorySearch
                        ref={categorySearchRef}
                        replaceHistory={(id: string) => {

                          if (id) {
                            findInCollection(id, collection);
                          } else {
                            findInCollection("Dowolna kategoria", collection);
                          }

                        }}
                        scrollToView={scrollViewRef}
                      />
                    </div>
                  </div>
                </section>

                <section className="section.jobs">
                  <div className="pxp-container">
                    <div className="pxp-jobs-list-top mt-3 mt-lg-4">
                      <div className="row justify-content-between align-items-center">
                        <div
                          className="col-auto mt-3 mt-md-0"
                          ref={scrollViewRef}
                          style={{ scrollMarginTop: "100px" }}
                        >
                          <h2>
                            8,536{" "}
                            <span className="pxp-text-light">
                              zleceń w kategorii{" "}
                            </span>
                            {category.name}
                          </h2>
                        </div>

                        <div className="col-auto mt-3 mt-md-0">
                          <MobiSelect
                            onChange={(e) => {
                              console.log(e)
                            }}
                            required={true}
                            label={isPlatform("mobile") ? undefined : "Sortowanie"}
                            data={["Najnowsze", "Najstarsze"]}
                            defaultValue="Najnowsze"
                            itemHeight={45}
                            responsive_xsmall={
                              {
                                touchUi: false,
                                scroll3d: false,
                                showOverlay: false
                              }
                            }
                          />
                        </div>

                        {/* <div className="col-auto">
                          <select className="form-select">
                            <option value={0} selected>
                              Most relevant
                            </option>
                            <option value={1}>Newest</option>
                            <option value={2}>Oldest</option>
                          </select>
                        </div> */}
                      </div>
                      <div className="row mt-4 mt-lg-5 justify-content-center align-items-center">
                        <div className="col-auto">
                          <nav
                            className="mt-3 mt-sm-0"
                            aria-label="Jobs list pagination"
                          >
                            <ul className="pagination pxp-pagination">
                              <li
                                className="page-item active"
                                aria-current="page"
                              >
                                <span className="page-link">1</span>
                              </li>
                              <li className="page-item">
                                <a className="page-link" href="#">
                                  2
                                </a>
                              </li>
                              <li className="page-item">
                                <a className="page-link" href="#">
                                  3
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ position: "relative", minHeight: "400px" }}
                    >
                      {/* <div className="pxp-jobs-card-3 pxp-has-border">
                        <div className="row align-items-center justify-content-between">
                          <div className="col-sm-3 col-md-2 col-xxl-auto">
                            <a
                              href="single-company-1.html"
                              className="pxp-jobs-card-3-company-logo"
                              style={{
                                backgroundImage:
                                  "url(images/company-logo-1.png)",
                              }}
                            />
                          </div>
                          <div className="col-sm-9 col-md-10 col-xxl-4">
                            <a
                              href="single-job-1.html"
                              className="pxp-jobs-card-3-title mt-3 mt-sm-0"
                            >
                              Senior Editor
                            </a>
                            <div className="pxp-jobs-card-3-details">
                              <a
                                href="jobs-list-1.html"
                                className="pxp-jobs-card-3-location"
                              >
                                <span className="fa fa-globe" />
                                San Francisco, CA
                              </a>
                              <div className="pxp-jobs-card-3-type">
                                Full-time
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-8 col-xxl-4 mt-3 mt-xxl-0">
                            <a
                              href="jobs-list-1.html"
                              className="pxp-jobs-card-3-category"
                            >
                              <div className="pxp-jobs-card-3-category-label">
                                Marketing &amp; Communication
                              </div>
                            </a>
                            <div className="pxp-jobs-card-3-date-company">
                              <span className="pxp-jobs-card-3-date pxp-text-light">
                                3 days ago by
                              </span>{" "}
                              <a href="#" className="pxp-jobs-card-3-company">
                                Artistre Studio
                              </a>
                            </div>
                          </div>
                          <div className="col-sm-4 col-xxl-auto mt-3 mt-xxl-0 pxp-text-right">
                            <a
                              href="single-job-1.html"
                              className="btn rounded-pill pxp-card-btn"
                            >
                              Apply
                            </a>
                          </div>
                        </div>
                      </div> */}

                      {posts.length > 0 ? (
                        posts.map((e, i) => {
                          return (
                            <JobPost
                              transitionDelay={
                                isPlatform("mobile") ? 0.15 : 0.15 * i
                              }
                              title={e.short_sentence}
                              description={e.very_long_sentence}
                            />
                          );
                        })
                      ) : (
                        <div className="loader-container-secondary">
                          <Player
                            speed={1.3}
                            autoplay
                            loop
                            src={loading}
                            style={{
                              width: "200px",
                              height: "200px",
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="row mt-4 mt-lg-5 justify-content-between align-items-center">
                      <div className="col-auto">
                        <nav
                          className="mt-3 mt-sm-0"
                          aria-label="Jobs list pagination"
                        >
                          <ul className="pagination pxp-pagination">
                            <li
                              className="page-item active"
                              aria-current="page"
                            >
                              <span className="page-link">1</span>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                2
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                3
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonFooter>
          <Footer />
        </IonFooter>
      </IonContent>
    </IonPage>
  );
};

export default Home;

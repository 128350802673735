import {
  IonAccordion,
  IonAccordionGroup,
  IonApp,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRippleEffect,
  IonRouterLink,
  useIonViewWillEnter,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { home, list, add, fastFoodOutline } from "ionicons/icons";
import { RefObject, useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import CustomHeaderFade from "./CustomHeaderFade";

import "./JobsterMenu.css";

interface AppPage {
  url: string;
  title: string;
  icon: string;
  exact: boolean;
}

const appPages: AppPage[] = [
  {
    title: "Strona główna",
    url: "/",
    icon: home,
    exact: true
  },
  {
    title: "Lista zleceń",
    url: "/zlecenia",
    icon: list,
    exact: true
  },
  {
    title: "Dodaj zlecenie",
    url: "/AddJob",
    icon: add,
    exact: true
  },
  {
    title: "Brokuł",
    url: "/Brokul",
    icon: fastFoodOutline,
    exact: true
  },
];

const Menu: React.FC = () => {
  const history = useHistory();

  const [url, setUrl] = useState("");


  return (
    <IonMenu
      style={{
        "--width": "235px",
      }}
      id="mainMenu"
      contentId="main"
      type="overlay"
      maxEdgeStart={0}

      onIonWillOpen={() => {
        setUrl(history.location.pathname);
      }}
      
    >

<CustomHeaderFade logo extended={false} >
      Menu
    </CustomHeaderFade>

      <IonContent fullscreen style={{ position: "relative" }} id="menu-content">



        <IonList
          lines="none"
          style={{
            padding: "0 8px",
            paddingTop: "15px",
          }}
        >
          {appPages.map((e, i) => {
            return (
              <IonRouterLink
                className={(e.url == url) ? "active" : ""}
                routerLink={(e.url == url) ? undefined : e.url}
                onClick={() => {
                  const el = document.getElementById("mainMenu") as any;
                  if (el) {
                    el.close();
                  }
                }}
              >
                <IonItem style={{ 
                  position: "relative",
                }}>
                  <IonLabel>{e.title}</IonLabel>
                  <IonIcon slot="start" icon={e.icon} />
                  <IonRippleEffect />
                </IonItem>
              </IonRouterLink>
            );
          })}
        </IonList>
        {/* <IonListHeader mode="ios">
          <IonLabel>List Header</IonLabel>
        </IonListHeader>
        <IonList
          lines="none"
          style={{
            padding: "0 15px",
          }}
        >
          {appPages.map((e, i) => {
            return (
              <IonRouterLink
                routerLink={e.url}
                onClick={() => {
                  const el = document.getElementById("mainMenu") as any;
                  if (el) {
                    el.close();
                  }
                }}
              >
                <IonItem style={{ position: "relative" }}>
                  <IonLabel>{e.title}</IonLabel>
                  <IonIcon slot="end" icon={e.icon} />
                  <IonRippleEffect />
                </IonItem>
              </IonRouterLink>
            );
          })}
        </IonList> */}
      </IonContent>
    </IonMenu>
  );
};

export default Menu;

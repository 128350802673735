import { IonSkeletonText, isPlatform } from '@ionic/react';
import { Popup } from '@mobiscroll/react';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { isData, ShowData } from '../Common';
import './User.css'

export type ListUsersModal = {
    hide: Boolean;
    item?: ListUserModal;
    loading?: Boolean;
};

export type ListUserModal = {
    name: string;
};

export const RandomNumber = (min: number, max: number) =>
{
    return Math.floor(Math.random() * (max - min)) + min;
}

const List: React.FC<ListUsersModal> = ({ item, hide, loading = false }) => {

    const location = useLocation();
    const history = useHistory();

    const modalButtonRef = useRef<HTMLDivElement>(null);


    const [isPopupOpen, setOpen] = useState(false);

    useEffect(() => {
        if (!location.search.includes('optionsModal=true')) {
            setOpen(false);
        }
    }, [location]);

    const openOptions = () => {
        if (isPlatform("mobile")) {
            history.push(history.location.pathname + '?optionsModal=true');
        }
        setOpen(true);
    }

    const closeOptions = () => {
        if (isPlatform("mobile")) {
            history.goBack();
        }
        setOpen(false);
    }



    return (
        <div className={"nk-tb-item custom-list " + (hide ? "hide-list-element" : "")}>
            <div className="nk-tb-col nk-tb-col-check">
                <div className="custom-control custom-control-sm custom-checkbox notext">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        id="uid1"
                    />
                    <label className="custom-control-label" htmlFor="uid1" />
                </div>
            </div>
            <div className="nk-tb-col">
                <a href="html/user-details-regular.html">
                    <div className="user-card">
                        <div className={"user-avatar bg-primary " + (loading ? "remove-background" : "")}>
                            {
                                loading
                                    ?
                                    <IonSkeletonText animated className="h-40" style={{ width: '40px' }} />
                                    :
                                    isData(item)
                                        ?
                                        <span>{"AB"}</span>
                                        :
                                        "..."
                            }

                        </div>
                        <div className="user-info">
                            <span className="tb-lead">
                                {
                                    loading
                                        ?
                                        <IonSkeletonText animated className="h-19" style={{ width: RandomNumber(70, 130) + "px" }} />
                                        :
                                        isData(item)
                                            ?
                                            ShowData(item?.name)
                                            :
                                            "..."
                                }
                                <span className="dot dot-success d-md-none ml-1" />
                            </span>
                            <span id="email-skeleton">
                            {
                                loading
                                    ?
                                    <IonSkeletonText animated className="h-14" style={{ width: RandomNumber(100, 120) + "px" }} />
                                    :
                                    isData(item)
                                        ?
                                        "mateusz@ebert.app"
                                        :
                                        "..."
                            }
                            </span>
                        </div>
                    </div>
                </a>
            </div>
            <div className="nk-tb-col tb-col-mb">
                <span className="tb-amount">
                    35,040.34 <span className="currency">USD</span>
                </span>
            </div>
            <div className="nk-tb-col tb-col-md">
                <span>+811 847-4958</span>
            </div>
            <div className="nk-tb-col tb-col-lg">
                <ul className="list-status">
                    <li>
                        <em className="icon text-success ni ni-check-circle" />{" "}
                        <span>Email</span>
                    </li>
                    <li>
                        <em className="icon ni ni-alert-circle" /> <span>KYC</span>
                    </li>
                </ul>
            </div>
            <div className="nk-tb-col tb-col-lg">
                <span>10 Feb 2020</span>
            </div>
            <div className="nk-tb-col tb-col-md">
                <span className="tb-status text-success">Active</span>
            </div>
            <div className="nk-tb-col nk-tb-col-tools">

                <ul className="nk-tb-actions gx-1">
                    <li>
                        <div className="drodown" ref={modalButtonRef}>
                            <a

                                onClick={() => {

                                    openOptions();

                                }}

                                className="dropdown-toggle btn btn-icon btn-trigger"
                                data-toggle="dropdown"
                            >
                                <em className="icon ni ni-more-h" />
                            </a>

                        </div>
                    </li>
                </ul>

                {/* <li className="nk-tb-action-hidden">
                    <a
                        href="#"
                        className="btn btn-trigger btn-icon"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Wallet"
                    >
                        <em className="icon ni ni-wallet-fill" />
                    </a>
                </li>
                <li className="nk-tb-action-hidden">
                    <a
                        href="#"
                        className="btn btn-trigger btn-icon"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Send Email"
                    >
                        <em className="icon ni ni-mail-fill" />
                    </a>
                </li>
                <li className="nk-tb-action-hidden">
                    <a
                        href="#"
                        className="btn btn-trigger btn-icon"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Suspend"
                    >
                        <em className="icon ni ni-user-cross-fill" />
                    </a>
                </li> */}
            </div>


            <Popup
                anchor={modalButtonRef.current as HTMLDivElement}
                isOpen={isPopupOpen}
                onClose={() => closeOptions()}
                cssClass="mbsc-no-padding modal-top-border"
                contentPadding={false}
                showOverlay={false}

                display="anchored"

                responsive={{
                    xsmall: {
                        display: "anchored",
                        showOverlay: true
                    },
                    medium: {
                        display: "anchored"
                    }
                }}

            // responsive={{
            //     xsmall: {
            //         display: "center"
            //     },
            //     medium: {
            //         width: "270px",
            //         display: "anchored"
            //     }
            // }}

            >
                <ul className="link-list-opt no-bdr">
                    <li>
                        <a href="#">
                            <em className="icon ni ni-focus" />
                            <span>Quick View</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <em className="icon ni ni-eye" />
                            <span>View Details</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <em className="icon ni ni-repeat" />
                            <span>Transaction</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <em className="icon ni ni-activity-round" />
                            <span>Activities</span>
                        </a>
                    </li>
                    <li className="divider" />
                    <li>
                        <a href="#">
                            <em className="icon ni ni-shield-star" />
                            <span>Reset Pass</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <em className="icon ni ni-shield-off" />
                            <span>Reset 2FA</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <em className="icon ni ni-na" />
                            <span>Suspend User</span>
                        </a>
                    </li>
                </ul>




            </Popup>


        </div>
    )
}


export default List;
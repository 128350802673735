import './Template.css';

import React, { useState, useCallback } from 'react';
import { setOptions } from '@mobiscroll/react';
import { IonCol, IonContent, IonGrid, IonHeader, IonPage, IonRow } from '@ionic/react';
import Header from '../components/Header';


setOptions({
  theme: 'ios',
  themeVariant: 'light'
});

const _Page: React.FC = () => {

  return (
    <IonPage>
      <IonHeader>
        <Header title="Start" />
      </IonHeader>
      <IonContent fullscreen className="background-lightgrey">

        <div className="mg-65"/>

        <IonGrid className="content-padding" >

          <IonRow>
            
          </IonRow>

        </IonGrid>



      </IonContent>



    </IonPage>
  );
};

export default _Page;

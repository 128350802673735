export const Roles = {

  Student: "Student",
  Lecturer: "Lecturer",
  Worker: "Worker",
  Owner: "Owner",
  PlatformWorker: "PlatformWorker",
  PlatformAdmin: "PlatformAdmin",

}

export const Permissions = {

  AddKursantSetOSK: "AddKursantSetOSK"

}
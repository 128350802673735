import {
  IonItem,
  IonLabel,
  IonInput,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonCol,
  IonRow,
  IonFooter,
  useIonViewWillEnter,
  useIonViewDidEnter,
  IonButtons,
  IonButton,
  IonIcon,
  IonBackButton,
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail
} from "@ionic/react";

import {
  Datepicker,
  Button,
  Page,
  setOptions,
  localePl,
  Input,
  Popup,
  Select,
  Switch,
} from "@mobiscroll/react";
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
} from "react";

import Header from "../components/JobsterHeader";
import ContentBox from "../components/ContentBox";

import { useDebounce, useDebouncedCallback } from "use-debounce";

import { Main } from "../components/HomeScript";
import { isPlatform, ScrollDetail } from "@ionic/core";

import MobiInput from "../components/Mobi/MobiInput";
import { LocationModelAttributes } from "../components/Order/Location";
import {
  isValidPostCode,
  onScrollHeader,
  toggleHeader,
} from "../components/Common";
import MobiPopup from "../components/Mobi/MobiPopup";

import "./Job.css";

import Categories from "../data/categories.json";

import JobPost from "../components/Home/JobPost";

import Footer from "../components/JobsterFooter";
import { useLocation, useParams } from "react-router";

import CategorySearch from "../components/Home/CategorySearch";

import { ForwardProps } from "../components/Home/CategorySearch";
import MobiSelect from "../components/Mobi/MobiSelect";

import axios from "axios";
import { Player, Controls } from "@lottiefiles/react-lottie-player";

import loading from "../lottie/loading.json";
import { appsOutline, arrowBack, reorderFourOutline } from "ionicons/icons";
import { NavLink } from "react-router-dom";

import CustomHeaderFade from "../components/CustomHeaderFade"
import CustomHeaderCondense from "../components/CustomHeaderCondense"

setOptions({
  theme: "ios",
  themeVariant: "light",
});

const Home: React.FC<any> = (props) => {
  let _localePl = localePl;
  _localePl.setText = "Ustaw";

  type CategoryProps = {
    id: string;
    name: string;
    nameShow?: string;
    icon: string;
    iconShow?: string;
  };

  const categorySearchRef = useRef<ForwardProps>(null);

  const ionContentRef = useRef<HTMLIonContentElement>(null);

  useEffect(() => {
    Main();
    if (!isPlatform("mobile")) {
      setTimeout(() => {
        onScrollHeader();
      }, 100);
    }
  });


  const scrollViewRef = useRef<HTMLDivElement>(null);


  return (
    <IonPage>
      
    <CustomHeaderFade arrow long >
      <strong>
        Zlecenie:&nbsp;
      </strong>
      Stworzenie strony internetowej
    </CustomHeaderFade>

      <IonContent
        fullscreen={true}
        ref={ionContentRef}
        className="background-lightgrey slide-transition"
        scrollEvents={false}
        onIonScroll={(event: CustomEvent<ScrollDetail>) => {
          onScrollHeader(event);
          categorySearchRef.current?.closeModal();
        }}
      >
        {/* <div className="mg-65" /> */}

        {/* <IonRefresher slot="fixed" onIonRefresh={(event: CustomEvent<RefresherEventDetail>) => {
          console.log('Begin async operation');

          setTimeout(() => {
            console.log('Async operation has ended');
            event.detail.complete();
          }, 2000);
        }} >
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher> */}

        <CustomHeaderCondense title="Stworzenie strony internetowej" />

        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <div>
                <section className="section.jobs">
                  <div className="pxp-container">
                   


<div className="pxp-single-job-content mt-0 mt-lg-5">
  <div className="row justify-content-center" style={{}}>
    <div className="col-12 col-md-8 ">

      <div
      className="single-job-frame p-md-5"
      >
      <div className="row justify-content-between align-items-center">
        <div className="col-xl-8 col-xxl-6">
          <h1>Senior Editor</h1>
        </div>
      </div>
      <div className="row mt-2 justify-content-between align-items-center">
        <div className="col-6">
          <a href="jobs-list-1.html" className="pxp-single-job-category">
            <div className="pxp-single-job-category-icon"><span className="fa fa-bullhorn" /></div>
            <div className="pxp-single-job-category-label">Marketing &amp; Communication</div>
          </a>
        </div>
        <div className="col-auto">
          <div className="pxp-single-job-date pxp-text-light">4 days ago</div>
        </div>
      </div>
      <div className="pxp-single-job-content-details mt-4 mt-lg-5">
        <h4>Opis</h4>
        <p style={{
          fontSize: "16px"
        }} >As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent. You will help the team design beautiful interfaces that solve business challenges for our clients. We work with a number of Tier 1 banks on building web-based applications for AML, KYC and Sanctions List management workflows. This role is ideal if you are looking to segue your career into the FinTech or Big Data arenas.</p>
      </div>
      </div>

    </div>
    <div className="col-12 col-md-3">
      <div className="pxp-single-job-side-panel mt-5 mt-lg-0">
        <div>
          <div className="pxp-single-job-side-info-label pxp-text-light">Experience</div>
          <div className="pxp-single-job-side-info-data">Minimum 1 year</div>
        </div>
        <div className="mt-4">
          <div className="pxp-single-job-side-info-label pxp-text-light">Work Level</div>
          <div className="pxp-single-job-side-info-data">Senior Level</div>
        </div>
        <div className="mt-4">
          <div className="pxp-single-job-side-info-label pxp-text-light">Employment Type</div>
          <div className="pxp-single-job-side-info-data">Full Time</div>
        </div>
        <div className="mt-4">
          <div className="pxp-single-job-side-info-label pxp-text-light">Salary</div>
          <div className="pxp-single-job-side-info-data">$35k / year</div>
        </div>
      </div>
      
    </div>
  </div>
</div>



                    

                  </div>
                </section>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonFooter>
          <Footer />
        </IonFooter>
      </IonContent>
    </IonPage>
  );
};

export default Home;

import { isPlatform } from '@ionic/core';
import { ScrollDetail } from '@ionic/react';
import { HubConnection } from '@microsoft/signalr';
import { Dispatch, RefObject, SetStateAction } from 'react';
import './Common.css'

export const ButtonLoader: React.FC = () => {
    
    return(

      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

    )

};

export function GenerateGUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function isValidPostCode(PostCode: string)
{

  const code = PostCode.replace(" - ", "");

  if(code.length == 5)
  {
    return true;
  }
  else
  {
    return false;
  }

}

export function isValidPhone(phone: string)
{

  const number = phone.replaceAll(" ", "");

  if(number.length == 9)
  {
    return true;
  }
  else
  {
    return false;
  }

}

export function isValidPesel(pesel: string) {
  console.log('pesel')
  console.log(pesel)
  let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
  let sum = 0;
  let controlNumber = parseInt(pesel.substring(10, 11));

  for (let i = 0; i < weight.length; i++) {
      sum += (parseInt(pesel.substring(i, i + 1)) * weight[i]);
  }
  sum = sum % 10;
  return (10 - sum) % 10 === controlNumber;
}

export function isData(data: any) {
  if (data != undefined) {
      return true;
  }
  else {
      return false;
  }
}

export function ShowData(data: any) {
  console.log("data: " + data)
  if (data != undefined) {
      return data;
  }
  else {
      return "";
  }
}

export const Spinner = '<div style="width: 39px; height: 45px; top: -12px; right: -19px; position: absolute;"><?xml version="1.0" encoding="utf-8"?>'+
'<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">'+
'<circle cx="50" cy="50" fill="none" stroke="#6576ff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">'+
'  <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.819672131147541s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>'+
'</circle>'+
'<!-- [ldio] generated by https://loading.io/ --></svg></div>';



export const OnKeyDownCurrency = (event: KeyboardEvent, setValue: Dispatch<SetStateAction<string>>) => {
  if (event.target != null) {
    const element =
      event.target as HTMLInputElement;

      element.value = element.value.replace(" zł", "").replace(",00", "");

      element.value = Number(element.value).toString()

    if (event.key == "Backspace") {
      element.value = element.value.slice(
        0,
        element.value.length - 1
      );
    }
    else if (element.value.length < 11) {
      if (
        parseInt(event.key) >= 0 &&
        parseInt(event.key) <= 9
      ) {
        element.value = element.value + event.key;
      }
    }

    if(Number(element.value) == 0)
    {
      element.value = "0,00 zł";
    }
    else
    {
      //element.value = ((Math.round(Number(element.value) * 100) / 100) / 100).toFixed(0).replace(".", ",") + " zł";
      element.value = Number(element.value) + ",00 zł"
    }

    setValue(element.value);

  }

  event.preventDefault();
  event.stopPropagation();
}

export const OnKeyDownCurrencyExact = (event: KeyboardEvent, setValue: Dispatch<SetStateAction<string>>) => {
  if (event.target != null) {
    const element =
      event.target as HTMLInputElement;

      element.value = element.value.replace(" zł", "").replace(",", "");

      element.value = Number(element.value).toString()

    if (event.key == "Backspace") {
      element.value = element.value.slice(0, element.value.length - 1);
    } else if (element.value.length < 11) {
      if (parseInt(event.key) >= 0 && parseInt(event.key) <= 9) {
        element.value = element.value + event.key;
      }
    }

    console.log(Number(element.value).toString())

    if (Number(element.value).toString().length == 1) {
      element.value = "0,0" + Number(element.value) + " zł";
    } else if (Number(element.value).toString().length == 2) {
      element.value = "0," + Number(element.value) + " zł";
    } else {
      let firstNumber = element.value.substring(0, element.value.length - 2);
      let secondNumber = element.value.substring(
        element.value.length - 2,
        element.value.length
      );

      element.value = firstNumber + "," + secondNumber + " zł";
    }

    setValue(element.value);

  }

  event.preventDefault();
  event.stopPropagation();
}

export const OnKeyDownHours = (event: KeyboardEvent, setValue: Dispatch<SetStateAction<string>>) => {
  if (event.target != null) {
    const element =
      event.target as HTMLInputElement;

      element.value = element.value.replace(" h", "");

      element.value = Number(element.value).toString()

    if (event.key == "Backspace") {
      element.value = element.value.slice(
        0,
        element.value.length - 1
      );
    }
    else if (element.value.length < 11) {
      if (
        parseInt(event.key) >= 0 &&
        parseInt(event.key) <= 9
      ) {
        element.value = element.value + event.key;
      }
    }

    if(Number(element.value) == 0)
    {
      element.value = "0 h";
    }
    else
    {
      element.value = ((Math.round(Number(element.value) * 100) / 100)).toFixed(0) + " h";
    }

    setValue(element.value);

  }

  event.preventDefault();
  event.stopPropagation();
}


export class SignalR {
  
  public static isConnected = (connection: HubConnection) => {
    if(connection.state == "Connected")
    {
      return true;
    }
    else
    {
      return false;
    }
  }

}

export const titleCase = (str: string) => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
  }
  // Directly return the joined string
  return splitStr.join(' '); 
}


export const onScrollHeader = (event?: CustomEvent<ScrollDetail>, ...refs: RefObject<any>[]) => {
  
 

  if(refs)
  {
    if(refs.length > 0)
    {
      refs.map((e) => {
        if(e.current)
        {
          if (e.current._isOpen) {
            e.current.close();
          }
        }
      })
    }
  }

  const elems = document.querySelectorAll(".pxp-animate-in:not(.pxp-in)");

    if (elems.length > 0) {

      const windowHeight = window.innerHeight;

      for (var i = 0; i < elems.length; i++) {
        var positionFromTop = elems[i].getBoundingClientRect().top;

        if (
          positionFromTop -
            windowHeight +
            (isPlatform("mobile") ? 30 : elems[i].clientHeight / 4) <=
          0 || !event
        ) {
          elems[i].classList.add("pxp-in");
          if (elems[i]) {
            if (elems[i].classList.contains("pxp-info-stats-item")) {
              if (elems[i].classList.contains("pxp-animate-bounce")) {
                setTimeout(function () {
                  elems[i].classList.add("animate__animated");
                  elems[i].classList.add("animate__bounceIn");
                }, 500);
              }
            }
          }
          if (elems[i]) {
            if (
              elems[i].classList.contains("pxp-testimonials-1-circles-item")
            ) {
              if (elems[i].classList.contains("pxp-animate-bounce")) {
                setTimeout(function () {
                  elems[i].classList.add("animate__animated");
                  elems[i].classList.add("animate__bounceIn");
                }, 200);
              }
            }
          }
        }
      }
    }

    // if(event)
    // {
    //   toggleHeader(event?.detail.scrollTop, event.detail.deltaY);
    // }
    

}

let lastScroll = 0;

export const toggleHeader = (offset: number, delta = -1 ) => {

  const el = document.querySelector(".pxp-header");

  if(delta >= 0)
  {
    delta = offset - lastScroll ;
  }

  

  console.log("offset: " + offset);
  console.log("lastScroll: " + lastScroll);

  lastScroll = offset;

  if (
    el?.classList.contains("pxp-bigger") ||
    el?.classList.contains("pxp-no-bg")
  ) {
    if (offset > 300 && delta > 0) {
      el?.classList.add("pxp-is-hidden");
    }
    else if (offset > 20) {
      el?.classList.add("pxp-is-sticky");
      el?.classList.remove("pxp-is-hidden");
    } else {
      el?.classList.remove("pxp-is-sticky");
      el?.classList.remove("pxp-is-hidden");
    }
  } else if (el?.classList.contains("pxp-no-bg")) {
    if (offset > 0) {
      el?.classList.add("pxp-is-sticky");
    } else {
      el?.classList.remove("pxp-is-sticky");
    }
  } else {
    if (offset > 93) {
      el?.classList.add("pxp-is-hidden");
    } else {
      el?.classList.remove("pxp-is-hidden");
    }
  }

}



// export function SetCaretPosition(e: HTMLInputElement, sep: string){
//   var pos = e.value.indexOf(sep);
//   e.selectionStart = pos;
//   e.selectionEnd = pos;
// }


// export function CustomKeyDown(e: HTMLInputElement, event: KeyboardEvent, length: number, sep = "", tab: string[] = []){

//   if (((event.key == "v" || event.key == "V") && event.ctrlKey)) {
//     return;
// }

// if (event.key != "Tab") {
//     event.preventDefault();
// }

// if (
//     (parseInt(event.key) >= 0 && parseInt(event.key) <= 9) ||
//     event.key == "Backspace") {

//     var arr = e.value.replace(/\D/g, '').split("");

//     if (event.key == "Backspace") {
//         arr.pop();
//     }
//     else {
//         arr.push(event.key);
//     }

//     e.value = "";

//     var arr_length = arr.length;
//     if (arr_length > length) {
//         arr_length = length;
//     }

//     for (var i: number = 0; i < arr_length; i++) {
//         e.value += arr[i];
//         if (tab.includes(i.toString())) {
//             e.value += sep;
//         }
//     }

//     for (var i: number = arr_length; i < length; i++) {
//         e.value += "_";
//         if (tab.includes(i.toString())) {
//             e.value += sep;
//         }
//     }
// }

// if(e.value.length > e.value.replace(/\D/g, '').length)
// {
//   SetCaretPosition(e, "_");
// }


// }

// export function BlurPESEL(){
//   const elem = document.querySelector("#student-pesel");

//   if(elem != null)
//   {
//     (elem as HTMLInputElement).blur();
//   }

// }

// export function ClearPESELValue(){

//   const elem = document.querySelector("#student-pesel");

//   if(elem != null)
//   {

//     (elem as HTMLInputElement).value = "___________";

//   }
  
// }

// export function GetPESELValue(){

//   const elem = document.querySelector("#student-pesel");

//   if(elem != null)
//   {
//     return (elem as HTMLInputElement).value.replace(/\D/g, '');
//   }
//   else
//   {
//     return null;
//   }
  
// }
import {
  IonItem,
  IonLabel,
  IonInput,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonCol,
  IonRow,
  IonFooter,
  useIonViewWillEnter,
  useIonViewDidEnter,
  useIonViewWillLeave,
  IonReorderGroup,
  IonReorder,
  IonButtons,
  IonButton,
  IonIcon,
  IonRippleEffect,
  IonRouterLink,
} from "@ionic/react";

import {
  Datepicker,
  Page,
  setOptions,
  localePl,
  Input,
  Popup,
  Select,
  Switch,
} from "@mobiscroll/react";
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
} from "react";

import Header from "../components/JobsterHeader";
import ContentBox from "../components/ContentBox";

import { useDebounce, useDebouncedCallback } from "use-debounce";

import { Main } from "../components/HomeScript";
import { isPlatform, ScrollDetail } from "@ionic/core";

import MobiInput from "../components/Mobi/MobiInput";
import { LocationModelAttributes } from "../components/Order/Location";
import { isValidPostCode, onScrollHeader } from "../components/Common";
import MobiPopup from "../components/Mobi/MobiPopup";

import JobPost from "../components/Home/JobPost";

import Footer from "../components/JobsterFooter";
import { useHistory } from "react-router";
import MobiButton from "../components/Mobi/MobiButton";

import { Link } from "react-router-dom";
import MobiTextarea from "../components/Mobi/MobiTextarea";
import CustomHeaderFade from "../components/CustomHeaderFade";
import CustomHeaderCondense from "../components/CustomHeaderCondense";

import CategorySearchInput from "../components/Home/CategorySearchInput";
import {
  imageOutline,
  layersOutline,
  navigateOutline,
  toggle,
} from "ionicons/icons";

import Button from "./../components/Ebert/Button";

setOptions({
  theme: "ios",
  themeVariant: "light",
});

const Home: React.FC = () => {
  let _localePl = localePl;
  _localePl.setText = "Ustaw";

  const headerRef = useRef<HTMLIonHeaderElement>(null);

  const [headerScrollTop, setHeaderScrollTop] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (headerRef.current) {
      headerRef.current.style.willChange = "transform";
      headerRef.current.style.transition = "transform ease-in-out 150ms";
    }
  }, []);

  useIonViewWillLeave(() => {
    if (headerRef.current) {
      headerRef.current.style.transform = "translate3d(0, 0, 0)";
    }
    setHeaderTop(0);
  });

  const [items, setItems] = useState([
    "Kartuska 12",
    "al. Armi Krajowych 52",
    "Biskupia 61",
    "Partyzantów 74",
    "Nowe Ogrody 8/12",
    "Długi Targ 39/40",
    "Wilanowska 2",
    "Tytusa Chałubińskiego 27",
    "Rajska 6",
    "Karmelicka 1",
    "aleja Grunwaldzka 100",
    "Ignacego Paderewskiego 9",
    "Łąkowa 35",
  ]);

  return (
    <IonPage>
      <CustomHeaderFade
        headerRef={headerRef}
        title="Testowa aplikacja"
        extraButtons={
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                setDisabled(!disabled);
              }}
            >
              <IonIcon slot="icon-only" icon={layersOutline} />
            </IonButton>
          </IonButtons>
        }
      />

      <IonContent
        fullscreen={true}
        className="background-lightgrey"
        scrollEvents={true}
        onIonScroll={(event: CustomEvent<ScrollDetail>) => {
          // if (headerRef.current) {
          //   const result = headerScrollTop - event.detail.scrollTop;
          //   if(event.detail.scrollTop > 57)
          //   {
          //     if(result < 0)
          //     {
          //       headerRef.current.style.transform = "translate3d(0, -57px, 0)";
          //     }
          //     else
          //     {
          //       headerRef.current.style.transform = "translate3d(0, 0, 0)";
          //     }
          //   }
          // }
          // setHeaderScrollTop(event.detail.scrollTop);
        }}
      >
        <CustomHeaderCondense title="Testowa aplikacja" />

        <IonReorderGroup
          disabled={disabled}
          onIonItemReorder={(event) => {

            //setItems(items.splice(event.detail.to, 0, items.splice(event.detail.from, 1)[0]))

            setItems(event.detail.complete(items));

          }}
        >
          {items.map((e, i) => {
            return (
              <IonItem
                style={{
                  position: "relative",
                }}
              >
                <IonLabel>{e}</IonLabel>
                {
                  i == 0
                  ?
                  <IonIcon slot="end" icon={navigateOutline} onClick={() => {

                    window.open("https://www.google.com/maps/dir/?api=1&destination=" + encodeURIComponent("Gdańsk " + e), '_blank');
  
                  }} />
                  :
                  <></>
                }
                {/* <IonIcon slot="end" icon={imageOutline} /> */}
                <IonRippleEffect />
                <IonReorder slot="end" />
              </IonItem>
            );
          })}
        </IonReorderGroup>
      </IonContent>
    </IonPage>
  );
};

export default Home;

import {
  IonItem,
  IonLabel,
  IonInput,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonCol,
  IonRow,
  IonFooter,
  useIonViewWillEnter,
  useIonViewDidEnter,
  IonRouterLink,
  IonRippleEffect,
} from "@ionic/react";
import "./Tab1.css";

import {
  Datepicker,
  Button,
  Page,
  setOptions,
  localePl,
  Input,
  Popup,
  Select,
  Switch,
} from "@mobiscroll/react";
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
} from "react";

import Header from "../components/JobsterHeader";
import ContentBox from "../components/ContentBox";

import { useDebounce, useDebouncedCallback } from "use-debounce";

import { Main } from "../components/HomeScript";
import { isPlatform, ScrollDetail } from "@ionic/core";

import MobiInput from "../components/Mobi/MobiInput";
import { LocationModelAttributes } from "../components/Order/Location";
import { isValidPostCode, onScrollHeader, toggleHeader } from "../components/Common";
import MobiPopup from "../components/Mobi/MobiPopup";

import "./Home.css";

import Categories from "../data/categories.json";

import JobPost from "../components/Home/JobPost";

import Footer from "../components/JobsterFooter";
import { useHistory } from "react-router";
import MobiButton from "../components/Mobi/MobiButton";

import CategorySearch from "../components/Home/CategorySearch";

import { ForwardProps } from "../components/Home/CategorySearch";

import { CollectionProps } from "../components/Home/CategorySearch";
import { Link } from "react-router-dom";
import CustomHeaderFade from "../components/CustomHeaderFade";
import CustomHeaderCondense from "../components/CustomHeaderCondense";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';

setOptions({
  theme: "ios",
  themeVariant: "light",
});

const Home: React.FC = () => {
  let _localePl = localePl;
  _localePl.setText = "Ustaw";

  const [location, setLocation] = useState<LocationModelAttributes>({
    PostCode: "",
    City: "",
    Street: "",
    Number1: "",
    Number2: "",
  });

  const [locationError, setLocationError] = useState<LocationModelAttributes>({
    PostCode: "",
    City: "",
    Street: "",
    Number1: "",
    Number2: "",
  });

  type CategoryProps = {
    id: string;
    name: string;
    nameShow?: string;
    icon: string;
    iconShow?: string;
  };

  const headerRef = useRef<HTMLIonHeaderElement>(null);

  const categorySearchRef = useRef<ForwardProps>(null);

  useEffect(() => {
    Main();

    const result: CollectionProps[] = [];

    const findInCollection = (_collection: CollectionProps[]) => {
      _collection.map((e) => {
        result.push(e);
        if(e.next)
        {
          findInCollection(e.next);
        }
      })
    }
    findInCollection(Categories);

    setCollection(result);

  }, []);

  const history = useHistory();

  const ionContentRef = useRef<HTMLIonContentElement>(null);

  const [collection, setCollection] = useState<CollectionProps[]>([]);

  


  

  return (
    <IonPage>
      <CustomHeaderFade title="Lista zleceń" logo={true} />

      <IonContent
        fullscreen={true}
        ref={ionContentRef}
        className="background-lightgrey"
        scrollEvents={false}
        onIonScroll={(event: CustomEvent<ScrollDetail>) => {
          onScrollHeader(event);
          categorySearchRef.current?.closeModal();
        }}
      >
        {/* <div className="mg-65" /> */}

        {/* <CustomHeaderCondense title="Lista zleceń" logo={true} style={{ marginBottom: "15px" }} /> */}

        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <div>
                <section
                  style={{ marginTop: 0 }}
                  className="pxp-hero pxp-hero-boxed"
                >
                  <div className="pxp-container">
                    <div
                      className="pxp-hero-boxed-content"
                      style={{ backgroundColor: "var(--pxpMainColorLight)" }}
                    >
                      <div className="row align-items-center justify-content-center justify-content-xxl-between">
                        <div className="col-12 col-xl-8 col-xxl-6">
                          <h1>
                            Znajdź idealne
                            <br />
                            <span style={{ color: "var(--pxpMainColor)" }}>
                              zlecenia
                            </span>{" "}
                            dla siebie
                          </h1>
                          <div className="pxp-hero-subtitle mt-3 mt-lg-4">
                            Wyszukaj i wybierz teraz spośród{" "}
                            <strong>127</strong> zleceń
                          </div>

                          <div className="pxp-hero-form pxp-hero-form-round pxp-bigger mt-3 mt-lg-4">
                            <CategorySearch
                              ref={categorySearchRef}
                              updateTop={true}
                            />
                          </div>

                          <div className="overline-main-page mt-0 mt-md-3">
                            <div className="text-center pt-2 pb-2">
                              <h6 className="overline-title overline-title-sap">
                                <span>LUB</span>
                              </h6>
                            </div>
                          </div>

                          <div
                            className="main-page-button-container"
                            style={{
                              borderRadius: 30,
                            }}
                          >
                            <div className="row justify-content-center">
                              {/* <div className="col-6"><h2 style={{}}>Jesteś<span style={{color: 'var(--pxpMainColor)'}}> zleceniodawcą</span>?</h2></div> */}
                              <div className="col-auto">
                                <IonRouterLink
                                  routerLink="/AddJob"
                                  routerDirection="forward"
                                >
                                  <MobiButton
                                    className="main-page-button pulse-button pd-cst"
                                    style={{
                                      margin: 0,
                                      fontSize: 24,
                                      height: 100,
                                    }}
                                  >
                                    <div className="row">
                                      <div className="col-auto" style={{}}>
                                        Dodaj zlecenie <br />
                                        <strong style={{ fontWeight: 700 }}>
                                          za darmo
                                        </strong>
                                      </div>
                                      <div className="col-auto d-flex align-items-center main-page-button-icon">
                                        <i
                                          className="fas fa-plus"
                                          style={{
                                            fontSize: 36,
                                            padding: "0 13px 0 5px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </MobiButton>
                                </IonRouterLink>
                              </div>
                            </div>
                          </div>

                          {/* <div className="pxp-hero-searches-container">
                            <div className="pxp-hero-searches-label">
                              Popular Searches
                            </div>
                            <div className="pxp-hero-searches">
                              <div className="pxp-hero-searches-items">
                                <a href="jobs-list-1.html">Work from home</a>
                                <a href="jobs-list-1.html">Part-time</a>
                                <a href="jobs-list-1.html">Administration</a>
                                <a href="jobs-list-1.html">Finance</a>
                                <a href="jobs-list-1.html">Retail</a>
                                <a href="jobs-list-1.html">IT</a>
                                <a href="jobs-list-1.html">Engineering</a>
                                <a href="jobs-list-1.html">Sales</a>
                                <a href="jobs-list-1.html">Manufacturing</a>
                              </div>
                            </div>
                          </div> */}
                        </div>
                        <div className="d-none d-xxl-block col-xl-6 col-xxl-5">
                          <div
                            className="pxp-hero-boxed-circulars pxp-animate-circles-bounce"
                            style={{ height: "642px" }}
                          >
                            <div className="pxp-hero-boxed-circular-outer">
                              <div className="pxp-hero-boxed-circular-outer-1" />
                              <div className="pxp-hero-boxed-circular-outer-2" />
                              <div className="pxp-hero-boxed-circular-outer-3" />
                            </div>
                            <div className="pxp-hero-boxed-circular-middle">
                              <div className="pxp-hero-boxed-circular-middle-1" />
                              <div className="pxp-hero-boxed-circular-middle-2" />
                              <div className="pxp-hero-boxed-circular-middle-3" />
                            </div>
                            <div className="pxp-hero-boxed-circular-center" />
                          </div>
                          <div className="pxp-hero-boxed-icon-circles">
                            <div className="pxp-hero-boxed-icon-circle-1 pxp-animate-icon-circle-bounce">
                              <img
                                src="/images/paper-icon.png"
                                alt="Paper icon"
                              />
                            </div>
                            <div className="pxp-hero-boxed-icon-circle-2 pxp-animate-icon-circle-bounce">
                              <img
                                src="/images/folder-icon.png"
                                alt="Folder icon"
                              />
                            </div>
                          </div>
                          <div className="pxp-hero-boxed-info-cards">
                            <div className="pxp-hero-boxed-info-card-big pxp-animate-info-card">
                              <div className="pxp-hero-boxed-info-card-big-content">
                                <div className="pxp-hero-boxed-info-card-big-icon">
                                  <img
                                    src="/images/service-2-icon.png"
                                    alt="Job Fit Scoring"
                                  />
                                </div>
                                <div className="pxp-hero-boxed-info-card-big-title">
                                  Job Fit Scoring
                                </div>
                                <div className="pxp-hero-boxed-info-card-big-text pxp-text-light">
                                  Search your career opportunity through 12,800
                                  jobs
                                </div>
                              </div>
                            </div>
                            <div className="pxp-hero-boxed-info-card-small pxp-animate-info-card">
                              <div className="pxp-hero-boxed-info-card-small-content">
                                <div className="pxp-hero-boxed-info-card-small-icon">
                                  <img
                                    src="/images/service-1-icon.png"
                                    alt="Full-service recruiting"
                                  />
                                </div>
                                <div className="pxp-hero-boxed-info-card-small-title">
                                  Zweryfikowane firmy
                                  <br /> i freelancerzy
                                </div>
                              </div>
                            </div>
                            <div className="pxp-hero-boxed-info-list-container pxp-animate-info-card">
                              <div className="pxp-hero-boxed-info-list">
                                <div className="pxp-hero-boxed-info-list-item">
                                  <div className="pxp-hero-boxed-info-list-item-number">
                                    286<span>job offers</span>
                                  </div>
                                  <div className="pxp-hero-boxed-info-list-item-description">
                                    in Business Development
                                  </div>
                                </div>
                                <div className="pxp-hero-boxed-info-list-item">
                                  <div className="pxp-hero-boxed-info-list-item-number">
                                    154<span>job offers</span>
                                  </div>
                                  <div className="pxp-hero-boxed-info-list-item-description">
                                    in Marketing &amp; Communication
                                  </div>
                                </div>
                                <div className="pxp-hero-boxed-info-list-item">
                                  <div className="pxp-hero-boxed-info-list-item-number">
                                    319<span>job offers</span>
                                  </div>
                                  <div className="pxp-hero-boxed-info-list-item-description">
                                    in Human Resources
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="mt-100">
                  <div className="pxp-container">
                    <h2 className="pxp-section-h2 text-center">
                      Search by Category
                    </h2>
                    <p className="pxp-text-light text-center">
                      Search your career opportunity with our categories
                    </p>
                    <div className="row justify-content-center mt-4 mt-md-5">
                      {collection.map((e) => {
                        return (
                          <div
                            key={e.id}
                            className="col-4 col-md-4 col-lg-3 col-xxl-2 pxp-categories-card-1-container pxp-animate-in-top"
                          >
                            <IonRouterLink
                              onClick={() =>
                                history.push("/zlecenia", { category: e.name })
                              }
                            >
                              <a
                                style={{
                                  position: "relative",
                                  overflow: "hidden",
                                  pointerEvents: "none",
                                }}
                                href="jobs-list-1.html"
                                className="pxp-categories-card-2"
                              >
                                <div className="pxp-categories-card-1-icon-container">
                                  <div className="pxp-categories-card-1-icon">
                                    <span className={e.icon} />
                                  </div>
                                </div>
                                <div className="pxp-categories-card-1-title">
                                  {e.name}
                                </div>
                                <div className="pxp-categories-card-1-subtitle">
                                  139 open positions
                                </div>
                                <IonRippleEffect></IonRippleEffect>
                              </a>
                            </IonRouterLink>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </section>

                

                <section className="mt-100">
                  <div className="pxp-container">
                    <h2 className="pxp-section-h2">Wyróżnione zlecenia</h2>
                    <p className="pxp-text-light">
                      Search your career opportunity through 12,800 jobs
                    </p>
                    <div className="row mt-4 mt-md-5 pxp-animate-in-top">

                    {
                  isPlatform("mobile")
                  ?
                  <Swiper
                    //  options={{
                    //   freeMode: true,
                    //   freeModeMomentum: true,
                    //   freeModeMomentumRatio: 0.2
                    // }} 
                    >
                      {["", "", "","", "", ""].map((e, i) => {
                        return (
                          <SwiperSlide>
                          <JobPost
                            margin
                            transitionDelay={
                              isPlatform("mobile") ? 0.15 : 0.15 * i
                            }
                          />
                          </SwiperSlide>
                        );
                      })}
                      </Swiper>
                  :
                  ["", "", "","", "", ""].map((e, i) => {
                        return (
                          <JobPost
                            transitionDelay={
                              isPlatform("mobile") ? 0.15 : 0.15 * i
                            }
                          />
                        );
                      })
                }

                    
                    </div>
                    <div className="row justify-content-end mt-4 mt-md-5 pxp-animate-in-top">
                      <a
                        href="jobs-list-1.html"
                        className="btn rounded-pill pxp-section-cta"
                        style={{ lineHeight: "inherit" }}
                      >
                        Zobacz wszystkie zlecenia
                        <span
                          className="fa fa-angle-right"
                          style={{ width: "auto" }}
                        />
                      </a>
                    </div>
                  </div>
                </section>
                {/* <section
                  className="mt-100 pt-100 pb-100"
                  style={{ backgroundColor: "var(--pxpMainColorLight)" }}
                >
                  <div className="pxp-container">
                    <h2 className="pxp-section-h2">Featured Candidates</h2>
                    <p className="pxp-text-light">
                      We can help you find and engage with candidates
                    </p>
                    <div className="row mt-4 mt-md-5 pxp-animate-in-top">
                      <div className="col-md-6 col-xl-4 col-xxl-3 pxp-candiadates-card-1-container">
                        <div className="pxp-candiadates-card-1 pxp-has-shadow text-center">
                          <div className="pxp-candiadates-card-1-top">
                            <div
                              className="pxp-candiadates-card-1-avatar pxp-cover"
                              style={{
                                backgroundImage: "url(/images/ph-small.jpg)",
                              }}
                            />
                            <div className="pxp-candiadates-card-1-name">
                              Derek Cotner
                            </div>
                            <div className="pxp-candiadates-card-1-title">
                              UI Designer
                            </div>
                            <div className="pxp-candiadates-card-1-location">
                              <span className="fa fa-globe" />
                              London, UK
                            </div>
                          </div>
                          <div className="pxp-candiadates-card-1-bottom">
                            <div className="pxp-candiadates-card-1-cta">
                              <a href="single-candidate-1.html">
                                View profile
                                <span className="fa fa-angle-right" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-4 col-xxl-3 pxp-candiadates-card-1-container">
                        <div className="pxp-candiadates-card-1 pxp-has-shadow text-center">
                          <div className="pxp-candiadates-card-1-top">
                            <div
                              className="pxp-candiadates-card-1-avatar pxp-cover"
                              style={{
                                backgroundImage: "url(/images/ph-small.jpg)",
                              }}
                            />
                            <div className="pxp-candiadates-card-1-name">
                              Kenneth Spiers
                            </div>
                            <div className="pxp-candiadates-card-1-title">
                              Software Developer
                            </div>
                            <div className="pxp-candiadates-card-1-location">
                              <span className="fa fa-globe" />
                              San Francisco, CA
                            </div>
                          </div>
                          <div className="pxp-candiadates-card-1-bottom">
                            <div className="pxp-candiadates-card-1-cta">
                              <a href="single-candidate-1.html">
                                View profile
                                <span className="fa fa-angle-right" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-4 col-xxl-3 pxp-candiadates-card-1-container">
                        <div className="pxp-candiadates-card-1 pxp-has-shadow text-center">
                          <div className="pxp-candiadates-card-1-top">
                            <div
                              className="pxp-candiadates-card-1-avatar pxp-cover"
                              style={{
                                backgroundImage: "url(/images/ph-small.jpg)",
                              }}
                            />
                            <div className="pxp-candiadates-card-1-name">
                              Rebecca Eason
                            </div>
                            <div className="pxp-candiadates-card-1-title">
                              Marketing Expert
                            </div>
                            <div className="pxp-candiadates-card-1-location">
                              <span className="fa fa-globe" />
                              Los Angeles, CA
                            </div>
                          </div>
                          <div className="pxp-candiadates-card-1-bottom">
                            <div className="pxp-candiadates-card-1-cta">
                              <a href="single-candidate-1.html">
                                View profile
                                <span className="fa fa-angle-right" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-4 col-xxl-3 pxp-candiadates-card-1-container">
                        <div className="pxp-candiadates-card-1 pxp-has-shadow text-center">
                          <div className="pxp-candiadates-card-1-top">
                            <div
                              className="pxp-candiadates-card-1-avatar pxp-cover"
                              style={{
                                backgroundImage: "url(/images/ph-small.jpg)",
                              }}
                            />
                            <div className="pxp-candiadates-card-1-name">
                              Susanne Weil
                            </div>
                            <div className="pxp-candiadates-card-1-title">
                              Architect
                            </div>
                            <div className="pxp-candiadates-card-1-location">
                              <span className="fa fa-globe" />
                              Paris, France
                            </div>
                          </div>
                          <div className="pxp-candiadates-card-1-bottom">
                            <div className="pxp-candiadates-card-1-cta">
                              <a href="single-candidate-1.html">
                                View profile
                                <span className="fa fa-angle-right" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 pxp-animate-in-top">
                      <a
                        href="candidates-list-1.html"
                        className="btn rounded-pill pxp-section-cta"
                      >
                        All Candidates
                        <span className="fa fa-angle-right" />
                      </a>
                    </div>
                  </div>
                </section>
                <section
                  className="pt-100 pb-100"
                  style={{ backgroundColor: "var(--pxpSecondaryColorLight)" }}
                >
                  <div className="pxp-container">
                    <h2 className="pxp-section-h2">Featured Companies</h2>
                    <p className="pxp-text-light">
                      Work for the best companies in the world
                    </p>
                    <div className="row mt-4 mt-md-5 pxp-animate-in-top">
                      <div className="col-md-6 col-xl-4 col-xxl-3 pxp-companies-card-1-container">
                        <div className="pxp-companies-card-1 pxp-has-shadow">
                          <div className="pxp-companies-card-1-top">
                            <a
                              href="single-company-1.html"
                              className="pxp-companies-card-1-company-logo"
                              style={{
                                backgroundImage:
                                  "url(/images/company-logo-1.png)",
                              }}
                            />
                            <a
                              href="single-company-1.html"
                              className="pxp-companies-card-1-company-name"
                            >
                              Artistre Studio
                            </a>
                            <div className="pxp-companies-card-1-company-description pxp-text-light">
                              Is an American multinational computer software
                              company. Incorporated in Delaware and
                              headquartered in San Jose, California, it has
                              historically specialized in software for the
                              creation.
                            </div>
                          </div>
                          <div className="pxp-companies-card-1-bottom">
                            <a
                              href="jobs-list-1.html"
                              className="pxp-companies-card-1-company-jobs"
                            >
                              10 jobs
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-4 col-xxl-3 pxp-companies-card-1-container">
                        <div className="pxp-companies-card-1 pxp-has-shadow">
                          <div className="pxp-companies-card-1-top">
                            <a
                              href="single-company-1.html"
                              className="pxp-companies-card-1-company-logo"
                              style={{
                                backgroundImage:
                                  "url(/images/company-logo-7.png)",
                              }}
                            />
                            <a
                              href="single-company-1.html"
                              className="pxp-companies-card-1-company-name"
                            >
                              CoderBotics
                            </a>
                            <div className="pxp-companies-card-1-company-description pxp-text-light">
                              Is an American multinational computer software
                              company. Incorporated in Delaware and
                              headquartered in San Jose, California.
                            </div>
                          </div>
                          <div className="pxp-companies-card-1-bottom">
                            <a
                              href="jobs-list-1.html"
                              className="pxp-companies-card-1-company-jobs"
                            >
                              14 jobs
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-4 col-xxl-3 pxp-companies-card-1-container">
                        <div className="pxp-companies-card-1 pxp-has-shadow">
                          <div className="pxp-companies-card-1-top">
                            <a
                              href="single-company-1.html"
                              className="pxp-companies-card-1-company-logo"
                              style={{
                                backgroundImage:
                                  "url(/images/company-logo-4.png)",
                              }}
                            />
                            <a
                              href="single-company-1.html"
                              className="pxp-companies-card-1-company-name"
                            >
                              Syspresoft
                            </a>
                            <div className="pxp-companies-card-1-company-description pxp-text-light">
                              Is an American multinational computer software
                              company. Incorporated in Delaware and
                              headquartered in San Jose, California, it has
                              historically specialized in software for the
                              creation.
                            </div>
                          </div>
                          <div className="pxp-companies-card-1-bottom">
                            <a
                              href="jobs-list-1.html"
                              className="pxp-companies-card-1-company-jobs"
                            >
                              10 jobs
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-4 col-xxl-3 pxp-companies-card-1-container">
                        <div className="pxp-companies-card-1 pxp-has-shadow">
                          <div className="pxp-companies-card-1-top">
                            <a
                              href="single-company-1.html"
                              className="pxp-companies-card-1-company-logo"
                              style={{
                                backgroundImage:
                                  "url(/images/company-logo-3.png)",
                              }}
                            />
                            <a
                              href="single-company-1.html"
                              className="pxp-companies-card-1-company-name"
                            >
                              Illuminate Studio
                            </a>
                            <div className="pxp-companies-card-1-company-description pxp-text-light">
                              Is an American multinational computer software
                              company. Incorporated in Delaware and
                              headquartered in San Jose, California.
                            </div>
                          </div>
                          <div className="pxp-companies-card-1-bottom">
                            <a
                              href="jobs-list-1.html"
                              className="pxp-companies-card-1-company-jobs"
                            >
                              14 jobs
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 pxp-animate-in-top">
                      <a
                        href="companies-list-1.html"
                        className="btn rounded-pill pxp-section-cta"
                      >
                        All Companies
                        <span className="fa fa-angle-right" />
                      </a>
                    </div>
                  </div>
                </section>
                <section className="mt-100">
                  <div className="pxp-container">
                    <h2 className="pxp-section-h2">Top Career Advice</h2>
                    <p className="pxp-text-light">
                      Browse the latest career advices
                    </p>
                    <div className="row mt-4 mt-md-5 pxp-animate-in-top">
                      <div className="col-md-6 col-xl-4 col-xxl-3 pxp-posts-card-1-container">
                        <div className="pxp-posts-card-1 pxp-has-border">
                          <div className="pxp-posts-card-1-top">
                            <div className="pxp-posts-card-1-top-bg">
                              <div
                                className="pxp-posts-card-1-image pxp-cover"
                                style={{
                                  backgroundImage: "url(/images/ph-medium.jpg)",
                                }}
                              />
                              <div className="pxp-posts-card-1-info">
                                <div className="pxp-posts-card-1-date">
                                  August 31, 2021
                                </div>
                                <a
                                  href="blog-list-1.html"
                                  className="pxp-posts-card-1-category"
                                >
                                  Assessments
                                </a>
                              </div>
                            </div>
                            <div className="pxp-posts-card-1-content">
                              <a
                                href="single-blog-post.html"
                                className="pxp-posts-card-1-title"
                              >
                                10 awesome free career self assessments
                              </a>
                              <div className="pxp-posts-card-1-summary pxp-text-light">
                                Figuring out what you want to be when you grow
                                up is hard, but a career test can make it easier
                                to find...
                              </div>
                            </div>
                          </div>
                          <div className="pxp-posts-card-1-bottom">
                            <div className="pxp-posts-card-1-cta">
                              <a href="single-blog-post.html">
                                Read more
                                <span className="fa fa-angle-right" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-4 col-xxl-3 pxp-posts-card-1-container">
                        <div className="pxp-posts-card-1 pxp-has-border">
                          <div className="pxp-posts-card-1-top">
                            <div className="pxp-posts-card-1-top-bg">
                              <div
                                className="pxp-posts-card-1-image pxp-cover"
                                style={{
                                  backgroundImage: "url(/images/ph-medium.jpg)",
                                }}
                              />
                              <div className="pxp-posts-card-1-info">
                                <div className="pxp-posts-card-1-date">
                                  September 5, 2021
                                </div>
                                <a
                                  href="blog-list-1.html"
                                  className="pxp-posts-card-1-category"
                                >
                                  Jobs
                                </a>
                              </div>
                            </div>
                            <div className="pxp-posts-card-1-content">
                              <a
                                href="single-blog-post.html"
                                className="pxp-posts-card-1-title"
                              >
                                How to start looking for a job
                              </a>
                              <div className="pxp-posts-card-1-summary pxp-text-light">
                                Your resume is perfect. It's keyword-optimized,
                                industry-specified, full of achievements, backed
                                by data...
                              </div>
                            </div>
                          </div>
                          <div className="pxp-posts-card-1-bottom">
                            <div className="pxp-posts-card-1-cta">
                              <a href="single-blog-post.html">
                                Read more
                                <span className="fa fa-angle-right" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-4 col-xxl-3 pxp-posts-card-1-container">
                        <div className="pxp-posts-card-1 pxp-has-border">
                          <div className="pxp-posts-card-1-top">
                            <div className="pxp-posts-card-1-top-bg">
                              <div
                                className="pxp-posts-card-1-image pxp-cover"
                                style={{
                                  backgroundImage: "url(/images/ph-medium.jpg)",
                                }}
                              />
                              <div className="pxp-posts-card-1-info">
                                <div className="pxp-posts-card-1-date">
                                  September 10, 2021
                                </div>
                                <a
                                  href="blog-list-1.html"
                                  className="pxp-posts-card-1-category"
                                >
                                  Resume
                                </a>
                              </div>
                            </div>
                            <div className="pxp-posts-card-1-content">
                              <a
                                href="single-blog-post.html"
                                className="pxp-posts-card-1-title"
                              >
                                Resume samples
                              </a>
                              <div className="pxp-posts-card-1-summary pxp-text-light">
                                Need help writing a resume? Looking for resume
                                examples for specific industries? You’ll find a
                                variety...
                              </div>
                            </div>
                          </div>
                          <div className="pxp-posts-card-1-bottom">
                            <div className="pxp-posts-card-1-cta">
                              <a href="single-blog-post.html">
                                Read more
                                <span className="fa fa-angle-right" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-4 col-xxl-3 pxp-posts-card-1-container">
                        <div className="pxp-posts-card-1 pxp-has-border">
                          <div className="pxp-posts-card-1-top">
                            <div className="pxp-posts-card-1-top-bg">
                              <div
                                className="pxp-posts-card-1-image pxp-cover"
                                style={{
                                  backgroundImage: "url(/images/ph-medium.jpg)",
                                }}
                              />
                              <div className="pxp-posts-card-1-info">
                                <div className="pxp-posts-card-1-date">
                                  September 15, 2021
                                </div>
                                <a
                                  href="blog-list-1.html"
                                  className="pxp-posts-card-1-category"
                                >
                                  Interview
                                </a>
                              </div>
                            </div>
                            <div className="pxp-posts-card-1-content">
                              <a
                                href="single-blog-post.html"
                                className="pxp-posts-card-1-title"
                              >
                                100 top interview questions - be prepared
                              </a>
                              <div className="pxp-posts-card-1-summary pxp-text-light">
                                While there are as many different possible
                                interview questions as there are interviewers,
                                it always helps...
                              </div>
                            </div>
                          </div>
                          <div className="pxp-posts-card-1-bottom">
                            <div className="pxp-posts-card-1-cta">
                              <a href="single-blog-post.html">
                                Read more
                                <span className="fa fa-angle-right" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 pxp-animate-in-top">
                      <a
                        href="blog-list-1.html"
                        className="btn rounded-pill pxp-section-cta"
                      >
                        Read All Stories
                        <span className="fa fa-angle-right" />
                      </a>
                    </div>
                  </div>
                </section> */}
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonFooter>
          <Footer />
        </IonFooter>
      </IonContent>
    </IonPage>
  );
};

export default Home;

import { localePl, Popup, setOptions } from "@mobiscroll/react";
import React, {
  createRef,
  MouseEvent,
  ReactNode,
  Ref,
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  IonItem,
  IonLabel,
  IonInput,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonCol,
  IonRow,
  IonFooter,
  useIonViewWillEnter,
  useIonViewDidEnter,
  IonButtons,
  IonButton,
  IonIcon,
  IonBackButton,
  isPlatform,
} from "@ionic/react";

import { appsOutline, arrowBack, reorderFourOutline } from "ionicons/icons";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router";

import './CustomHeaderFade.css'

setOptions({
  theme: "ios",
  themeVariant: "light",
  locale: localePl,
});

type Props = {
  children?: ReactNode,
  title?: string;
  mode?: "ios" | "md";
  logo?: boolean;
  arrow?: boolean;
  long?: ReactNode;
  extended?: ReactNode;
  headerRef?: RefObject<HTMLIonHeaderElement>;
  extraButtons?: ReactNode;
};

const CustomHeaderFade: React.FC<Props> = ({
  children,
  title,
  mode = "md",
  long,
  headerRef,
  extraButtons,
  arrow = false,
  logo = false,
  extended = true,
}) => {
  const [hide, setHide] = useState(true);

  useEffect(() => {
    setHide(true);

    setTimeout(() => {
      setHide(false);
    }, 150);
  }, []);

  const history = useHistory();

  return (
    <IonHeader ref={headerRef} collapse="fade" translucent={isPlatform("mobile")} mode={isPlatform("mobile") ? mode : "ios"} className={(long ? "fade-header-long-container " : "") + (arrow ? "fade-header-arrow " : "")} >
      <IonToolbar>
        {isPlatform("mobile") ? (
          <>
            {arrow ? (
              <IonButtons slot="start">
                <IonButton onClick={() => history.goBack()}>
                  <IonIcon slot="icon-only" icon={arrowBack}  />
                </IonButton>
              </IonButtons>
            ) : (
              <></>
            )}
            <IonTitle>
              <div
                className={"fade-header " + (long ? "fade-header-long" : "")}
              >
                {logo ? (
                  <div className="pxp-logo">
                    <a className="pxp-animate" style={{
                      fontFamily: "'Nunito'"
                    }}>
                      zlecenia
                      <span style={{ color: "var(--pxpMainColor)" }}>.app</span>
                    </a>
                  </div>
                ) : 
                children ? (
                  children
                ) : 
                (
                  title
                  )
                }
              </div>
            </IonTitle>

                {extraButtons}

            

            {
              extended
              ?
              <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  const el = document.getElementById("mainMenu") as any;

                  if (el) {
                    el.open();
                  }
                }}
              >
                <IonIcon slot="icon-only" icon={reorderFourOutline} />
              </IonButton>
            </IonButtons>
            :
            <></>
            }
          </>
        ) : (
          <div className="pxp-container">
            <IonTitle size={isPlatform("mobile") ? "small" : "large"}>
              <div className="pxp-logo">
                <a href="index.html" className="pxp-animate"
                  style={{
                    fontFamily: "'Nunito'"
                  }}
                >
                  zlecenia
                  <span style={{ color: "var(--pxpMainColor)" }}>.app</span>
                </a>
              </div>
              <div className="pxp-header-right" style={{ float: "right" }}>
                <nav className="pxp-nav dropdown-hover-all d-none d-xl-block">
                  <ul>
                    <li className="dropdown">
                      <NavLink to={"/a/tab1"}>
                        <a className="nav-link">Home</a>
                      </NavLink>
                      <ul className="dropdown-menu">
                        <li className="pxp-dropdown-body">
                          <div className="pxp-dropdown-layout">
                            <div className="pxp-dropdown-header">
                              Home Page Versions
                            </div>
                            <div className="row gx-5 pxp-dropdown-lists">
                              <div className="col-auto pxp-dropdown-list">
                                <ul>
                                  <li>
                                    <a
                                      href="index.html"
                                      className="pxp-has-icon"
                                    >
                                      <div className="pxp-dropdown-icon">
                                        <img
                                          src="images/index-1-nav-icon@2x.png"
                                          alt="Hero with Animated Cards"
                                        />
                                      </div>
                                      <div className="pxp-dropdown-text">
                                        Hero with Animated Cards
                                        <span>Home page version 1</span>
                                      </div>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="index-2.html"
                                      className="pxp-has-icon"
                                    >
                                      <div className="pxp-dropdown-icon">
                                        <img
                                          src="images/index-2-nav-icon@2x.png"
                                          alt="Center Image Rotator Hero"
                                        />
                                      </div>
                                      <div className="pxp-dropdown-text">
                                        Center Image Rotator Hero
                                        <span>Home page version 2</span>
                                      </div>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="index-3.html"
                                      className="pxp-has-icon"
                                    >
                                      <div className="pxp-dropdown-icon">
                                        <img
                                          src="images/index-3-nav-icon@2x.png"
                                          alt="Hero with Illustration"
                                        />
                                      </div>
                                      <div className="pxp-dropdown-text">
                                        Hero with Illustration
                                        <span>Home page version 3</span>
                                      </div>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="index-4.html"
                                      className="pxp-has-icon"
                                    >
                                      <div className="pxp-dropdown-icon">
                                        <img
                                          src="images/index-4-nav-icon@2x.png"
                                          alt="Boxed Hero with Animation"
                                        />
                                      </div>
                                      <div className="pxp-dropdown-text">
                                        Boxed Hero with Animation
                                        <span>Home page version 4</span>
                                      </div>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-auto pxp-dropdown-list">
                                <ul>
                                  <li>
                                    <a
                                      href="index-5.html"
                                      className="pxp-has-icon"
                                    >
                                      <div className="pxp-dropdown-icon">
                                        <img
                                          src="images/index-5-nav-icon@2x.png"
                                          alt="Full Image Background Hero"
                                        />
                                      </div>
                                      <div className="pxp-dropdown-text">
                                        Full Image Background Hero
                                        <span>Home page version 5</span>
                                      </div>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="index-6.html"
                                      className="pxp-has-icon"
                                    >
                                      <div className="pxp-dropdown-icon">
                                        <img
                                          src="images/index-6-nav-icon@2x.png"
                                          alt="Full Image with Top Search"
                                        />
                                      </div>
                                      <div className="pxp-dropdown-text">
                                        Full Image with Top Search
                                        <span>Home page version 6</span>
                                      </div>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="index-7.html"
                                      className="pxp-has-icon"
                                    >
                                      <div className="pxp-dropdown-icon">
                                        <img
                                          src="images/index-7-nav-icon@2x.png"
                                          alt="Hero With Image Card"
                                        />
                                      </div>
                                      <div className="pxp-dropdown-text">
                                        Hero With Image Card
                                        <span>Home page version 7</span>
                                      </div>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown">
                      <a
                        href="#"
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        Find Jobs
                      </a>
                      <ul className="dropdown-menu">
                        <li className="pxp-dropdown-body">
                          <div className="pxp-dropdown-layout">
                            <div className="row gx-5 pxp-dropdown-lists">
                              <div className="col-auto pxp-dropdown-list">
                                <div className="pxp-dropdown-header">
                                  Job Listings
                                </div>
                                <ul>
                                  <li>
                                    <a
                                      href="jobs-list-1.html"
                                      className="pxp-has-icon-small"
                                    >
                                      <div className="pxp-dropdown-icon">
                                        <span className="fa fa-th-large" />
                                      </div>
                                      <div className="pxp-dropdown-text">
                                        Top Search with Cards
                                        <span>Jobs listing version 1</span>
                                      </div>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="jobs-list-2.html"
                                      className="pxp-has-icon-small"
                                    >
                                      <div className="pxp-dropdown-icon">
                                        <span className="fa fa-th" />
                                      </div>
                                      <div className="pxp-dropdown-text">
                                        Top Search with Small Cards
                                        <span>Jobs listing version 2</span>
                                      </div>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="jobs-list-3.html"
                                      className="pxp-has-icon-small"
                                    >
                                      <div className="pxp-dropdown-icon">
                                        <span className="fa fa-th-list" />
                                      </div>
                                      <div className="pxp-dropdown-text">
                                        Top Search with List
                                        <span>Jobs listing version 3</span>
                                      </div>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="jobs-list-4.html"
                                      className="pxp-has-icon-small"
                                    >
                                      <div className="pxp-dropdown-icon">
                                        <span className="fa fa-toggle-right" />
                                      </div>
                                      <div className="pxp-dropdown-text">
                                        Left Search with Cards
                                        <span>Jobs listing version 4</span>
                                      </div>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="jobs-list-5.html"
                                      className="pxp-has-icon-small"
                                    >
                                      <div className="pxp-dropdown-icon">
                                        <span className="fa fa-list-ul" />
                                      </div>
                                      <div className="pxp-dropdown-text">
                                        Left Search with Small Cards
                                        <span>Jobs listing version 5</span>
                                      </div>
                                    </a>
                                  </li>
                                </ul>
                                <div className="pxp-dropdown-header mt-3">
                                  Single Job
                                </div>
                                <ul>
                                  <li>
                                    <a
                                      href="single-job-1.html"
                                      className="pxp-has-icon-small"
                                    >
                                      <div className="pxp-dropdown-icon">
                                        <span className="fa fa-align-justify" />
                                      </div>
                                      <div className="pxp-dropdown-text">
                                        Wide Content
                                        <span>Single job version 1</span>
                                      </div>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="single-job-2.html"
                                      className="pxp-has-icon-small"
                                    >
                                      <div className="pxp-dropdown-icon">
                                        <span className="fa fa-dedent" />
                                      </div>
                                      <div className="pxp-dropdown-text">
                                        Right Side Panel
                                        <span>Single job version 2</span>
                                      </div>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-auto pxp-dropdown-list">
                                <div className="pxp-dropdown-header">
                                  &nbsp;
                                </div>
                                <ul>
                                  <li>
                                    <a
                                      href="jobs-list-6.html"
                                      className="pxp-has-icon-small"
                                    >
                                      <div className="pxp-dropdown-icon">
                                        <span className="fa fa-indent" />
                                      </div>
                                      <div className="pxp-dropdown-text">
                                        Left Search with List
                                        <span>Jobs listing version 6</span>
                                      </div>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="jobs-list-7.html"
                                      className="pxp-has-icon-small"
                                    >
                                      <div className="pxp-dropdown-icon">
                                        <span className="fa fa-th-large" />
                                      </div>
                                      <div className="pxp-dropdown-text">
                                        No Sidebar with Cards
                                        <span>Jobs listing version 7</span>
                                      </div>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="jobs-list-8.html"
                                      className="pxp-has-icon-small"
                                    >
                                      <div className="pxp-dropdown-icon">
                                        <span className="fa fa-th" />
                                      </div>
                                      <div className="pxp-dropdown-text">
                                        No Sidebar with Small Cards
                                        <span>Jobs listing version 8</span>
                                      </div>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="jobs-list-9.html"
                                      className="pxp-has-icon-small"
                                    >
                                      <div className="pxp-dropdown-icon">
                                        <span className="fa fa-th-list" />
                                      </div>
                                      <div className="pxp-dropdown-text">
                                        No Sidebar with List
                                        <span>Jobs listing version 9</span>
                                      </div>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="jobs-list-10.html"
                                      className="pxp-has-icon-small"
                                    >
                                      <div className="pxp-dropdown-icon">
                                        <span className="fa fa-list-alt=" />
                                      </div>
                                      <div className="pxp-dropdown-text">
                                        Top Search with Card Details
                                        <span>Jobs listing version 10</span>
                                      </div>
                                    </a>
                                  </li>
                                </ul>
                                <div className="pxp-dropdown-header mt-3">
                                  &nbsp;
                                </div>
                                <ul>
                                  <li>
                                    <a
                                      href="single-job-3.html"
                                      className="pxp-has-icon-small"
                                    >
                                      <div className="pxp-dropdown-icon">
                                        <span className="fa fa-align-center" />
                                      </div>
                                      <div className="pxp-dropdown-text">
                                        Center Boxed Content
                                        <span>Single job version 3</span>
                                      </div>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown">
                      <a
                        href="#"
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        Companies
                      </a>
                      <ul className="dropdown-menu">
                        <li className="dropdown dropend">
                          <a
                            className="dropdown-item dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                          >
                            <div className="pxp-dropdown-icon">
                              <span className="fa fa-th-large" />
                            </div>
                            <div className="pxp-dropdown-text">
                              Companies List
                              <span>List of companies versions</span>
                            </div>
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="companies-list-1.html"
                              >
                                <div className="pxp-dropdown-text">
                                  Top Search
                                  <span>Companies list version 1</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="companies-list-2.html"
                              >
                                <div className="pxp-dropdown-text">
                                  Left Search Side Panel
                                  <span>Companies list version 2</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="companies-list-3.html"
                              >
                                <div className="pxp-dropdown-text">
                                  Top Search Light
                                  <span>Companies list version 3</span>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="dropdown dropend">
                          <a
                            className="dropdown-item dropdown-toggle pxp-has-icon-small"
                            href="#"
                            data-bs-toggle="dropdown"
                          >
                            <div className="pxp-dropdown-icon">
                              <span className="fa fa-file-text-o" />
                            </div>
                            <div className="pxp-dropdown-text">
                              Single Company
                              <span>Company profile page versions</span>
                            </div>
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="single-company-1.html"
                              >
                                <div className="pxp-dropdown-text">
                                  Wide Content
                                  <span>Company profile version 1</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="single-company-2.html"
                              >
                                <div className="pxp-dropdown-text">
                                  Right Side Panel
                                  <span>Company profile version 2</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="single-company-3.html"
                              >
                                <div className="pxp-dropdown-text">
                                  Center Boxed Content
                                  <span>Company profile version 3</span>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a
                            href="company-dashboard.html"
                            className="pxp-has-icon-small"
                          >
                            <div className="pxp-dropdown-icon">
                              <span className="fa fa-cogs" />
                            </div>
                            <div className="pxp-dropdown-text">
                              Company Dashboard
                              <span>Useful insights and settings</span>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown">
                      <a
                        href="#"
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        Candidates
                      </a>
                      <ul className="dropdown-menu">
                        <li className="dropdown dropend">
                          <a
                            className="dropdown-item dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                          >
                            <div className="pxp-dropdown-icon">
                              <span className="fa fa-th-large" />
                            </div>
                            <div className="pxp-dropdown-text">
                              Candidates List
                              <span>List of candidates versions</span>
                            </div>
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="candidates-list-1.html"
                              >
                                <div className="pxp-dropdown-text">
                                  Top Search
                                  <span>Candidates list version 1</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="candidates-list-2.html"
                              >
                                <div className="pxp-dropdown-text">
                                  Left Search Side Panel
                                  <span>Candidates list version 2</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="candidates-list-3.html"
                              >
                                <div className="pxp-dropdown-text">
                                  Top Search Light
                                  <span>Candidates list version 3</span>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="dropdown dropend">
                          <a
                            className="dropdown-item dropdown-toggle pxp-has-icon-small"
                            href="#"
                            data-bs-toggle="dropdown"
                          >
                            <div className="pxp-dropdown-icon">
                              <span className="fa fa-user-circle-o" />
                            </div>
                            <div className="pxp-dropdown-text">
                              Single Candidate
                              <span>Candidate profile page versions</span>
                            </div>
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="single-candidate-1.html"
                              >
                                <div className="pxp-dropdown-text">
                                  Wide Content
                                  <span>Candidate profile version 1</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="single-candidate-2.html"
                              >
                                <div className="pxp-dropdown-text">
                                  Right Side Panel
                                  <span>Candidate profile version 2</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="single-candidate-3.html"
                              >
                                <div className="pxp-dropdown-text">
                                  Center Boxed Content
                                  <span>Candidate profile version 3</span>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a
                            href="candidate-dashboard.html"
                            className="pxp-has-icon-small"
                          >
                            <div className="pxp-dropdown-icon">
                              <span className="fa fa-cog" />
                            </div>
                            <div className="pxp-dropdown-text">
                              Candidate Dashboard
                              <span>Useful insights and settings</span>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown">
                      <a
                        href="#"
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        Blog
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="blog-list-1.html">
                            Articles Cards
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="blog-list-2.html">
                            Articles List
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="blog-list-3.html">
                            Articles Boxed
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="single-blog-post.html"
                          >
                            Single Article
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown">
                      <a
                        href="#"
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        Pages
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="about-us.html">
                            About Us
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="pricing.html">
                            Pricing
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="faqs.html">
                            FAQs
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="contact-us.html">
                            Contact Us
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="sign-in.html">
                            Sign In
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="sign-up.html">
                            Sign Up
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="404.html">
                            404 Page
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
                <nav className="pxp-user-nav pxp-on-light d-none d-sm-flex">
                  <a
                    href="company-dashboard-new-job.html"
                    className="btn rounded-pill pxp-nav-btn"
                  >
                    Post a Job
                  </a>
                  <a
                    className="btn rounded-pill pxp-user-nav-trigger"
                    data-bs-toggle="modal"
                    href="#pxp-signin-modal"
                    role="button"
                  >
                    Sign in
                  </a>
                </nav>
              </div>
            </IonTitle>
          </div>
        )}
      </IonToolbar>
    </IonHeader>
  );
};

export default CustomHeaderFade;

import {
  IonItem,
  IonLabel,
  IonInput,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonCol,
  IonRow,
  IonFooter,
  useIonViewWillEnter,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import "./Tab1.css";

import {
  Datepicker,
  Button,
  Page,
  setOptions,
  localePl,
  Input,
  Popup,
  Select,
  Switch,
} from "@mobiscroll/react";
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
} from "react";

import Header from "../components/JobsterHeader";
import ContentBox from "../components/ContentBox";

import { useDebounce, useDebouncedCallback } from "use-debounce";

import { Main } from "../components/HomeScript";
import { isPlatform, ScrollDetail } from "@ionic/core";

import MobiInput from "../components/Mobi/MobiInput";
import { LocationModelAttributes } from "../components/Order/Location";
import { isValidPostCode, onScrollHeader } from "../components/Common";
import MobiPopup from "../components/Mobi/MobiPopup";

import "./AddJob.css";

import JobPost from "../components/Home/JobPost";

import Footer from "../components/JobsterFooter";
import { useHistory } from "react-router";
import MobiButton from "../components/Mobi/MobiButton";

import { Link } from "react-router-dom";
import MobiTextarea from "../components/Mobi/MobiTextarea";
import CustomHeaderFade from "../components/CustomHeaderFade";
import CustomHeaderCondense from "../components/CustomHeaderCondense";

import CategorySearchInput from "../components/Home/CategorySearchInput";

setOptions({
  theme: "ios",
  themeVariant: "light",
});

const Home: React.FC = () => {
  let _localePl = localePl;
  _localePl.setText = "Ustaw";

  const headerRef = useRef<HTMLIonHeaderElement>(null);

  const [headerScrollTop, setHeaderScrollTop] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    if(headerRef.current)
    {
      headerRef.current.style.willChange = "transform";
      headerRef.current.style.transition = "transform ease-in-out 150ms";
    }
  }, [])

  useIonViewWillLeave(() => {
    if(headerRef.current)
    {
      headerRef.current.style.transform = "translate3d(0, 0, 0)";
    }
    setHeaderTop(0);
  });

  return (
    <IonPage>
      <CustomHeaderFade headerRef={headerRef} arrow title="Dodaj zlecenie" />

      <IonContent
        fullscreen={true}
        className="background-lightgrey"
        scrollEvents={true}
        onIonScroll={(event: CustomEvent<ScrollDetail>) => {
          //onScrollHeader(event);

          if (headerRef.current) {
            // if (headerTop >= -57 && headerTop <= 0) {
            //   let difference = headerTop + (headerScrollTop - event.detail.scrollTop);
            //   if(difference > -57)
            //   {
            //     if(difference > 0)
            //     {
            //       difference = 0;
            //     }
            //   }
            //   else
            //   {
            //     difference = -57;
            //   }
            //   headerRef.current.style.transform =
            //   "translate3d(0, " + difference + "px, 0)";
            //   setHeaderTop(difference);
            // }
            const result = headerScrollTop - event.detail.scrollTop;
            if(event.detail.scrollTop > 57)
            {
              if(result < 0)
              {
                headerRef.current.style.transform = "translate3d(0, -57px, 0)";
              }
              else
              {
                headerRef.current.style.transform = "translate3d(0, 0, 0)";
              }
            }
          }
         

          setHeaderScrollTop(event.detail.scrollTop);

        }}
      >
        <CustomHeaderCondense title="Dodaj zlecenie" />

        <div className="add-job-container">
          <div className="container container-margin-header-responsive">
            <div className="pxp-dashboard-content-details">
              <h1 className="ml-1 d-none d-md-block">Dodaj nowe zlecenie</h1>
              <form>
                <div className="row mt-4">
                  <div className="col-12 col-md-6">
                    <div className="">
                      <MobiInput required label="Tytuł" />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="">
                      {/* <MobiInput 
                        required
                        label="Kategoria"
                      /> */}
                      <CategorySearchInput />
                    </div>
                  </div>
                </div>
                <div className="mt-md-2">
                  {/* <h2 className="ml-1 mt-3 mb-4">Opis zlecenia</h2> */}

                  <MobiTextarea label="Opis zlecenia" />
                </div>

                <div className="row justify-content-center mt-md-5">
                  <div className="col-12 col-md-auto">
                    <Switch
                      className="remove-shadow"
                      label="Akceptuje regulamin oprogramowania SendMap API"
                      disabled={false}
                      rtl={true}
                    />
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-md-6 col-xxl-3">
                    <div className="mb-3">
                      <label
                        htmlFor="pxp-company-job-experience"
                        className="form-label"
                      >
                        Experience
                      </label>
                      <input
                        type="text"
                        id="pxp-company-job-experience"
                        className="form-control"
                        placeholder="E.g. Minimum 3 years"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-xxl-3">
                    <div className="mb-3">
                      <label
                        htmlFor="pxp-company-job-level"
                        className="form-label"
                      >
                        Career level
                      </label>
                      <select
                        id="pxp-company-job-level"
                        className="form-select"
                      >
                        <option>No Experience</option>
                        <option>Entry-Level</option>
                        <option>Mid-Level</option>
                        <option>Senior-Level</option>
                        <option>Manager / Executive</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 col-xxl-3">
                    <div className="mb-3">
                      <label
                        htmlFor="pxp-company-job-type"
                        className="form-label"
                      >
                        Employment type
                      </label>
                      <select id="pxp-company-job-type" className="form-select">
                        <option>Full Time</option>
                        <option>Part Time</option>
                        <option>Remote</option>
                        <option>Internship</option>
                        <option>Contract</option>
                        <option>Training</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 col-xxl-3">
                    <div className="mb-3">
                      <label
                        htmlFor="pxp-company-job-salary"
                        className="form-label"
                      >
                        Salary range
                      </label>
                      <select
                        id="pxp-company-job-salary"
                        className="form-select"
                      >
                        <option>Select range</option>
                        <option>$700 - $1000</option>
                        <option>$1000 - $1200</option>
                        <option>$1200 - $1400</option>
                        <option>$1500 - $1800</option>
                        <option>$2000 - $3000</option>
                      </select>
                    </div>
                  </div>
                </div> */}
                <div className="">
                  <div className="row justify-content-center">
                    <div className="col-auto">
                      <MobiButton
                        height={66}
                        fontSize={23}
                        style={{
                          borderRadius: "20px",
                        }}
                        type="button"
                        theme="primary"
                        label="Dodaj zlecenie"
                      ></MobiButton>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <IonFooter>
          <Footer margin={false} />
        </IonFooter>
      </IonContent>
    </IonPage>
  );
};

export default Home;
